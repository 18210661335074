import styled, { css } from 'styled-components';

interface TooltipContainerProps {
  position: 'top' | 'right' | 'bottom' | 'left';
}

export const Container = styled.div<TooltipContainerProps>`
  position: relative;

  .tooltip-content {
    width: 16rem;
    background: #fff;
    padding: 1.5rem;
    border-radius: 1.1rem;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 2100;
    ${props => toolTipTransitions[props.position || 'top']}
    color: #323232;
    transition: opacity 0.3s;

    &::before {
      content: '';
      border-style: solid;
      border-color: #fff transparent;
      position: absolute;
      ${props => toolTipBeforeTransitions[props.position]}
    }

    p {
      font-family: 'RedHatDisplay-Regular';
      font-family: 1rem;
      line-height: 1.44rem;
      text-align: left;
    }
  }

  &:hover .tooltip-content {
    opacity: 1;
    visibility: visible;
  }
`;

const toolTipBeforeTransitions = {
  top: css`
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px 10px 0 10px;
    top: 100%;
  `,

  bottom: css`
    border-width: 0 10px 10px 10px;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  `,

  left: css`
    border-width: 10px 10px 0 10px;
    right: 98.2%;
    top: 40%;
    transform: rotate(180deg);
  `,

  right: css`
    border-width: 10px 10px 0 10px;
    left: -0.75rem;
    top: 50%;
    transform: rotate(90deg) translateX(-50%);
  `,
};

const toolTipTransitions = {
  top: css`
    bottom: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
  `,

  bottom: css`
    top: calc(50% + 20px);
    left: calc(50% - 8rem);
  `,

  left: css`
    left: 100%;
    transform: translateX(-50%);
  `,

  right: css`
    left: calc(100% + 20px);
    bottom: 50%;
    transform: translateY(50%);
  `,
};

export const TooltipHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 0.83rem;

  > svg {
    margin-right: 1rem;
  }

  > svg + h4 {
    font-family: 'RedHatDisplay-Bold';
    font-family: 1rem;
    line-height: 1.27rem;
    width: auto;
  }
`;
