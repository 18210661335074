/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { EyeHiddenIcon, EyeVisibleIcon } from '../../assets/svg/SVGComponents';

import {
  Container,
  InputError,
  ToggleVisibilityButton,
  ReadStatus,
  InputAndErrorWrapper,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  hasToggleButton?: boolean;
  multiline?: boolean;
  iconRight?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isBoolean?: boolean;
  saveAnswer?: () => void;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  type,
  hasToggleButton = false,
  multiline,
  readOnly,
  iconRight: IconRight,
  isBoolean,
  saveAnswer,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputType, setInputType] = useState<string | undefined>(type);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (saveAnswer) saveAnswer();
    setIsFilled(
      multiline ? !!textAreaRef.current?.value : !!inputRef.current?.value,
    );
  }, [multiline, saveAnswer]);

  const handleVisibilityChange = useCallback(() => {
    if (type === 'password') {
      inputType === 'password'
        ? setInputType('text')
        : setInputType('password');
    }
  }, [inputType, type]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: multiline ? textAreaRef.current : inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, multiline]);

  return (
    <InputAndErrorWrapper>
      {error && <InputError>{error}</InputError>}
      <Container
        isFilled={isFilled}
        isFocused={isFocused}
        multiline={multiline}
        readOnly={readOnly}
        hasToggleButton={hasToggleButton}
      >
        {Icon && <Icon width="1.33rem" fill="none" strokeWidth={1.5} />}

        {multiline && (
          <textarea
            name={name}
            ref={textAreaRef}
            onFocus={handleInputFocus}
            defaultValue={defaultValue}
            onBlur={handleInputBlur}
            readOnly={readOnly}
          />
        )}

        {!multiline && !isBoolean && (
          <input
            name={name}
            type={inputType}
            ref={inputRef}
            defaultValue={defaultValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            readOnly={readOnly}
            {...rest}
          />
        )}

        {IconRight && (
          <ReadStatus>
            <IconRight
              width="1.33rem"
              fill="none"
              stroke="#323232"
              strokeWidth={1.5}
            />
          </ReadStatus>
        )}

        {hasToggleButton && (
          <ToggleVisibilityButton
            type="button"
            onClick={handleVisibilityChange}
          >
            {inputType === 'password' ? (
              <EyeHiddenIcon
                width="1.33rem"
                fill="none"
                stroke="#323232"
                strokeWidth={1.5}
              />
            ) : (
              <EyeVisibleIcon
                width="1.33rem"
                fill="none"
                stroke="#323232"
                strokeWidth={1.5}
              />
            )}
          </ToggleVisibilityButton>
        )}
      </Container>
    </InputAndErrorWrapper>
  );
};

export default Input;
