import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const ChapterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={41}
    fill="none"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M27.333 22.208h3.417a3.416 3.416 0 1 1 0 6.833h-20.5a3.416 3.416 0 1 0 0 6.834h6.833M18.792 22.208s6.833-5.456 6.833-10.44c0-3.669-3.06-6.643-6.833-6.643-3.774 0-6.834 2.974-6.834 6.644 0 4.983 6.834 10.44 6.834 10.44Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M18.793 11.483a.48.48 0 0 1 .487.475.481.481 0 0 1-.488.473.48.48 0 0 1-.487-.474.48.48 0 0 1 .488-.474Z"
    />
  </svg>
);
export default ChapterIcon;
