import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface HardwareImageProps {
  background: string;
}

export const MainContent = styled.div`
  width: 100%;
  height: 75vh;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid #e2e2e2;
  > a {
    display: flex;
    align-items: center;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const HeaderMainContent = styled.div`
  display: flex;
  align-tems: center;
  justify-content: space-between;
  margin-top: 1.58rem;

  > .primary-button {
    margin-right: 10.44rem;
    width: 14.67rem;
    height: 3.56rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #fff;
    line-height: 1.28rem;
    padding: 0.77rem 1rem;
    border-radius: 0.55rem;
    min-width: 10.57rem;
    min-height: 2.82rem;
    box-shadow: 0px 3px 6px rgba(255, 159, 0, 0.3);
  }
`;

export const TitleWrapper = styled.header`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 3.44rem;

  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    text-align: center;
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'MarkPro';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;

export const ToolsGrid = styled.div`
  max-width: 100%;
  height: 90%;
  margin-top: 3.66rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-areas:
    'item1 item3'
    'item1 item3'
    'item1 item4'
    'item2 item4'
    'item2 item5'
    'item2 item5';

  .item-1 {
    grid-area: item1;
  }
  .item-2 {
    grid-area: item2;
  }
  .item-3 {
    grid-area: item3;
  }
  .item-4 {
    grid-area: item4;
  }
  .item-5 {
    grid-area: item5;
  }
`;

export const ToolCard = styled(Link)<HardwareImageProps>`
  background: ${props => `url("${props.background}") no-repeat center`};
  width: 100%;
  height: 100%;
  border-radius: 1.67rem;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.102);
  background-size: cover;
  cursor: pointer;
  overflow: visible;
  transition: all 0.3s;

  &:hover {
    box-shadow: 4px 8px 15px 8px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  }

  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; */

  > span {
    position: absolute;
    top: 50%; /* Deslocamento vertical em relação ao topo */
    left: 50%; /* Deslocamento horizontal em relação à esquerda */
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 3.33rem;
    color: #fff;
    line-height: 3.33rem;
    text-align: center;
  }

  > p {
    position: absolute;
    bottom: 1.38rem;
    right: 1.94rem;
  }
`;
