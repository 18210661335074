import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const StoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 33"
    fill="none"
    {...props}
  >
    <g strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
      <path d="M25.734 9.791H8.46c-.75 0-1.372.545-1.421 1.246L5.905 26.972c-.11 1.54 1.195 2.848 2.843 2.848h16.699c1.648 0 2.952-1.307 2.843-2.848l-1.134-15.935c-.05-.7-.671-1.246-1.422-1.246Z" />
      <path d="M21.372 12.462V7.12c0-2.213-1.913-4.006-4.273-4.006h-.002c-2.36 0-4.273 1.793-4.273 4.006v5.34M14.248 16.468v1.335M19.946 16.468v1.335M22.083 22.727s-1.87 1.752-4.986 1.752c-3.116 0-4.985-1.752-4.985-1.752" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.005.445H34.19V32.49H.005z" />
      </clipPath>
    </defs>
  </svg>
);
export default StoreIcon;
