import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  BarFill,
  BarTimer,
  CommentContainer,
  Container,
  Content,
  ContentContainer,
  RateContainer,
  StarContainer,
} from './styles';
import {
  CommentIcon,
  LogoRobotIcon,
  StarIcon,
} from '../../../assets/svg/SVGComponents';
import { useFeedback } from '../../../hooks/feedback';
import { IUpdateFeedbackProps } from '../../../dtos/IFeedbacksProps';
import { ISmallPopoverComponentProps } from '../../../dtos/IPopoverComponentsProps';

const SmallPopover: React.FC<ISmallPopoverComponentProps> = ({
  positiontop,
  positionleft,
  closePopover,
  openLargePopover,
  style,
  openPopover,
}) => {
  const [rating, setRating] = useState(0);
  const [selectRating, setSelectRating] = useState(0);
  const [progress, setProgress] = useState(0);
  const { openFeedback, setOpenFeedback, feedbacks, updateFeedback } =
    useFeedback();
  const [isTimeout, setIsTimeout] = useState(false);
  const feedbackSource = 'small-popover';

  const memoFeedback = useMemo(() => {
    if (feedbacks) {
      setRating(feedbacks.rating || 0);
      setSelectRating(feedbacks.rating || 0);
    }

    return feedbacks;
  }, [feedbacks]);

  useEffect(() => {
    const duration = 5000;
    const intervalTime = 50;

    if (openPopover) {
      const timerSmall = setTimeout(() => {
        closePopover();
        setProgress(0);
        setIsTimeout(true);
      }, duration);

      const intervalsmall = setInterval(() => {
        setProgress(prevProgress => {
          const nextProgress = prevProgress + (intervalTime / duration) * 100;
          return nextProgress >= 100 ? 100 : nextProgress;
        });
      }, intervalTime);

      return () => {
        clearTimeout(timerSmall);
        clearInterval(intervalsmall);
      };
    }

    return undefined;
  }, [closePopover, openFeedback, openPopover]);

  useEffect(() => {
    if (isTimeout) {
      if (rating !== feedbacks?.rating) {
        const updateFeedbackData: IUpdateFeedbackProps = {
          rating,
          feedback_source: feedbackSource,
        };

        updateFeedback(updateFeedbackData);
      }
    }
  }, [feedbacks?.rating, isTimeout, rating, updateFeedback]);

  const handleClick = useCallback((starIndex: number) => {
    setRating(starIndex);
  }, []);

  const handleButtonComment = useCallback(() => {
    if (openFeedback) {
      setOpenFeedback(false);
      closePopover();
      openLargePopover();
    }
  }, [closePopover, openLargePopover, openFeedback, setOpenFeedback]);

  return (
    <Container
      style={style}
      positiontop={positiontop}
      positionleft={positionleft}
    >
      <BarTimer>
        <BarFill style={{ width: `${100 - progress}%` }} />
      </BarTimer>
      <ContentContainer>
        <Content>
          <LogoRobotIcon width="2rem" fill="#1e88e5" />

          <RateContainer>
            <h3>Avalie o conteúdo</h3>
            <StarContainer>
              {Array.from({ length: 5 }, (_, index) => (
                <StarIcon
                  key={index}
                  width="1.5rem"
                  onClick={() => handleClick(index + 1)}
                  fill={index < selectRating ? '#ff9800' : '#e2e2e2'}
                  onMouseEnter={() => setSelectRating(index + 1)}
                  onMouseLeave={() => setSelectRating(rating)}
                />
              ))}
            </StarContainer>
          </RateContainer>

          <CommentContainer type="button" onClick={handleButtonComment}>
            <CommentIcon width="1.8rem" stroke="#1e88e5" strokeWidth={2} />
            <p>
              Deixe seu <br />
              comentário
            </p>
          </CommentContainer>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default SmallPopover;
