import React from 'react';

const SvgLock: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="lock_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#lock_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect width={14} height={12} rx={2} transform="translate(5 9)" />
        <path d="M12 17.091v-2.592" />
        <circle cx={0.75} cy={0.75} r={0.75} transform="translate(11.25 13)" />
        <path d="M8 9V7a4 4 0 018 0v2" />
      </g>
    </svg>
  );
};

export default SvgLock;
