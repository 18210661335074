import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'info' | 'success' | 'error' | 'warn';
}

// const toastColorsVariation = {
//   info: '#2b80b8',
//   success: '#0ea581',
//   error: '#ff2e3e',
//   warn: '#ffba08',
// };

const toastTypeVariations = {
  info: css`
    box-shadow: 0px 3px 15px rgba(43, 128, 184, 0.16);
    border-left: 6px solid #2b80b8;
  `,

  success: css`
    box-shadow: 0px 3px 15px rgba(14, 165, 129, 0.16);
    border-left: 6px solid #0ea581;
  `,

  error: css`
    box-shadow: 0px 3px 15px rgba(255, 46, 62, 0.16);
    border-left: 6px solid #ff2e3e;
  `,

  warn: css`
    box-shadow: 0px 3px 15px rgba(255, 207, 83, 0.16);
    border-left: 6px solid #ffba08;
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 26rem;
  min-height: 5.2rem;
  position: relative;
  border-radius: 0.55rem;
  display: flex;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
  background: #fff;

  ${props => toastTypeVariations[props.type || 'info']}

  & + div {
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1.38rem 1.38rem 1.38rem 0;
  background: #fff;

  > svg:first-child {
    margin: 0 1rem 0 1.2rem;
  }

  > div {
    flex: 1;
    padding-right: 1rem;

    > p {
      font-family: 'MarkPro';
      font-size: 1rem;
      line-height: 1.44rem;
      text-align: left;

      > strong {
        font-family: 'MarkPro-Bold';
      }
    }
  }

  button {
    align-self: flex-start;
    border: 0;
    cursor: pointer;

    > svg {
      opacity: 0.8;
      transition: all 0.3s;

      &:hover {
        transform: rotate(90deg);
        opacity: 1;
      }
    }
  }
`;
