import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70vh;
  gap: 1.92rem;
  grid-template-areas: 'f b';
`;

export const FuzzyBitsArea = styled(Link)`
  grid-area: f;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1.92rem;
  justify-content: center;
  background: url('https://fuzzyplaydev.s3.us-east-2.amazonaws.com/fuzzy-bits-background.png')
    no-repeat center;
  background-size: cover;
  position: relative;
  overflow: visible;
  transition: all 0.3s;

  span {
    display: flex;
    align-items: flex-end;
    color: #e8e8e8;
    font-family: 'MarkPro-Bold';
    font-size: 1.5rem;
    line-height: 2.22rem;
    margin-bottom: 1.55rem;
    text-align: center;
  }

  &:hover {
    box-shadow: 4px 8px 15px 8px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  }

  h1 {
    font-family: 'MarkPro-Heavy';
    font-size: 1.78rem;
    line-height: 2.22rem;
    margin-bottom: 1.55rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.44rem;
    text-align: center;
  }
`;

export const MicroBitArea = styled(Link)`
  grid-area: b;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1.92rem;
  justify-content: center;
  background: url('https://fuzzyplaydev.s3.us-east-2.amazonaws.com/micro-bit-background.png')
    no-repeat center;
  background-size: cover;
  position: relative;
  overflow: visible;
  transition: all 0.3s;

  span {
    display: flex;
    align-items: flex-end;
    color: #e8e8e8;
    font-family: 'MarkPro-Bold';
    font-size: 1.5rem;
    line-height: 2.22rem;
    margin-bottom: 1.55rem;
    text-align: center;
  }

  &:hover {
    box-shadow: 4px 8px 15px 8px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  }

  h1 {
    font-family: 'MarkPro-Heavy';
    font-size: 1.78rem;
    line-height: 2.22rem;
    margin-bottom: 1.55rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.44rem;
    text-align: center;
  }
`;
