export default function setLinks(): void {
  const { links } = document;

  // eslint-disable-next-line no-plusplus
  for (let i = 0, linksLength = links.length; i < linksLength; i++) {
    if (links[i].hostname !== window.location.hostname) {
      links[i].target = '_blank';
    }
  }
}
