import React, { useEffect } from 'react';

import {
  CrossIcon,
  AttentionIcon,
  CheckmarkIcon,
  InfoIcon,
} from '../../../assets/svg/SVGComponents';
import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container, Content } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: (
    <InfoIcon width="1.33rem" stroke="#2B80B8" fill="none" strokeWidth={2} />
  ),

  success: (
    <CheckmarkIcon
      width="1.33rem"
      stroke="#0EA581"
      fill="none"
      strokeWidth={2}
    />
  ),

  error: (
    <AttentionIcon
      width="1.33rem"
      stroke="#FF2E3E"
      fill="none"
      strokeWidth={2}
    />
  ),
  warn: (
    <AttentionIcon
      width="1.33rem"
      stroke="#FFBA08"
      fill="none"
      strokeWidth={2}
    />
  ),
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <Container type={message.type} style={style}>
      <Content>
        {icons[message.type || 'info']}
        <div>
          <p>
            <strong>{message.expression && message.expression} </strong>
            {message.description}
          </p>
        </div>

        <button onClick={() => removeToast(message.id)} type="button">
          <CrossIcon width="1rem" stroke="#63646A" strokeWidth={1.5} />
        </button>
      </Content>
    </Container>
  );
};

export default Toast;
