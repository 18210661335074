import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import getValidationErrors from '../../utils/getValidationErrors';

import { CrossIcon, LockIcon } from '../../assets/svg/SVGComponents';

import Input from '../../components/Input';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

import { useModal } from '../../hooks/modal';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Header,
  Title,
  ButtonsContainer,
  CancelButton,
} from './styles';

interface ResetPasswordFormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const ResetPasswordModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { t } = useTranslation();
  const { handleModal } = useModal();
  const { addToast } = useToast();
  const { updatePassword, isOldPasswordCorrect } = useAuth();

  const handleClose = useCallback(() => {
    handleModal(null);
  }, [handleModal]);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      formRef.current?.setErrors({});
      setButtonLoading(true);

      try {
        const resetPasswordSchema = Yup.object().shape({
          old_password: Yup.string().required(
            t('yup_errors.password_required'),
          ),
          password: Yup.string().required(t('yup_errors.password_required')),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            t('yup_errors.passwords_match'),
          ),
        });

        await resetPasswordSchema.validate(data, {
          abortEarly: false,
        });

        if (!(await isOldPasswordCorrect(data.old_password))) {
          addToast({
            type: 'error',
            expression: 'Ops!',
            description: t('toasts.errors.password_verification_fail'),
          });
          setButtonLoading(false);
          return;
        }

        await updatePassword(data.password);

        setButtonLoading(false);
        handleClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        setButtonLoading(false);
      }
    },
    [t, updatePassword, handleClose, addToast, isOldPasswordCorrect],
  );

  return (
    <Container>
      <Header>
        <div>
          <LockIcon
            width="1.5rem"
            stroke="#323650"
            strokeWidth={1.5}
            fill="none"
          />
          <h3>
            <Trans i18nKey="h3.redefine_password" />
          </h3>
        </div>

        <CrossIcon
          onClick={handleClose}
          width="1.22rem"
          stroke="#323650"
          strokeWidth={1.5}
        />
      </Header>

      <Title>
        <h1>
          <Trans i18nKey="h1.redefine_password" />
        </h1>
        <p>{t('p.fill_the_form_to_reset_password')}:</p>
      </Title>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <label htmlFor="old_password" tabIndex={-1}>
          {t('labels.old_password')}
        </label>
        <Input
          id="old_password"
          name="old_password"
          type="password"
          placeholder={t('placeholders.old_password')}
          icon={LockIcon}
          hasToggleButton
        />

        <label htmlFor="password" tabIndex={-1}>
          {t('labels.new_password')}
        </label>
        <Input
          id="password"
          name="password"
          type="password"
          placeholder={t('placeholders.new_password')}
          icon={LockIcon}
          hasToggleButton
        />

        <label htmlFor="password_confirmation" tabIndex={-1}>
          {t('labels.password_confirmation')}
        </label>
        <Input
          id="password_confirmation"
          name="password_confirmation"
          type="password"
          placeholder={t('placeholders.password_confirmation')}
          icon={LockIcon}
          hasToggleButton
        />

        <ButtonsContainer>
          <PrimaryButton loading={buttonLoading} type="submit">
            {t('buttons.reset_password')}
          </PrimaryButton>
          <CancelButton onClick={handleClose}>
            {t('buttons.cancel')}
          </CancelButton>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default ResetPasswordModal;
