import styled from 'styled-components';

interface StepProps {
  accomplished: boolean;
}

interface StepBarProps {
  steps: number;
}
export const ContentWrapper = styled.div`
  padding: 2.89rem 2.89rem;
`;

export const Content = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e2e2e2;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  position: relative;
`;

export const DisplayChapter = styled.div`
  position: absolute;
  z-index: 2;
  top: -210%;
  padding: 0.5rem;
  border-radius: 0.4rem;
  border: 2px #e2e2e2 solid;
  margin-bottom: 1.33rem;

  > img {
    display: flex;
    width: 3.88rem;
    height: 3.88rem;
  }

  h1 {
    display: flex;

    letter-spacing: 0.1rem;
    font-size: 1rem;
    font-family: 'RedHatDisplay-Regular';
  }
`;

export const ActivityTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > h2 {
    width: 50%;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.66rem;
    text-align: center;
  }

  > .primary-button {
    display: flex;
    align-items: center;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    color: #fff;
    line-height: 1.28rem;
    padding: 0.77rem 0.72rem 0.77rem 0.88rem;
    border-radius: 0.55rem;
    vertical-align: center;

    > svg {
      margin-left: 0.8rem;
      visibility: visible;
    }

    &:disabled {
      cursor: not-allowed;

      color: #b7b7b7;
      background-color: #f2f2f2;
      box-shadow: none;
    }
  }
`;

export const HeaderMainContent = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-content: start;
  position: relative;

  > button {
    a {
      width: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.11rem;
      font-family: 'RedHatDisplay-Bold';
      transition: opacity 0.3s;
      opacity: 0.8;
      margin-right: 1.11rem;

      > svg {
        margin-right: 0.89rem;
        opacity: 0.8;
      }

      &:hover {
        opacity: 1;

        > svg {
          opacity: 1;
        }
      }
    }
  }

  > .secondary-button {
    display: flex;
    height: 2.89rem;
    align-items: center;
    font-family: 'MarkPro';
    font-size: 1rem;
    color: #323232;
    line-height: 1.28rem;
    padding: 0.77rem 0.72rem 0.77rem 0.88rem;
    border-radius: 0.55rem;
    vertical-align: center;
    position: relative;

    > svg {
      margin-left: 0.8rem;
      visibility: visible;
    }

    &:disabled {
      cursor: not-allowed;
      background: rgba(50, 54, 80, 0.05);
      border: rgba(50, 54, 80, 0.05);
      color: #323232;
    }
  }
`;
export const ChapterTitle = styled.div`
  height: fit-content;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProgressBarWrapper = styled.div`
  margin-top: 2rem;
  padding: 0;
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  > div {
    margin: 0 4rem;
  }

  button {
    font-family: 'RedhatDisplay-Bold';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.27rem;
    padding: 0.77rem 0px;
    transition: all 0.3s;
    color: #1e88e5;

    > svg {
      margin-right: 0.61rem;
      margin-left: 0.61rem;
    }

    &:disabled {
      cursor: not-allowed;
      color: #63646a;
      background: none;
      svg {
        stroke: #63646a;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
`;

export const Steps = styled.div<StepBarProps>`
  display: flex;

  > div:nth-child(2) {
    margin-left: 5.29rem;
  }

  > div:nth-child(3) {
    margin-left: 5.13rem;
  }
`;

export const Step = styled.div<StepProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  > span {
    width: 250%;
    top: -2.2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 0.61rem;
    letter-spacing: 0.12rem;
    margin-bottom: 1.4rem;
  }
  > div {
    width: 3.9rem;
    height: 3.9rem;
    background-color: ${props => (props.accomplished ? '#4CAF50' : '#f2f2f2')};
    transition: background-color 0.4s ease;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > p {
      width: 0.12rem;
      height: 1.22rem;
      background-color: ${props =>
        props.accomplished ? '#4CAF50' : '#f2f2f2'};
      transition: background-color 0.4s ease;
      position: absolute;
      top: -1.22rem;
      left: 50%;
      transform: translateX(-50%);
    }

    > div {
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 50%;
      outline: ${props =>
        props.accomplished ? '2px solid #FFFEFC' : '2px solid #b7b7b7'};
      transition: outline 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        stroke: ${props => (props.accomplished ? '#FFFEFC' : '#b7b7b7')};
        transition: stroke 0.4s ease;
      }
    }
  }
`;

export const ProjectSection = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 7.22vh;
  margin-top: 7.22vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc((100% - 2.66rem) / 3));
  grid-gap: 1.33rem;
`;

export const MainContent = styled.div`
  width: 100%;
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
`;

export const MakecodeContent = styled.div`
  width: 100%;
  height: 80vh;
  padding-top: 3.2rem;
  overflow: hidden;
  position: relative;
`;

export const IllustrationWrapper = styled.div`
  width: 4rem;
  height: 4rem;
`;

export const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    margin-top: 4rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > .primary-button {
    height: 2.83rem;
    width: 12.16rem;
    margin-left: 2rem;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0px 3px 15px rgba(255, 152, 0, 0.3);
  }
`;
