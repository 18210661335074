import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar as NavBar,
  Tabs as TabsContainer,
  Tab,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { VideosIcon } from '../../../assets/svg/SVGComponents';

import { MainContent, MainContentHeader, NavigationMenu } from './styles';
import PlaylistAndProgress from '../PlaylistAndProgress';

const Journeys: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>();
  const { active_tab } = useParams();

  useEffect(() => {
    if (active_tab === 'progress') setSelectedTab(0);
    if (active_tab === 'tools') setSelectedTab(1);
    if (active_tab === 'knowledge') setSelectedTab(2);
  }, [active_tab]);

  const handleSelectedTabChange = useCallback(
    (_event, tabIndex) => {
      setSelectedTab(tabIndex);
      if (tabIndex === 0) navigate('progress');
      if (tabIndex === 1) navigate('tools');
    },
    [navigate],
  );
  return (
    <MainContent>
      <MainContentHeader>
        <VideosIcon width="2rem" stroke="#FF9800" strokeWidth={2} />
        <h1>{t('h1.learning_journeys')}</h1>
      </MainContentHeader>

      <NavigationMenu>
        <NavBar position="relative" className="profile-navBar">
          <TabsContainer
            value={selectedTab}
            onChange={handleSelectedTabChange}
            className="profile-tabs-container"
            TabIndicatorProps={{
              style: {
                background: '#FF9800',
                height: '5px',
                borderRadius: '2.5px',
                marginBottom: '-3px',
                zIndex: 1101,
              },
            }}
          >
            <Tab label={t('tabs.progress')} className="profile-tab-item" />
          </TabsContainer>
        </NavBar>
        {selectedTab === 0 && <PlaylistAndProgress />}
      </NavigationMenu>
    </MainContent>
  );
};
export default Journeys;
