import React, { useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import i18next from 'i18next';
import BackgroundBLue from '../../../assets/images/backgroundSignIn.jpg';

import { firebaseAuth } from '../../../config/firebase';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  FuzzyLogoBlueOrange,
  FuzzyLogoNewOrange,
  MailIcon,
} from '../../../assets/svg/SVGComponents';

import Input from '../../../components/Input';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

import {
  Container,
  ResetPassword,
  Footer,
  SideContent,
  ImageArea,
  TipBackground,
  BackToLogin,
  TopContent,
  LanguageButtons,
} from './styles';

import { useToast } from '../../../hooks/toast';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { t } = useTranslation();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      formRef.current?.setErrors({});

      try {
        setLoading(true);

        const passwordForgotSchema = Yup.object().shape({
          email: Yup.string()
            .required(t('yup_errors.email_required'))
            .email(t('yup_errors.invalid_email')),
        });

        await passwordForgotSchema.validate(data, {
          abortEarly: false,
        });

        await firebaseAuth.sendPasswordResetEmail(data.email);

        formRef.current?.clearField('email');

        addToast({
          type: 'success',
          expression: 'Ok!',
          description: t('toasts.success.forgot_password_email_sent'),
        });

        navigate('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          expression: 'Ops!',
          description: t('toasts.errors.send_email_fail'),
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, navigate, t],
  );

  const handleLanguageChange = useCallback((lang_code: string) => {
    i18next.changeLanguage(lang_code);
  }, []);

  return (
    <Container>
      <ResetPassword>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FuzzyLogoNewOrange />
          <h1>
            <Trans i18nKey="h1.recover_password" />
          </h1>

          <h3>{t('h3.recover_password')}</h3>

          <span>{t('labels.email')}</span>
          <Input
            name="email"
            type="text"
            placeholder={t('placeholders.email')}
            icon={MailIcon}
          />

          <PrimaryButton loading={loading} type="submit">
            {t('buttons.recover_password')}
          </PrimaryButton>
        </Form>
        <BackToLogin>
          <p> Quer voltar a página inicial?</p>
          <SecondaryButton onClick={() => navigate('/')}>
            Voltar para login
          </SecondaryButton>
        </BackToLogin>
        <footer />
      </ResetPassword>
      <SideContent>
        <ImageArea>
          <img src={BackgroundBLue} alt="fuzzy-logo" />
          <TopContent>
            <LanguageButtons>
              <button
                type="button"
                className={
                  i18next.language === 'pt-BR' ? 'active-language' : ''
                }
                onClick={() => handleLanguageChange('pt-BR')}
              >
                PT
              </button>
              |
              <button
                type="button"
                className={i18next.language === 'en' ? 'active-language' : ''}
                onClick={() => handleLanguageChange('en')}
              >
                EN
              </button>
            </LanguageButtons>
            <TipBackground>
              <img
                src="https://fuzzyplaydev.s3.us-east-2.amazonaws.com/backgroundTip.png"
                alt="tip-bg"
              />
            </TipBackground>
          </TopContent>
        </ImageArea>
      </SideContent>
    </Container>
  );
};

export default ForgotPassword;
