import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const VideoContent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 14" {...props}>
    <g clipPath="url(#a)">
      <path d="M22.55 10.91c-.109.416-.31.77-.696.999-.43.256-.885.302-1.333.08-.493-.243-.965-.524-1.445-.793-.765-.428-1.527-.864-2.295-1.289-.135-.074-.191-.152-.191-.308.006-1.698.006-3.396 0-5.094 0-.155.05-.237.186-.313 1.151-.645 2.315-1.271 3.442-1.956.988-.6 2.15-.066 2.31.922.003.019.014.037.021.055v7.697h.001ZM7.933 13.42c-1.644 0-3.286.003-4.93-.002-1.22-.003-2.17-.72-2.436-1.839a2.625 2.625 0 0 1-.064-.607C.5 8.366.5 5.758.501 3.151.503 1.743 1.526.711 2.961.707c3.322-.01 6.644-.01 9.967 0 1.426.004 2.447.998 2.456 2.4a676.5 676.5 0 0 1 0 7.905c-.008 1.41-1.055 2.402-2.5 2.407-1.65.006-3.3.001-4.95.001h-.001Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M.5.7h22.05v12.721H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default VideoContent;
