import React, { useLayoutEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import GlobalStyle from './styles/global';
import Routex from './routes';
import AppProvider from './hooks';
import { PreviousURLProvider } from './hooks/previousLink';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Wrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

const App: React.FC = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <PreviousURLProvider>
          <Wrapper>
            <Routex />
          </Wrapper>
        </PreviousURLProvider>
      </BrowserRouter>
      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
