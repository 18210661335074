import React from 'react';

const SvgBell: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" {...props}>
      <defs>
        <clipPath id="bell_svg__a">
          <path fill="none" d="M-.342-.342h33v33h-33z" />
        </clipPath>
      </defs>
      <g
        transform="translate(.342 .342)"
        clipPath="url(#bell_svg__a)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.692 25.261v.508a3.188 3.188 0 006.377 0v-.508M18.43 7.743V6.466a2.551 2.551 0 00-5.1 0v1.277" />
        <path d="M8.317 13.915h0a6.3 6.3 0 016.3-6.3h2.528a6.3 6.3 0 016.3 6.3v3.891a2.783 2.783 0 00.815 1.967l.89.892a2.781 2.781 0 01.815 1.967 2.63 2.63 0 01-2.63 2.629H8.424a2.629 2.629 0 01-2.629-2.63 2.779 2.779 0 01.814-1.967l.892-.892a2.784 2.784 0 00.817-1.967z" />
      </g>
    </svg>
  );
};

export default SvgBell;
