import React from 'react';

const SvgClock: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <defs>
        <clipPath id="clock_svg__a">
          <path transform="translate(-.287 -.287)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clock_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(.287 .287)"
      >
        <circle cx={5} cy={5} r={5} transform="translate(2.713 3.713)" />
        <path d="M2.215 3.737l1.928-1.62M13.212 3.742l-1.928-1.62M7.552 6.062V8.72l2.089 1.273" />
      </g>
    </svg>
  );
};

export default SvgClock;
