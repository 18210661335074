import React from 'react';

const SvgMail: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="mail_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#mail_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3 6.783h0a1.781 1.781 0 00.782 1.475l5.981 4.053a4 4 0 004.488 0l5.967-4.044A1.78 1.78 0 0021 6.792v-.009A1.782 1.782 0 0019.218 5H4.782A1.782 1.782 0 003 6.783z" />
        <path d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V7M3.586 18.414l6.14-6.14M14.281 12.281l6.13 6.13" />
      </g>
    </svg>
  );
};

export default SvgMail;
