export const getDeviceType = (): string => {
  const { userAgent } = navigator;
  if (/mobile/i.test(userAgent)) {
    return 'Mobile';
  }
  if (/tablet/i.test(userAgent)) {
    return 'Tablet';
  }
  return 'Desktop';
};

interface UserLocationProps {
  city: string;
  state: string;
  country: string;
}

const getUserLocation = (): Promise<UserLocationProps> => {
  return new Promise<UserLocationProps>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await fetch(
              `http://api.positionstack.com/v1/reverse?access_key=b3e6e22b397dd8de1af80010cf712fc9&query=${latitude},${longitude}`,
            );
            const data = await response.json();

            if (data.data && data.data.length > 0) {
              resolve({
                city: data.data[0].locality || 'Unknown',
                state: data.data[0].region || 'Unknown',
                country: data.data[0].country || 'Unknown',
              });
            } else {
              reject(new Error('Location data not found'));
            }
          } catch (error) {
            reject(error);
          }
        },
        error => reject(error),
      );
    } else {
      reject(new Error('Geolocation not supported'));
    }
  });
};
