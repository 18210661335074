import styled from 'styled-components';

const arrayColors = ['#1e88e5', '#4caf50', '#ff9800', '#9e9e9e'];

export const LoadingWrapper = styled.div`
  width: 100%;

  --blue: #1e88e5;
  --green: #4caf50;
  --orange: #ff9800;
  --grey: #9e9e9e;
`;

export const HeadWrapper = styled.div`
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading-title {
    height: 3rem;
    border-radius: 10px;
    width: 50vw;
    margin-bottom: 1rem;
  }

  .loading-step {
    height: 7.5rem;
    border-radius: 10px;
    width: 28vw;
    margin-bottom: 1rem;
  }
`;

export const LoadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  > div {
    fill: orange;
    svg {
      #face-grey {
        animation: opacity 6s ease infinite;
        fill: var(--blue);
        @keyframes opacity {
    /* Começa visível */
    0%, 10% {
      opacity: 1;
      fill: var(--blue); /* Azul visível por mais tempo */
    }
    /* Primeiro fade-out */
    20% {
      opacity: 0.2;
    }
    /* Segundo fade-in */
    30%, 40% {
      opacity: 1;
      fill: var(--orange); /* Laranja fica visível por mais tempo */
    }
    /* Segundo fade-out */
    50% {
      opacity: 0.2;
    }
    /* Terceiro fade-in */
    60%, 70% {
      opacity: 1;
      fill: var(--green); /* Verde fica visível por mais tempo */
    }
    /* Terceiro fade-out */
    80% {
      opacity: 0.2;
    }
    /* Quarto fade-in */
    90%, 100% {
      opacity: 1;
      fill: var(--blue); /* Cinza fica visível até o final */
    }
  }
    }
  }

`;

export const ShimmerEffect = styled.div`
  background-image: linear-gradient(
    -90deg,
    #f5f5f5 0%,
    #e2e2e2 50%,
    #f5f5f5 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &.white {
    background-image: linear-gradient(
      -90deg,
      #f5f5f5 0%,
      #e2e2e2 50%,
      #f5f5f5 100%
    );
  }
`;
