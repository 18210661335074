import React from 'react';

const SvgVideos: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
      <defs>
        <clipPath id="videos_svg__a">
          <path fill="none" d="M.224.224h34v34h-34z" />
        </clipPath>
      </defs>
      <g fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path d="M8 8h18" />
        <g clipPath="url(#videos_svg__a)" transform="translate(-.224 -.224)">
          <path d="M12.224 4.224h10M15.523 18.637l3.874 2.29a.75.75 0 010 1.291l-3.874 2.292a.749.749 0 01-1.131-.645v-4.581a.749.749 0 011.131-.647z" />
          <rect
            width={25}
            height={16}
            rx={2}
            transform="translate(4.224 13.224)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SvgVideos;
