import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const EmailInput = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1.11rem" height="0.89rem" viewBox="0 0 20 16" {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1 2.783a1.78 1.78 0 0 0 .782 1.475l5.981 4.053a4 4 0 0 0 4.488 0l5.967-4.044A1.78 1.78 0 0 0 19 2.792v-.009A1.783 1.783 0 0 0 17.218 1H2.782A1.782 1.782 0 0 0 1 2.783v0Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1 3v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3M1.586 14.414l6.14-6.14M12.281 8.28l6.13 6.13"
    />
  </svg>
);
export default EmailInput;
