import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;
export const MakecodeIframe = styled.div`
  width: 100%;
  height: 90vh;

  iframe {
    width: 100%;
    height: 100%;
    border-style: none;
  }
`;
