import React, { useState } from 'react';
import { ChevronRightIcon } from '../../assets/svg/SVGComponents';

import { Container, AccordionHeader, AccordionContent } from './styles';

interface AccordionProps {
  title: string;
  videoAmount?: number | 0;
  open: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  videoAmount,
  children,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <Container>
      <AccordionHeader type="button" onClick={() => setIsOpen(!isOpen)}>
        <div>
          <h4>{title}</h4>
          <p>
            {videoAmount === 1
              ? `${videoAmount} vídeo`
              : `${videoAmount} vídeos`}
          </p>
        </div>

        <ChevronRightIcon
          height="1.12rem"
          stroke="#fff"
          strokeWidth={2}
          className={isOpen ? 'open' : ''}
        />
      </AccordionHeader>
      <AccordionContent className={!isOpen ? 'collapsed' : ''}>
        {children}
      </AccordionContent>
    </Container>
  );
};

export default Accordion;
