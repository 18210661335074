import React from 'react';

const SvgHelp: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
      <defs>
        <clipPath id="help_svg__a">
          <path fill="none" d="M0-.275h34v34H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#help_svg__a)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(0 .275)"
      >
        <path d="M4.216 15.866a11.145 11.145 0 005.057 9.182v4.519l4.962-2.455a14.022 14.022 0 002.626.252c6.96 0 12.647-5.119 12.647-11.5s-5.687-11.5-12.647-11.5S4.216 9.488 4.216 15.866z" />
        <path d="M16.72 17.324v-.291a2.182 2.182 0 011.179-1.865 2.14 2.14 0 001.15-1.827 2.332 2.332 0 00-4.664 0M16.719 20.61a.211.211 0 10.212.211.211.211 0 00-.212-.211" />
      </g>
    </svg>
  );
};

export default SvgHelp;
