import React from 'react';

const SvgQuestionMark: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="questionMark_svg__a">
          <path transform="translate(-.24)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        transform="translate(.24)"
        clipPath="url(#questionMark_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 21h0a9 9 0 119-9 9 9 0 01-9 9z" />
        <path d="M12 13.25V13a1.873 1.873 0 011.011-1.6A1.837 1.837 0 0014 9.833a2 2 0 00-4 0M12 16a.25.25 0 10.251.25A.249.249 0 0012 16" />
      </g>
    </svg>
  );
};

export default SvgQuestionMark;
