import * as React from 'react';
import { SVGProps } from 'react';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const BulletedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <path
      fill="#1E88E5"
      d="M8.41 16.848c4.645 0 8.41-3.772 8.41-8.424C16.82 3.772 13.055 0 8.41 0S0 3.772 0 8.424c0 4.652 3.765 8.424 8.41 8.424Z"
    />
  </svg>
);
export default BulletedIcon;
