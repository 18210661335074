import { animated } from 'react-spring';
import styled from 'styled-components';

interface IContainerProps {
  positiontop?: number;
  positionleft?: number;
}

export const Container = styled(animated.div)<IContainerProps>`
  background: #e9f3fd;
  width: 19rem;
  height: 4rem;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0.89rem 0.89rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 1000;
  top: ${({ positiontop }) => positiontop}%;
  left: ${({ positionleft }) => positionleft}%;
  transform: translateX(-50%);
`;

export const BarTimer = styled.div`
  background: #e0e0e0;
  width: 100%;
  height: 0.3rem;
  overflow: hidden;
`;

export const BarFill = styled.div`
  height: 100%;
  background: #1e88e5;
  transition: width 0.1s linear;
`;

export const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RateContainer = styled.div`
  display: flex;
  margin-left: 0.83rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h3 {
    color: #323232;
    font-family: 'RedHatDisplay-Bold';
    font-size: 0.89rem;
    text-align: center;
  }
`;

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CommentContainer = styled.button`
  margin-left: 0.83rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    color: #323232;
    font-family: 'RedHatDisplay-Medium';
    font-size: 0.8rem;
    text-align: center;
  }
`;
