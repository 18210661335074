import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  width: 48.5vw;
  margin-right: 4rem;
  .loading-title {
    height: 3rem;
    border-radius: 10px;
    width: 60%;
  }
`;

export const Title = styled.div``;

export const MainHeadContent = styled.div`
  height: 5rem;
  border-bottom: 1px solid #d6d5d3;
`;

export const ListWrapper = styled.div`
  .card-chapter {
    width: 80%;
    height: 7.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    border-radius: 10px;
  }
`;

export const PlaylistsContent = styled.div`
  border-left: 1px solid #d6d5d3;
`;

export const ProgressList = styled.div`
  width: 26.56vw;
  margin-left: 2.5rem;

  .progress-title {
    height: 1.5rem;
    width: 60%;
    margin-bottom: 3.11rem;
    border-radius: 10px;
  }

  .progress-card {
    margin-bottom: 2.72rem;
    height: 5.5rem;
    border-radius: 10px;
  }
`;

export const ShimmerEffect = styled.div`
  background-image: linear-gradient(
    -90deg,
    #f5f5f5 0%,
    #e2e2e2 50%,
    #f5f5f5 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &.white {
    background-image: linear-gradient(
      -90deg,
      #f5f5f5 0%,
      #e2e2e2 50%,
      #f5f5f5 100%
    );
  }
`;
