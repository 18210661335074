import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Activity,
  Circle,
  Container,
  DurationWrapper,
  IconActivity,
  IconArea,
  Line,
  ListItems,
  TextArea,
} from './styles';
import {
  ActivityIcon,
  ClockIcon,
  CursorIcon,
} from '../../assets/svg/SVGComponents';

import IActivityProps from '../../dtos/IActivitiesProps';

// import { Container } from './styles';

interface IListActivitiesProps {
  trail_slug: string;
  chapter_id: string;
  activities: IActivityProps[];
}

const ListActivities: React.FC<IListActivitiesProps> = ({
  trail_slug,
  chapter_id,
  activities,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createCurves = (quantity: number): JSX.Element => {
    const curves: JSX.Element[] = [];

    // Primeira curva
    curves.push(
      <path
        key="first-curve"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8064 92.9482V95H51.5852C50.228 94.8765 48.8704 94.8145 47.5217 94.8145C21.3163 94.8108 0 73.5431 0 47.4054C0 21.2677 21.3163 0 47.5182 0C48.1885 0 48.7368 0.543457 48.7368 1.2157C48.7368 1.88794 48.192 2.4314 47.5182 2.4314C22.6604 2.42786 2.43372 22.605 2.43372 47.4054C2.43372 72.2058 22.6604 92.3794 47.5182 92.3794C49.719 92.3794 51.9843 92.5439 54.2496 92.8657C54.4355 92.8921 54.6212 92.9197 54.8064 92.9482Z"
        fill="#ededed"
      />,
    );

    // Curvas do meio
    let spaceBetweenCurves = 88;
    for (let i = 1; i <= quantity - 1; i += 1) {
      curves.push(
        <g>
          <path
            key={`connection-curve-${i}`}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.9751 4.60547C29.9531 8.14954 47.2087 28.1843 47.2087 51.442C47.2087 74.6998 29.9531 94.7346 6.9751 98.2787V95.8245C7.40088 95.7548 7.82593 95.6791 8.25 95.5973C29.4121 91.5211 44.7749 72.9529 44.7749 51.4457C44.7749 29.9384 29.4121 11.3702 8.25 7.29407C7.82593 7.21228 7.40088 7.1366 6.9751 7.06689V4.60547Z"
            fill="#EDEDED"
            transform={`translate(42 ${spaceBetweenCurves})`}
          />

          <path
            key={`activity-curve-${i}`}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.8064 93.5435C54.6212 93.5149 54.4355 93.4873 54.2496 93.4609C51.9807 93.1392 49.719 92.9746 47.5182 92.9746C22.6604 92.9746 2.43372 72.801 2.43372 48.0006C2.43372 23.2002 22.6569 3.02307 47.5182 3.02307C49.719 3.02307 51.9843 2.85852 54.2496 2.53674C54.4355 2.51038 54.6212 2.48279 54.8064 2.45422V0C52.3857 0.395996 49.9421 0.595215 47.5217 0.595215C21.3163 0.595215 0 21.8629 0 48.0006C0 74.1383 21.3163 95.4025 47.5254 95.4097C49.5656 95.4097 51.624 95.5511 53.6693 95.8328H54.8064V93.5435Z"
            style={{ zIndex: 1 }}
            transform={`translate(0 ${i * 183})`}
            fill="#ededed"
          />
          <path
            d="M12.149 99.0513C13.2907 96.2741 11.9595 93.0995 9.17554 91.9605C6.39159 90.8215 3.20916 92.1495 2.06738 94.9266C0.925598 97.7038 2.25683 100.878 5.04078 102.017C7.82473 103.156 11.0072 101.828 12.149 99.0513Z"
            fill="#F39519"
            transform={`translate(42 ${spaceBetweenCurves})`}
          />
          <path
            d="M7.98603 11.5979C10.9565 11.1191 12.9754 8.32874 12.4953 5.36555C12.0153 2.40237 9.21809 0.388435 6.24763 0.867305C3.27717 1.34617 1.25829 4.13651 1.73834 7.09969C2.21838 10.0629 5.01557 12.0768 7.98603 11.5979Z"
            fill="#F39519"
            transform={`translate(42 ${spaceBetweenCurves})`}
          />
        </g>,
      );
      spaceBetweenCurves += 183;
    }

    return (
      <svg
        width="6.9rem"
        viewBox={`0 0 128 ${quantity * 182}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {curves}
      </svg>
    );
  };

  const handleActivityButton = useCallback(
    (activity: IActivityProps): void => {
      if (activity.type === 'activity') {
        navigate(
          `/projects/${trail_slug}/${chapter_id}/activity/${activity.slug}`,
        );
      }
      if (activity.type === 'internal-link') {
        navigate(activity.resource_url);
      }
      if (activity.type === 'external-link') {
        window.location.href = activity.resource_url;
      }
    },
    [chapter_id, navigate, trail_slug],
  );
  return (
    <Container>
      <Line>{createCurves(activities.length)}</Line>
      <ListItems>
        {activities.map(activity => (
          <Activity key={activity.id}>
            <button
              data-testid="button-activity"
              type="button"
              onClick={() => handleActivityButton(activity)}
            >
              <IconArea>
                <IconActivity>
                  <Circle watched={activity.watched}>
                    <ActivityIcon width="2.167rem" fill="#e2e2e2" />
                  </Circle>
                  <CursorIcon width="3rem" />
                </IconActivity>
              </IconArea>
              <TextArea watched={activity.watched}>
                <h1>{t(`activities.${activity.slug}.title`)}</h1>
                <DurationWrapper>
                  <ClockIcon
                    width="1rem"
                    fill="none"
                    strokeWidth={1.5}
                    stroke="#e2e2e2"
                  />
                  <p>{`${activity.duration} min`}</p>
                </DurationWrapper>
                <p>
                  {activity.watched
                    ? t('spans.activity_done')
                    : t('spans.activity_todo')}
                </p>
              </TextArea>
            </button>
          </Activity>
        ))}
      </ListItems>
    </Container>
  );
};

export default ListActivities;
