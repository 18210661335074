import styled from 'styled-components';
import DotIcon from '../../../assets/svg/icons/dot.svg';

export const Container = styled.main`
  border: 3px solid #4caf50;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1rem;

  .primary-button {
    margin-bottom: 2rem;
    font-family: 'MarkPro-Bold';
    font-size: 1rem;
    line-height: 1.27rem;
    width: 48%;
    border-radius: 0.55rem;
    padding: 0.77rem 0px;
  }
`;

export const TextContent = styled.main`
  margin-top: 10rem;


  > .markdown-container {
    h1 {
      font-family: 'MarkPro-Heavy';
      font-size: 1.56rem;
      margin-bottom: 2rem;
    }

    h2 {
      font-family: 'MarkPro-Heavy';
      font-size: 1.44rem;
      margin-bottom: 1.8rem;
    }

    h3 {
      font-family: 'MarkPro-Heavy';
      font-size: 1.33rem;
      margin-bottom: 1.8rem;
    }

    p {
      max-width: 100%;
      font-size: 1.11rem;
      line-height: 1.78rem;
      margin-bottom: 1.5rem;
    }

    strong {
      font-family: 'MarkPro-Heavy';
    }

    a {
      font-family: 'MarkPro-Bold';

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      display: flex;
      max-width: 100%;
      border-radius: 1.11rem;
      margin: 1rem auto;
    }

    ul {
      margin-bottom: 1.5rem;
      padding-left: 0.5rem;
    }

    li {
      background: url(${DotIcon}) no-repeat left 5px;
      padding-left: 1.3rem;
      margin-bottom: 1.3rem;
    }
    `;

export const ImageArea = styled.main`
  margin-top: 10rem;

  > img {
    width: 38rem;
    height: auto;
  }
}
`;
