import { lighten } from 'polished';
import styled, { css } from 'styled-components';

interface ProgressProps {
  progress: number;
}

interface TimelineItemProps {
  watching: boolean;
}

interface IPlaylistProps {
  selected: boolean;
}

interface PlaylistCardProps {
  disabled?: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  width: 48.5vw;
`;

export const MainContentHeader = styled.header`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  > a {
    display: flex;
    align-items: center;
    margin-bottom: 0.89rem;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    line-height: 1.61rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'RedHataDisplay-Regular';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;

export const MainHeadContent = styled.div`
  border-bottom: 1px solid #d6d5d3;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  h1 {
    text-align: center;
    font-family: 'RedHatDisplay-Bold';
    line-heght: 2.2rem;
    margin-left: 1.94rem;
    color: #1e88e5;
  }
`;

export const InfoPlaylist = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 4.22rem;
  margin-top: 1.89rem;
  text-align: center;

  h2 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.67rem;
  }

  span {
    text-align: center;
    font-size: 1rem;
    margin-right: 2rem;
  }
`;

export const ListWrapper = styled.div`
  padding: 1.22rem;
`;

export const TimelineItem = styled.li<TimelineItemProps>`
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 1.1rem;

  > a {
    width: fit-content;
    height: fit-content;
  }

  &:before {
    content: '';
    background-color: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    position: absolute;
    bottom: 0px;
    top: 75%;
    left: 0.9%;
    width: 1px;
    height: 80%;
  }

  &:after {
    content: '';
    width: 0.833vw;
    height: 0.833vw;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    box-shadow: ${props =>
      props.watching ? ' 0px 0px 0px 0.5rem rgba(236, 119, 93, 0.1)' : '0'};
    position: absolute;
    left: 0;
  }

  &:nth-last-child(2):before {
    background: #e2e2e2;
  }

  &:last-child:before {
    height: 0;
  }
`;

const playlisCardStyle = {
  disabled: css``,
  enabled: css`
    box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  `,
};

export const PlaylistCard = styled.div<PlaylistCardProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 90%;
  height: 7.5rem;
  margin-left: 2.5rem;
  padding-left: 1.22rem;
  border-radius: 1.1rem;
  display: flex;
  align-items: center;
  flex-direction: center;
  background: ${props => (props.disabled ? '#f5f5f5' : '#fff')};
  /* box-shadow: 0px 3px 10px #00000029; */
  position: relative;
  transition: all 0.3s;

  &:hover {
    ${props =>
      props.disabled ? playlisCardStyle.disabled : playlisCardStyle.enabled}
  }
`;

export const IconContent = styled.div`
  width: 2.33rem;
  height: 2.33rem;
  border-radius: 50%;
  background-color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.56rem;
`;

export const VideoInfos = styled.div`
  margin-left: 2.22rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.38rem;
    font-family: 'RedHatDisplay-Bold';
    line-height: 1.38rem;
    color: #1e88e5;
    margin-bottom: 0.83rem;
  }

  > span {
    font-size: 0.89rem;
    margin-bottom: 0.83rem;
  }

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 0.56rem;
      font-family: 'RedHatDisplay-Bold';
      font-size: 0.83rem;
    }
  }

  /* > div {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: 'MarkPro-Heavy';
      font-size: 0.67rem;
    }
  } */
`;

export const ListContent = styled.ul`
  padding: 0.1rem;
`;

export const PlaylistsContent = styled.div`
  margin-left: 4rem;
  border-left: 1px solid #d6d5d3;
`;

export const ChaptersLists = styled.div`
  margin-left: 2.5rem;

  h2 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.22rem;
    margin-bottom: 3.11rem;
  }
`;

export const ChapterItem = styled.li<IPlaylistProps>`
  margin-bottom: 1.72rem;
  padding: 0.8rem;
  font-family: 'RedHatDisplay-Regular';
  border-radius: 0.89rem;
  outline: 2px solid;
  outline-color: ${props => (props.selected ? '#4caf50' : '#fff')};

  > button {
    display: flex;
    flex-direction: column;
    width: 26.56vw;
  }
  opacity: ${props => (props.selected ? '1' : '0.8')};
  transition: opacity 0.3s;
  transition: outline 0.5s;

  &:hover {
    opacity: 1;
    outline: 2px solid;
    outline-color: ${props => (props.selected ? '#4caf50' : '#e2e2e2')};
  }
`;

export const InfoBar = styled.div<ProgressProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.559rem;
  font-size: 0.89rem;

  > h3 {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
  }

  > span {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.125rem;
    color: ${props => (props.progress > 0 ? '#FF9800' : '#e2e2e2')};
  }
`;

export const Progress = styled.span`
  height: 0.33rem;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 5.56rem;
`;

export const Fill = styled.div<ProgressProps>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #ff9800;
  border-radius: inherit;
  text-align: right;
`;
