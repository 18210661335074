import styled from 'styled-components';
import { transparentize } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasToggleButton?: boolean;
  readOnly?: boolean;
  multiline?: boolean;
}

export const InputAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  position: relative;
`;

export const Container = styled.div<ContainerProps>`
  border: 0.5px solid
    ${props => (props.isFocused && !props.readOnly ? '#FF9800' : '#e2e2e2')};
  background: ${props =>
    props.readOnly ? transparentize(0.7, '#e2e2e2') : '#fff'};
  font-size: 1rem;
  padding: 0 0.8rem;
  padding-right: ${props => (props.multiline ? 0 : '1.167rem')};
  border-radius: 0.56rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  height: ${props => (props.multiline ? '9rem' : '3.05rem')};
  cursor: ${props => (props.readOnly ? 'not-allowed' : 'unset')};

  input {
    font-size: 1rem;
    background: transparent;
    flex: 1;
    border: 0;
    color: #323232;
    vertical-align: middle;
    cursor: ${props => (props.readOnly ? 'not-allowed' : 'unset')};

    &::placeholder {
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  textarea {
    cursor: ${props => (props.readOnly ? 'not-allowed' : 'unset')};
    font-family: 'RedHatDisplay-Regular';
    font-size: 0.88rem;
    line-height: 2.3rem;
    padding: 0.5rem 0;
    background: transparent;
    border: 0;
    color: #323232;
    width: 100%;
    height: 100%;
    resize: none;
  }

  > svg:first-child {
    margin-right: 0.88rem;
    stroke: ${props => (props.isFocused ? '#FF9800' : '#323232')};
    opacity: 1 !important;
    visibility: visible !important;
  }

  /* Code to hide Chrome's default datepicker icon*/
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const ToggleVisibilityButton = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  width: auto;
  display: flex;
  align-items: center;
`;

export const InputError = styled.p`
  text-align: right;
  font-size: 0.88rem;
  color: #ff2e3e;
  position: absolute;
  top: -1.44rem;
  right: 5px;
`;

export const ReadStatus = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  width: auto;
  display: flex;
  align-items: center;
`;
