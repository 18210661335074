import styled from 'styled-components';

interface CheckProps {
  check: boolean;
}

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  place-content: center;

  > input {
    width: 1.11rem;
    height: 1.11rem;
    border: 0;
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.label<CheckProps>`
  display: inline-block;
  width: 1.11rem;
  height: 1.11rem;
  border: ${props => (props.check ? '0' : '1px solid #e2e2e2')};
  background: ${props => (props.check ? '#FF9800' : '#fff')};
  border-radius: 0.167rem;
  transition: all 0.3s;
  /*
  input:focus + &:hover {
    box-shadow: 0 0 0 3px pink;
  } */

  ${Icon} {
    visibility: ${props => (props.check ? 'visible' : 'hidden')};
  }
`;
