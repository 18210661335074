import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  ICreateFeedbackProps,
  IFeedbacksProps,
  IUpdateFeedbackProps,
} from '../dtos/IFeedbacksProps';
import api from '../services/api';
import { getDeviceType } from '../utils/getDataFromAccess';
import { ILocationData } from '../dtos/ILocationsProps';

interface FeedbackContextData {
  feedbacks: IFeedbacksProps;
  handleFeedback: (feedback: ICreateFeedbackProps) => void;
  updateFeedback: (feedback: IUpdateFeedbackProps) => void;
  openFeedback: boolean;
  setOpenFeedback: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserLocationProps {
  city: string;
  state: string;
  country: string;
}

const FeedbackContext = createContext<FeedbackContextData>(
  {} as FeedbackContextData,
);

const FeedbackProvider: React.FC = ({ children }) => {
  const [feedbacks, setFeedback] = useState<IFeedbacksProps>(
    {} as IFeedbacksProps,
  );
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedbackId, setFeedbackId] = useState<string>('');
  const [userLocation, setUserLocation] = useState<
    UserLocationProps | undefined
  >();
  const startTime = Date.now();

  const handleEngagementTime = useCallback((): number => {
    return Math.round((Date.now() - startTime) / 1000);
  }, [startTime]);

  const createFeedback = useCallback(
    async (data: ICreateFeedbackProps): Promise<void> => {
      const deviceType = getDeviceType();
      const engagementTime = handleEngagementTime();

      const updateData = {
        ...data,
        engagement_time: engagementTime,
        device_type: deviceType,
        user_location: `${userLocation?.country}, ${userLocation?.state}, ${userLocation?.city}`,
      };

      api
        .post<IFeedbacksProps>('/feedbacks', updateData)
        .then(response => {
          setFeedback(response.data);
          setFeedbackId(response.data.id);
        })
        .catch();
    },
    [handleEngagementTime, userLocation],
  );

  const handleFeedback = useCallback(
    async (data: ICreateFeedbackProps): Promise<void> => {
      try {
        const response = await api.get(`/feedbacks/${data.activity_id}`);

        if (response.status === 204) {
          await createFeedback(data);
        } else if (response.status === 200) {
          setFeedback(response.data);
          setFeedbackId(response.data.id);
        }
      } catch (error) {
        console.error('Erro ao buscar feedback:', error);
      }
    },
    [createFeedback],
  );

  const updateFeedback = useCallback(
    async (data: IUpdateFeedbackProps): Promise<void> => {
      if (!feedbackId) {
        return;
      }

      const handleEngagement = handleEngagementTime();

      const updateData = {
        ...data,
        engagement_time: handleEngagement,
      };

      api
        .patch(`/feedbacks/${feedbackId}`, updateData)
        .then(response => {
          setFeedback(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [feedbackId, handleEngagementTime],
  );

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const { latitude, longitude } = position.coords;

        try {
          const locationData = await fetchLocationData(latitude, longitude);
          setUserLocation(locationData);
        } catch (error) {
          console.log(error);
        }
      });
    } else {
      setUserLocation(undefined);
    }
  }, []);

  const fetchLocationData = async (
    latitude: number,
    longitude: number,
  ): Promise<UserLocationProps> => {
    try {
      const response = await fetch(
        `https://us1.locationiq.com/v1/reverse?key=${process.env.REACT_APP_LOCATIONIQ_API_KEY}&lat=${latitude}&lon=${longitude}&format=json&`,
      );

      const result = (await response.json()) as ILocationData;

      if (result) {
        return {
          city: result.address.city || 'Unknown',
          state: result.address.state || 'Unknown',
          country: result.address.country || 'Unknown',
        };
      }

      return { city: 'Unknown', state: 'Unknown', country: 'Unknown' };
    } catch (error) {
      throw new Error(`Error fetching location data: `);
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        openFeedback,
        setOpenFeedback,
        feedbacks,
        handleFeedback,
        updateFeedback,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

function useFeedback(): FeedbackContextData {
  const context = React.useContext(FeedbackContext);

  if (!context) {
    throw new Error('useFeedback must be used within FeedbackProvider');
  }

  return context;
}

export { FeedbackProvider, useFeedback };
