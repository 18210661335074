import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const LegoBricks = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M23.612 12.272h-.625v-.932a.388.388 0 0 0-.388-.388h-2.64a.388.388 0 0 0-.389.388v.932h-.327V7.224a.388.388 0 0 0-.388-.388h-.488v-.932a.388.388 0 0 0-.389-.388h-2.64a.388.388 0 0 0-.388.388v.932h-1.126v-.932a.388.388 0 0 0-.389-.388h-2.64a.388.388 0 0 0-.388.388v.932h-.488a.388.388 0 0 0-.389.388v5.048h-.405v-.932a.388.388 0 0 0-.388-.388h-2.64a.388.388 0 0 0-.389.388v.932H4.582v-.932a.388.388 0 0 0-.388-.388h-2.64a.388.388 0 0 0-.39.388v.932H.389A.388.388 0 0 0 0 12.66v5.436c0 .215.174.389.388.389h23.224a.388.388 0 0 0 .388-.389V12.66a.388.388 0 0 0-.388-.388Zm-3.265-.544h1.863v.544h-1.863v-.544Zm-4.62-5.436h1.863v.544h-1.864v-.544Zm-4.544 0h1.864v.544h-1.864v-.544Zm-.876 1.32h8.159v4.66h-8.159v-4.66Zm-3.822 4.116h1.863v.544H6.485v-.544Zm-4.543 0h1.863v.544H1.942v-.544Zm-1.165 1.32h13.28v4.66H.776v-4.66Zm22.446 4.66h-8.39v-4.66h8.39v4.66Z" />
  </svg>
);

export default LegoBricks;
