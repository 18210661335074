import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon, ToolsIcon } from '../../../assets/svg/SVGComponents';
import {
  ContentWrapper,
  HeaderContent,
  HeaderMainContent,
  MainContent,
  TitleWrapper,
} from './styles';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import AddHardwareUnlockModal from '../../../modals/AddHardwareUnlockModal';
import { useModal } from '../../../hooks/modal';
import GridHardware from '../../../components/GridHardware';
// import { Container } from './styles';

const HardwarePage: React.FC = () => {
  const { handleModal } = useModal();

  const handleHardwareUnlockButton = useCallback(() => {
    handleModal(<AddHardwareUnlockModal />);
  }, [handleModal]);

  const { t } = useTranslation();
  return (
    <MainContent>
      <HeaderContent>
        <HeaderMainContent>
          <TitleWrapper>
            <ToolsIcon
              width="2rem"
              stroke="#FF9800"
              strokeWidth={3}
              fill="none"
            />
            <h1>{t('h1.tools')}</h1>
          </TitleWrapper>

          <PrimaryButton type="button" onClick={handleHardwareUnlockButton}>
            Destravar Ferramenta
          </PrimaryButton>
        </HeaderMainContent>
      </HeaderContent>
      <ContentWrapper>
        <GridHardware />
      </ContentWrapper>
    </MainContent>
  );
};

export default HardwarePage;
