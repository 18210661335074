import React from 'react';

const SvgLogout: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.163 21l4.444-.988A1.777 1.777 0 0020 18.277V5.766c0-.833-.579-1.555-1.392-1.735l-4.444-.988A1.778 1.778 0 0012 4.779v14.486A1.777 1.777 0 0014.163 21zM15 10.98v2M4 16v2.042a2 2 0 002 2h3M4 8V6a2 2 0 012-2h3m-5 8h5m-2 2l2-2-2-2"
        />
      </g>
    </svg>
  );
};
export default SvgLogout;
