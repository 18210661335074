import styled from 'styled-components';

export const Container = styled.div`
  font-size: 1rem;
  z-index: 2001;
  min-width: 45vw;
  width: 45vw;

  > form {
    max-width: 75%;
    padding-bottom: 3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;

    > label {
      display: flex;
      justify-content: flex-start;
      font-family: 'RedHatDisplay-Regular';
      font-size: 0.88em;
      text-align: left;
      margin-top: 3%;
      margin-bottom: 1.5%;
    }

    > div {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2.5rem;
  border-bottom: 1px solid #e3e2e2;

  > div {
    display: flex;
    align-items: center;

    > h3 {
      font-family: 'RedHatDisplay-Medium';
      font-size: 1.4rem;
      line-height: 3rem;
      margin-left: 0.67rem;
    }
  }

  > svg {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: rotate(90deg);
      opacity: 0.8;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  margin: 2rem auto 0rem;

  > h1 {
    font-family: 'RedHatDisplay-Medium';
    font-size: 2.33rem;
    text-align: center;
    margin-bottom: 1rem;

    > strong {
      font-family: 'RedHatDisplay-ExtraBold';
    }
  }

  p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1.22rem;
    line-height: 2.4rem;
    text-align: center;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  > .primary-button {
    width: 100%;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.33rem;
    height: 4.44rem;
    margin: 2rem 0;
    border-radius: 0.55rem;
    box-shadow: 0px 3px 15px rgba(236, 119, 93, 0.3);
  }
`;

export const CancelButton = styled.button`
  font-family: 'RedHatDisplay-Regular';
  font-size: 1.22rem;
  line-height: 1.33rem;
  width: auto;
  color: #323232;
  border-bottom: 1px solid #323232;
  text-decoration: none;
  opacity: 0.8;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
`;
