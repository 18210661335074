import React from 'react';

const SvgEyeHidden: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="eyeHidden_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#eyeHidden_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 19a6.919 6.919 0 01-2.5-.5M20.882 12.468C18.989 15.967 15.494 19 12 19M19.079 8.921a15.135 15.135 0 011.8 2.612.987.987 0 010 .935M5 19L19 5M9.773 14.227a3.15 3.15 0 114.454-4.455" />
        <path d="M17.044 6.956A8.421 8.421 0 0012 5c-3.495 0-6.99 3.033-8.882 6.533a.985.985 0 000 .934 14.732 14.732 0 003.838 4.577" />
      </g>
    </svg>
  );
};

export default SvgEyeHidden;
