import * as React from 'react';
import { SVGProps } from 'react';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 55"
    fill="none"
    {...props}
  >
    <path d="M34.874 27.24c-.866-.787-1.829-1.23-2.951-1.18-.516.023-1.026.207-1.541.293-.145.022-.36.012-.45-.078-1.358-1.328-2.703-2.671-4.078-4.039.121-.134.22-.253.327-.36.994-.993 1.986-1.991 2.99-2.974.193-.19.242-.347.158-.62-1.33-4.373-.537-8.324 2.602-11.665 2.553-2.715 5.78-3.89 9.524-3.564.24.021.478.04.715.071.432.059.74.285.892.699.161.44.029.818-.288 1.138a389.43 389.43 0 0 1-2.271 2.27c-.755.746-1.284 1.616-1.517 2.662-.759 3.418 2.106 6.56 5.588 6.147 1.214-.144 2.225-.648 3.083-1.493.75-.74 1.5-1.48 2.237-2.236.365-.374.783-.58 1.292-.397.514.186.657.62.72 1.135.316 2.567-.164 4.966-1.387 7.246-2.047 3.815-6.744 6.344-11.058 5.867-.886-.098-1.758-.311-2.628-.506-.246-.054-.407-.05-.585.146-.44.484-.904.946-1.376 1.433l.002.004ZM7.432 52.001c-1.06-.213-2.049-.562-2.865-1.322-1.935-1.805-2.105-4.884-.358-6.869.126-.144.26-.282.396-.416l14.419-14.378c.088-.088.182-.172.314-.295.695.698 1.38 1.389 2.066 2.075.644.644 1.298 1.278 1.928 1.934.1.107.178.348.127.469-.656 1.619-.423 3.085.699 4.418.03.035.048.08.088.148-.117.124-.23.25-.352.37-3.857 3.847-7.729 7.677-11.56 11.549-1.1 1.11-2.24 2.079-3.847 2.317H7.43h.002Z" />
    <path d="M43.633 52.001c-.198-.048-.397-.1-.594-.146-.996-.23-1.816-.745-2.536-1.464-4.735-4.734-9.48-9.457-14.219-14.186-.91-.908-.908-1.73.008-2.642.227-.224.474-.427.663-.594a108512.66 108512.66 0 0 0-15.941-15.895c-.111.146-.266.414-.48.621-.822.801-1.838.732-2.52-.182a1043.388 1043.388 0 0 1-4.609-6.241c-.556-.758-.493-1.536.164-2.2a249 249 0 0 1 2.58-2.574c.647-.635 1.44-.709 2.17-.177a796.038 796.038 0 0 1 6.296 4.627c.916.681.973 1.685.17 2.512-.2.205-.437.372-.619.527C19.493 19.3 24.751 24.54 30.12 29.897c.147-.19.32-.463.543-.687.887-.895 1.724-.895 2.613-.008 3.962 3.947 7.922 7.896 11.881 11.845.87.868 1.766 1.715 2.605 2.614 2.34 2.517 1.537 6.55-1.569 7.895-.476.207-1 .299-1.503.445h-1.057Z" />
  </svg>
);
export default SvgComponent;
