import styled from 'styled-components';
import DotIcon from '../../../assets/svg/icons/dot.svg';
import Watched from '../../../assets/images/watched-check.svg';
import Play from '../../../assets/images/video-play.svg';
import Accordion from '../../../components/Accordion';

interface LessonItemProps {
  status: 'completed' | 'active' | 'pending' | 'disabled';
  background?: string;
  disable?: boolean;
}

export const Content = styled.div`
  max-width: 100vw;
  display: flex;
`;

export const MainContent = styled.div`
  flex: 1;
  padding: 2.98rem 3.89rem;
  max-width: calc(100% - var(--videobar-width));
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin-bottom: 3rem;
  > a {
    display: flex;
    align-items: center;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    line-height: 1.61rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const BreadCrumb = styled.div`
  margin-top: 3.05rem;

  > span {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    line-height: 1.5rem;
  }

  > a {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: none;
  }

  > a:last-child {
    text-decoration: none;
    color: #ff9800;
  }

  > a:hover {
    text-decoration: underline;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 31vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-player > div > iframe {
    border-radius: 1.1rem;
    overflow: hidden;
  }
`;

export const NavigationMenu = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 3.56rem;

  .student-class-navBar {
    background: none;
    border: 0;
    box-shadow: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  }

  .student-classroom-container {
    overflow: visible !important;
  }

  .MuiTabs-scroller.MuiTabs-fixed {
    overflow: visible !important;
  }

  .student-classroom-tab-item {
    > span {
      margin-bottom: 1.1rem;
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.22rem;
      color: #323232 !important;
      text-transform: none;
    }
  }

  .student-classroom-tab-item + .student-classroom-tab-item {
    margin-left: 2.4rem;
  }

  .chevron-disabled-tab-item {
    opacity: 1 !important;
    width: auto;
    padding: 0;
    margin-bottom: 1.1rem;
  }
`;

export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContent = styled.div`
  display: grid;
  margin-top: 3.2rem;
  margin-bottom: 1rem;
  aside {
    width: 22.11rem;
    height: 15rem;
    background: #fff;
    border: 1px solid #e2e2e2;
    margin-left: 3.11rem;
    padding: 1.97rem;
    border-radius: 0.56rem;

    h2 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.22rem;
      line-height: 1.78rem;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1.5rem;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: disc;
        list-style-position: inherit;
        font-family: 'RedHatDisplay-Bold';
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
`;
export const TextArea = styled.div`
  flex: 1;
  border-radius: 1.1rem;
  grid-area: 1 /1;

  > .markdown-container {
    h1 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.56rem;
      margin-bottom: 2rem;
      color: #1e88e5;
    }

    h2 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.44rem;
      margin-bottom: 1.8rem;
      color: #1e88e5;
    }

    h3 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.33rem;
      margin-bottom: 1.8rem;
      color: #1e88e5;
    }

    p {
      max-width: 100%;
      font-size: 1.11rem;
      line-height: 1.78rem;
      margin-bottom: 1.5rem;
      color: #323232;
    }

    strong {
      font-family: 'RedHatDisplay-ExtraBold';
    }

    a {
      font-family: 'RedHatDisplay-Bold';

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      display: flex;
      max-width: 100%;
      border-radius: 1.11rem;
      margin: 1rem auto;
    }

    ul {
      margin-bottom: 1.5rem;
      padding-left: 0.5rem;
    }

    li {
      background: url(${DotIcon}) no-repeat left 5px;
      padding-left: 1.3rem;
      margin-bottom: 1.3rem;
    }
  }
`;

export const QuestionAlert = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding: 1.1rem;
  background: rgba(255, 186, 8, 0.05);
  border: 1px solid rgba(255, 186, 8, 1);
  border-radius: 1.1rem;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 3px 15px rgba(255, 186, 8, 0.3);
  }

  h4 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.22rem;
    margin-bottom: 0.94rem;

    > strong {
      font-family: 'RedHatDisplay-ExtraBold';
    }
  }

  p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
    line-height: 1.3rem;
  }
`;

export const FilledIconBackground = styled.div`
  width: 4.4rem;
  height: 4.4rem;
  background: #fff2d0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
`;

export const OutlinedIconBackground = styled.div`
  width: 2.89rem;
  height: 2.89rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffba08;
  margin-left: auto;
`;

export const RightSidebar = styled.div`
  width: var(--videobar-width);
  min-height: 100vh;
  padding: 2.44rem;
  height: auto;
  background: #1e88e5;
`;

export const AccordionContainer = styled(Accordion)`
  button {
    div {
      h4 {
        font-size: 1rem;
      }
    }
  }
`;

export const LessonsList = styled.ul`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Lesson = styled.li<LessonItemProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  margin-top: 1.44rem;
  overflow: visible;
  width: 100%;
  padding-left: 1.8rem;
  transition: opacity 0.3s;

  > a {
    position: relative;
    display: flex;
  }

  h4 {
    font-family: 1rem;
  }

  &::before {
    content: ' ';
    background: ${props =>
      props.status === 'completed'
        ? 'rgba(236, 119, 93, 0.7)'
        : 'rgba(255,255,255,0.1)'};
    position: absolute;
    top: 80%;
    left: 0;
    width: 0.5px;
    height: 60%;
  }

  &::after {
    content: '';
    width: 0.88rem;
    height: 0.88rem;
    top: calc(50% - 0.44rem);
    border-radius: 50%;
    background: ${props =>
      props.status === 'pending' ? '#e2e2e2' : '#ec775d'};
    position: absolute;
    left: -0.44rem;
    z-index: 1;

    box-shadow: ${props =>
      props.status === 'active' ? ' 0px 0px 0px rgba(236, 119, 93, 0.4)' : '0'};
    animation: ${props =>
      props.status === 'active' ? 'pulse 2s infinite' : 'none'};

    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 rgba(236, 119, 93, 0.4);
        box-shadow: 0 0 0 0 rgba(236, 119, 93, 0.4);
      }
      70% {
        -moz-box-shadow: 0 0 0 8px rgba(236, 119, 93, 0);
        box-shadow: 0 0 0 8px rgba(236, 119, 93, 0);
      }
      100% {
        -moz-box-shadow: 0 0 0 0 rgba(236, 119, 93, 0);
        box-shadow: 0 0 0 0 rgba(236, 119, 93, 0);
      }
    }
  }

  &:last-child::before {
    height: 0;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const ThumbnailWrapper = styled.div<LessonItemProps>`
  position: relative;
  width: 50%;
  display: flex;
  margin-right: 5%;
  display: inline-block;

  > img {
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 0.55rem;
  }

  &::before {
    position: absolute;
    border-radius: 0.5rem;
    display: inline-block;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0.1px;
    background-color: ${props =>
      props.status === 'completed'
        ? 'rgba(50, 54, 80, 0.8)'
        : 'rgba(50, 54, 80, 0.2)'};
    background-image: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.status === 'completed'
        ? `url(${Watched})`
        : props.status === 'disabled'
        ? `url(${props.background})`
        : `url(${Play})`};
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const LessonInfos = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: auto;

  > h4 {
    font-family: 'MarkPro-Heavy';
    color: #fff;
    font-size: 0.83rem;
    line-height: 1.22rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  > p {
    font-family: 'MarkPro';
    font-size: 0.67rem;
    line-height: 1rem;
    color: #fff;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: #fff;

    > div {
      font-family: 'MarkPro-Bold' !important;
      margin-right: 1rem;
      font-size: 0.64rem !important;
    }

    > p {
      margin-left: 3px;
      font-family: 'MarkPro-Bold';
      font-size: 0.64rem;
      line-height: 1rem;
    }
  }
`;

export const LessonLevelTag = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'MarkPro-Heavy';
  font-size: 0.64rem;
  line-height: 0.8rem;
  padding: 0.4rem 0.6rem;
  border: 1px solid #ec775d;
  border-radius: 1.44rem;
  margin-right: 1rem;
`;
