import React from 'react';

const SvgGender: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="gender_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#gender_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={6.25} cy={6.25} r={6.25} transform="translate(2.997 8.5)" />
        <circle cx={6.25} cy={6.25} r={6.25} transform="translate(8.5 3)" />
      </g>
    </svg>
  );
};

export default SvgGender;
