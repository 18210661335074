import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const CursorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 71"
    fill="none"
    {...props}
  >
    <path
      fill="#EDEDED"
      d="M2.986 70.257c11.27-8.13 17.997-21.253 17.997-35.112 0-13.86-6.746-27.018-18.047-35.145L0 4.062c9.997 7.19 15.961 18.811 15.961 31.086 0 12.275-5.95 23.864-15.922 31.054l2.94 4.055h.007Z"
    />
    <path fill="#F39519" d="M54.288 34H19v2h35.288v-2Z" />
  </svg>
);
export default CursorIcon;
