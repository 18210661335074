import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Background, Container, Step, Steps } from './styles';
import Line3Steps from '../../assets/svg/SVGComponents/Line3Steps';
import Line2Steps from '../../assets/svg/SVGComponents/Line2Steps';
import { StepType } from '../../utils/enums';
import IntroductionBook from '../../assets/svg/SVGComponents/IntroductionBook';
import {
  CodeText,
  KeyboardIcon,
  Microchip,
  SimulationIcon,
} from '../../assets/svg/SVGComponents';
import LegoBricks from '../../assets/svg/SVGComponents/LegoBricks';
import IStepProps from '../../dtos/IStepsProps';

interface ProgressBarProps {
  total_steps: number;
  steps: ProgressBarStepsProps[];
  current_step: ProgressBarStepsProps;
}
interface ProgressBarStepsProps {
  step: IStepProps;
  progress: number;
  order: number;
}

const ProgressBarTutorial: React.FC<ProgressBarProps> = ({
  total_steps,
  steps,
  current_step,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Background>
        {total_steps === 2 && <Line2Steps width="23.5rem" fill="#1E88E5" />}
        {steps.length === 3 && <Line3Steps width="23.5rem" />}
      </Background>
      <Steps steps={steps.length}>
        {steps.map((step, index) => (
          <Step key={index} accomplished={index + 1 <= current_step.order}>
            <span>{t(`spans.step_label_${step.step.step_type}`)}</span>

            <div>
              <p />
              <div>
                {step.step.step_type === StepType.INTRO && (
                  <IntroductionBook
                    width="2rem"
                    strokeWidth={1.5}
                    fill="none"
                  />
                )}
                {step.step.step_type === StepType.CODING && (
                  <KeyboardIcon width="2rem" strokeWidth={1.5} fill="none" />
                )}
                {step.step.step_type === StepType.CIRCUIT && (
                  <Microchip
                    width="2rem"
                    fill={
                      index + 1 <= current_step.order ? '#FFFEFC' : '#b7b7b7'
                    }
                  />
                )}
                {step.step.step_type === StepType.SIMULATOR && (
                  <SimulationIcon
                    width="2rem"
                    fill={
                      index + 1 <= current_step.order ? '#FFFEFC' : '#b7b7b7'
                    }
                  />
                )}
                {step.step.step_type === StepType.BUILDING && (
                  <LegoBricks width="2rem" strokeWidth={1} fill="none" />
                )}
                {step.step.step_type === StepType.BEHIND && (
                  <CodeText width="2rem" strokeWidth={1.5} fill="none" />
                )}
                {step.step.step_type === StepType.LEARN && (
                  <CodeText width="2rem" strokeWidth={1.5} fill="none" />
                )}
                {step.step.step_type === StepType.TEACHER && (
                  <CodeText width="2rem" strokeWidth={1.5} fill="none" />
                )}
              </div>
            </div>
          </Step>
        ))}
      </Steps>
    </Container>
  );
};

export default ProgressBarTutorial;
