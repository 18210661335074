import styled from 'styled-components';

export const Container = styled.button`
  background: #ff9800;
  background-size: 200% auto;
  transition: 0.3s;
  color: #fff;
  font-family: 'MarkPro-Bold';
  outline: 0;
  box-shadow: 0px 3px 6px rgba(255, 152, 0, 0.3);

  &:hover {
    background-position: right center;
  }

  &.loading {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
