import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Dotcontent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" {...props}>
    <path
      fill="#EC775D"
      fillOpacity={0.1}
      d="M20 40.767c11.046 0 20-8.971 20-20.037C40 9.664 31.046.693 20 .693S0 9.663 0 20.73c0 11.066 8.954 20.037 20 20.037Z"
    />
    <path
      fill="#FF9800"
      d="M20 31.416c5.89 0 10.666-4.784 10.666-10.686S25.891 10.043 20 10.043 9.333 14.828 9.333 20.73c0 5.902 4.776 10.686 10.667 10.686Z"
    />
  </svg>
);
export default Dotcontent;
