import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Line2Steps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 383 142"
    fill="none"
    {...props}
  >
    <path
      fill="#1E88E5"
      d="M313.082 142c-38.554 0-69.909-31.249-69.909-69.648v-2.694c0-28.386-23.186-51.485-51.678-51.485-28.492 0-51.678 23.09-51.678 51.486v2.014c0 38.41-31.365 69.649-69.909 69.649C31.366 141.322 0 110.073 0 71.673V68.98l18.23-.01v2.693c0 28.386 23.186 51.486 51.678 51.486 28.492 0 51.679-23.1 51.679-51.486V69.65C121.587 31.239 152.952 0 191.495 0c38.543 0 69.909 31.249 69.909 69.648v2.694c0 28.386 23.186 51.485 51.678 51.485 28.492 0 51.678-23.099 51.678-51.486H383c0 38.41-31.365 69.649-69.908 69.649l-.01.01Z"
    />
  </svg>
);
export default Line2Steps;
