import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Keyboard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 21H4a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2ZM12 14.49v0M9 17.5h6M12 11V9.5A2.5 2.5 0 0 1 14.5 7v0A2.5 2.5 0 0 0 17 4.5V3"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 14.445a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05M18 14.445a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05M12 14.45a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05M6 14.45a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05M9 14.45a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05M18 17.45a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05M6 17.445a.05.05 0 0 1 .05.05.05.05 0 0 1-.05.05.05.05 0 0 1-.05-.05.05.05 0 0 1 .05-.05"
      />
    </g>
  </svg>
);

export default Keyboard;
