import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { PadlockClose, PadlockOpen } from '../../../assets/svg/SVGComponents';
import { MainContent, ToolCard, ToolsGrid } from './styles';
import imageFuzzyBitsLocked from '../../../assets/images/card-fuzzy-start-gray.jpg';
import imageFuzzyBitsUnlocked from '../../../assets/images/card-fuzzy-start-color.jpg';
import imageMicroBitLocked from '../../../assets/images/card-microbit-gray-700x420px.png';
import imageMicroBitUnlocked from '../../../assets/images/card-microbit-color-700x420px.png';
import imageMakeyMakeyLocked from '../../../assets/images/card-makemakey-gray-700x420px.png';
import imageMakeyMakeyUnlocked from '../../../assets/images/card-makemakey-color-700x420px.png';
import imageScratchLocked from '../../../assets/images/card-scratch-gray.jpg';
import imageScratchUnlocked from '../../../assets/images/card-scratch-color.jpg';
import imageFuzzyPlusLocked from '../../../assets/images/card-fuzzy-plus-gray.jpg';
import imageFuzzyPlusUnlocked from '../../../assets/images/card-fuzzy-plus-color.jpg';

import { useAuth } from '../../../hooks/auth';
import { HardwareSlug } from '../../../utils/enums';

const HardwarePage: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const handleStatusHardware = useCallback(
    (SlugHardware: HardwareSlug): boolean => {
      const unlockHardwares = user.hardwares.find(
        hardware => hardware.slug === SlugHardware,
      );
      if (unlockHardwares) {
        return true; // unlock
      }
      return false; // locked
    },
    [user.hardwares],
  );

  return (
    <MainContent>
      <ToolsGrid>
        <ToolCard
          to={
            handleStatusHardware(HardwareSlug.FUZZYBITS)
              ? `/trails/${HardwareSlug.FUZZYBITS}`
              : pathname
          }
          className="item-1"
          background={
            handleStatusHardware(HardwareSlug.FUZZYBITS)
              ? imageFuzzyBitsUnlocked
              : imageFuzzyBitsLocked
          }
        >
          <span>Fuzzy Start</span>
          <p>
            {handleStatusHardware(HardwareSlug.FUZZYBITS) ? (
              <PadlockOpen fill="none" width="1.22rem" />
            ) : (
              <PadlockClose fill="#fff" width="1.22rem" />
            )}
          </p>
        </ToolCard>
        <ToolCard
          to={
            handleStatusHardware(HardwareSlug.FUZZYPLUS)
              ? `/trails/${HardwareSlug.FUZZYPLUS}`
              : `${pathname}`
          }
          className="item-2"
          background={
            handleStatusHardware(HardwareSlug.FUZZYPLUS)
              ? imageFuzzyPlusUnlocked
              : imageFuzzyPlusLocked
          }
        >
          <span>Fuzzy Plus+</span>
          <p>
            {handleStatusHardware(HardwareSlug.FUZZYPLUS) ? (
              <PadlockOpen fill="none" width="1.5rem" />
            ) : (
              <PadlockClose fill="#fff" width="1.5rem" />
            )}
          </p>
        </ToolCard>
        <ToolCard
          to={
            handleStatusHardware(HardwareSlug.MICROBIT)
              ? `/trails/${HardwareSlug.MICROBIT}`
              : pathname
          }
          className="item-3"
          background={
            handleStatusHardware(HardwareSlug.MICROBIT)
              ? imageMicroBitUnlocked
              : imageMicroBitLocked
          }
        >
          <span>Micro:bit</span>
          <p>
            {handleStatusHardware(HardwareSlug.MICROBIT) ? (
              <PadlockOpen fill="none" width="1.22rem" />
            ) : (
              <PadlockClose fill="#fff" width="1.22rem" />
            )}
          </p>
        </ToolCard>
        <ToolCard
          to={
            handleStatusHardware(HardwareSlug.MAKEYMAKEY)
              ? `/trails/${HardwareSlug.MAKEYMAKEY}`
              : pathname
          }
          className="item-4"
          background={
            handleStatusHardware(HardwareSlug.MAKEYMAKEY)
              ? imageMakeyMakeyUnlocked
              : imageMakeyMakeyLocked
          }
        >
          <span>Makey Makey</span>
          <p>
            {handleStatusHardware(HardwareSlug.MAKEYMAKEY) ? (
              <PadlockOpen fill="none" width="1.22rem" />
            ) : (
              <PadlockClose fill="#fff" width="1.22rem" />
            )}
          </p>
        </ToolCard>
        <ToolCard
          to={
            handleStatusHardware(HardwareSlug.SCRATCH)
              ? `/hardwares/profile/scratch`
              : `${pathname}`
          }
          className="item-5"
          background={
            handleStatusHardware(HardwareSlug.SCRATCH)
              ? imageScratchUnlocked
              : imageScratchLocked
          }
        >
          <span>Scratch</span>
          <p>
            {handleStatusHardware(HardwareSlug.SCRATCH) ? (
              <PadlockOpen fill="none" width="1.22rem" />
            ) : (
              <PadlockClose fill="#fff" width="1.22rem" />
            )}
          </p>
        </ToolCard>
      </ToolsGrid>
    </MainContent>
  );
};

export default HardwarePage;
