import styled from 'styled-components';

export const Container = styled.div`
  font-size: 1rem;
  z-index: 2001;
  min-width: 56vw;
  min-height: 56vh;
  height: 80vh;
  width: 80vw;
  padding-top: 3.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .primary-button {
    margin-top: 1rem;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.33rem;
    height: 3.11rem;
    width: 13rem;
    border-radius: 0.55rem;
    box-shadow: 0px 3px 15px rgba(255, 153, 0, 0.3);
  }
`;

export const Logo = styled.div`
  width: 100%;
  margin-left: 11rem;
  margin-bottom: 2.56rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 90%;
  overflow-y: scroll;
  direction: rtl;

  ::-webkit-scrollbar {
    width: 8px;
    background: #c4c4c4;
    border-radius: 4px;
  }

  ::-webkit-track {
    -webkit-appearance: none;
    background: #c4c4c4;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background: #ff9800;
    border-radius: 4px;
  }
  > div {
    direction: ltr;
    margin-left: 1rem;
    > .markdown-container {
      h1 {
        font-family: 'RedHatDisplay-ExtraBold';
        font-size: 1.56rem;
        margin-bottom: 2rem;
      }

      h2 {
        font-family: 'RedHatDisplay-ExtraBold';
        font-size: 1.44rem;
        margin-bottom: 1.8rem;
      }

      h3 {
        font-family: 'RedHatDisplay-ExtraBold';
        font-size: 1.33rem;
        margin-bottom: 1.8rem;
      }

      p {
        font-size: 1.11rem;
        line-height: 1.78rem;
        margin-bottom: 1.5rem;
      }

      strong {
        font-family: 'RedHatDisplay-ExtraBold';
      }

      a {
        font-family: 'RedHatDisplay-Bold';

        &:hover {
          text-decoration: underline;
        }
      }

      img {
        display: flex;
        max-width: 100%;
        border-radius: 1.11rem;
        margin: 1rem auto;
      }

      ul {
        margin-bottom: 1.5rem;
        padding-left: 0.5rem;
      }
    }
  }
`;
