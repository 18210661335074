import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading, ...rest }, ref) => (
    <Container
      className={`secondary-button ${loading ? 'loading' : ''}`}
      ref={ref}
      {...rest}
    >
      {loading ? 'Carregando...' : children}
    </Container>
  ),
);

export default SecondaryButton;
