import React, { memo } from 'react';
import Header from '../../components/Header';
import LeftSideBar from '../../components/LeftSideBar';
import { useAuth } from '../../hooks/auth';
import { UserRole } from '../../utils/enums';

import { Container, Content } from './styles';

const MainLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <LeftSideBar />

      <Content>
        <Header />
        {children}
      </Content>
    </Container>
  );
};

export default memo(MainLayout);
