/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FuzzyLogoNewOrange = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={270}
    height={38}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#FF9800"
        d="M176.857 32.279a.571.571 0 0 1-.569-.572v-10.25c0-2.66-1.237-4.183-3.393-4.183-2.296 0-3.779 1.682-3.779 4.281v10.152a.571.571 0 0 1-.569.572h-3.544a.572.572 0 0 1-.57-.572V21.521c0-2.66-1.282-4.25-3.426-4.25-2.28 0-3.812 1.746-3.812 4.345v10.088a.572.572 0 0 1-.569.572h-3.545a.572.572 0 0 1-.569-.572V13.4c0-.315.256-.572.569-.572h3.481c.313 0 .569.257.569.572v1.284c1.206-1.205 2.862-1.887 4.683-1.887 2.287.034 4.279 1.055 5.408 2.722 1.416-1.697 3.722-2.722 6.228-2.722 2.25 0 4.083.746 5.444 2.223 1.383 1.422 2.086 3.398 2.086 5.86v10.83a.572.572 0 0 1-.569.572h-3.545l-.009-.003ZM192.595 32.209c-2.662 0-4.891-.955-6.626-2.832-1.724-1.865-2.598-4.214-2.598-6.978 0-2.764.853-5.03 2.604-6.859 1.735-1.81 3.961-2.727 6.62-2.727 2.058 0 3.855.672 5.35 2v-1.398c0-.315.256-.572.569-.572h3.411c.313 0 .569.257.569.572v18.222a.572.572 0 0 1-.569.572h-3.411a.572.572 0 0 1-.569-.572v-1.413c-1.501 1.318-3.295 1.985-5.35 1.985Zm.481-14.99c-1.468 0-2.68.514-3.603 1.526-.937 1.03-1.394 2.269-1.394 3.782 0 1.514.457 2.752 1.394 3.783.917 1.006 2.092 1.495 3.603 1.495 1.51 0 2.64-.501 3.608-1.532.956-1.018 1.422-2.253 1.422-3.776 0-1.523-.478-2.74-1.422-3.743-.968-1.03-2.15-1.532-3.608-1.532v-.003ZM217.357 32.224a.57.57 0 0 1-.447-.217l-5.871-7.446-.877.807v6.284a.572.572 0 0 1-.57.572h-3.544a.571.571 0 0 1-.569-.572V5.44c0-.315.255-.572.569-.572h3.544c.314 0 .57.257.57.572v14.198l6.976-6.66a.57.57 0 0 1 .387-.15h4.671a.575.575 0 0 1 .384.997l-8.204 7.69 8 9.77c.14.171.17.407.076.608a.574.574 0 0 1-.515.328h-4.577l-.003.003Z"
      />
      <path
        fill="#FF9800"
        d="M232.427 32.224c-6.827 0-9.884-4.887-9.884-9.73 0-2.774.877-5.095 2.61-6.902 1.757-1.835 4.053-2.764 6.824-2.764s4.853.85 6.525 2.529c1.693 1.7 2.552 4.002 2.552 6.843a12.5 12.5 0 0 1-.134 1.801.57.57 0 0 1-.563.49h-12.841c.673 2.21 2.439 3.418 5.042 3.418 1.855 0 4.141-.728 5.56-1.767a.56.56 0 0 1 .454-.098.56.56 0 0 1 .375.278l1.483 2.62a.574.574 0 0 1-.156.744c-1.641 1.229-4.564 2.54-7.85 2.54l.003-.002Zm3.807-11.791c-.472-2.05-2.117-3.386-4.257-3.386-2.141 0-3.816 1.279-4.461 3.386h8.718ZM244.242 32.224a.583.583 0 0 1-.582-.584V13.409c0-.321.259-.584.582-.584h3.447c.32 0 .581.26.581.584v1.174c1.118-1.168 2.604-1.758 4.443-1.758.81 0 1.55.104 2.269.32a.58.58 0 0 1 .411.612l-.329 3.53a.584.584 0 0 1-.268.44.59.59 0 0 1-.512.051 4.967 4.967 0 0 0-1.735-.293c-2.613 0-4.114 1.84-4.114 5.055v9.097a.583.583 0 0 1-.582.584h-3.611v.003ZM263.02 32.224c-2.981 0-5.63-1.456-7.088-3.896a.565.565 0 0 1 .106-.712l2.266-2.052a.56.56 0 0 1 .855.122c.902 1.456 2.406 2.36 3.925 2.36 1.298 0 2.275-.678 2.275-1.58 0-.618-.374-1.101-1.148-1.477a.123.123 0 0 1-.027-.015c-.36-.202-1.596-.676-2.001-.832-1.194-.508-1.86-.804-2.253-1.034-2.001-1.055-3.024-2.657-3.024-4.752 0-1.596.6-2.935 1.787-3.981 1.203-1.03 2.765-1.55 4.647-1.55 2.625 0 4.677.972 6.099 2.886a.566.566 0 0 1-.042.728l-2.043 2.177a.567.567 0 0 1-.412.178h-.018a.562.562 0 0 1-.417-.208c-.926-1.132-2.013-1.682-3.328-1.682-1.054 0-1.733.52-1.733 1.324 0 .382.113.942 1.096 1.37l1.748.734c1.185.489 1.946.831 2.47 1.11 2.147 1.131 3.237 2.767 3.237 4.859 0 1.672-.667 3.1-1.983 4.24-1.279 1.114-2.959 1.677-4.997 1.677l.003.006Z"
      />
      <path
        fill="#1E88E5"
        d="M61.986 32.224a.594.594 0 0 1-.594-.596V17.19h-2.427a.594.594 0 0 1-.594-.596v-3.17c0-.331.265-.597.594-.597h2.427v-.45c0-4.632 2.893-7.51 7.545-7.51 1.614 0 3.186.398 4.675 1.18.268.141.39.465.277.747l-1.176 3.002a.607.607 0 0 1-.322.334.6.6 0 0 1-.463 0c-1.282-.548-2.08-.66-2.522-.66-2.08 0-3.133 1.002-3.133 2.978v.382h5.116c.329 0 .594.266.594.596v3.171c0 .33-.265.596-.594.596h-5.116v14.437c0 .33-.265.596-.594.596h-3.693v-.003ZM82.832 32.224c-5.18 0-8.53-3.336-8.53-8.498V13.424c0-.33.266-.596.594-.596h3.697c.33 0 .594.266.594.596v10.268c0 2.437 1.361 3.893 3.642 3.893 2.28 0 3.642-1.382 3.642-3.86V13.425c0-.36.265-.596.594-.596h3.73c.329 0 .594.266.594.596v10.302c0 5.241-3.28 8.498-8.563 8.498h.006ZM94.312 32.224a.6.6 0 0 1-.597-.6v-2.47c0-.135.045-.266.13-.373l9.169-11.58h-8.702a.6.6 0 0 1-.597-.6v-3.18a.6.6 0 0 1 .597-.6h14.957a.6.6 0 0 1 .597.6v2.333a.607.607 0 0 1-.128.37l-9.147 11.718h8.678a.6.6 0 0 1 .597.6v3.18a.6.6 0 0 1-.597.599H94.312v.003ZM112.808 32.224a.6.6 0 0 1-.597-.6v-2.47a.6.6 0 0 1 .131-.373l9.169-11.58h-8.703a.6.6 0 0 1-.597-.6v-3.18a.6.6 0 0 1 .597-.6h14.957a.6.6 0 0 1 .597.6v2.333a.607.607 0 0 1-.128.37l-9.147 11.718h8.678a.6.6 0 0 1 .597.6v3.18a.6.6 0 0 1-.597.599h-14.957v.003ZM149.047 13.158a.763.763 0 0 0-.63-.33h-3.475a.767.767 0 0 0-.721.51l-4.05 11.354-4.684-11.387a.765.765 0 0 0-.706-.474h-3.639a.77.77 0 0 0-.7 1.076l7.366 16.956-1.818 5.877a.772.772 0 0 0 .085.71c.143.207.378.33.63.33h3.475a.767.767 0 0 0 .721-.51l2.933-9.309 5.298-14.094a.772.772 0 0 0-.085-.71ZM45.031 0H.881C.098 0-.295.951.259 1.508L5.013 6.28l.018-.019v27.329C5.031 36.025 6.995 38 9.422 38h35.606c2.424 0 4.391-1.972 4.391-4.41V4.41c0-2.435-1.964-4.41-4.391-4.41h.003ZM17.108 24.928c-3.261 0-5.905-2.655-5.905-5.93s2.644-5.929 5.905-5.929 5.904 2.654 5.904 5.93c0 3.274-2.643 5.929-5.904 5.929Zm20.24 0c-3.261 0-5.904-2.655-5.904-5.93s2.643-5.929 5.904-5.929c3.262 0 5.905 2.654 5.905 5.93 0 3.274-2.643 5.929-5.905 5.929Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h270v38H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FuzzyLogoNewOrange;
