/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import i18next from 'i18next';
import ReactPlayer from 'react-player';
import { Trans, useTranslation } from 'react-i18next';
import {
  AppBar as NavBar,
  Tabs as TabsContainer,
  Tab,
} from '@material-ui/core';
import {
  ArrowBackIcon,
  ChevronRightIcon,
  QuestionMarkIcon,
} from '../../../assets/svg/SVGComponents';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import IRequestError from '../../../dtos/IRequestError';
import IJourneyProps from '../../../dtos/IJourneyProps';
import IPlaylistProps from '../../../dtos/IPlaylistProps';
import ILessonProps from '../../../dtos/ILessonProps';

import {
  Content,
  MainContent,
  HeaderLinks,
  VideoContainer,
  TextContent,
  TextArea,
  VideoContent,
  QuestionAlert,
  OutlinedIconBackground,
  FilledIconBackground,
  RightSidebar,
  AccordionContainer,
  NavigationMenu,
  LessonsList,
} from './styles';
import ListLessons from '../../../components/ListLessons';
import IGroupProps from '../../../dtos/IGroupProps';

// eslint-disable-next-line no-unused-vars

const Lessons: React.FC = () => {
  const [descriptionText, setDescriptionText] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [journeyData, setJourneyData] = useState<IJourneyProps>(
    {} as IJourneyProps,
  );
  const { playlist_slug, lesson_slug, journey_slug } = useParams();
  const { isSessionExpired } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  // const { url } = useMatch({ end });
  const navigate = useNavigate();
  const loadDescriptionMarkdown = useCallback(
    async (lesson: ILessonProps) => {
      let descriptionURL = '';

      if (i18next.language === 'en') {
        descriptionURL = lesson.description_en;
      }

      if (i18next.language === 'pt-BR') {
        descriptionURL = lesson.description_ptbr;
      }

      if (!descriptionURL) return;

      try {
        const response = await fetch(descriptionURL);

        const text = await response.text();

        setDescriptionText(text);
      } catch (error) {
        addToast({
          type: 'error',
          expression: 'Ops!',
          description: t('toasts.errors.load_description_fail'),
        });
      }
    },
    [addToast, t],
  );

  const loadTeacherProgressData = useCallback(async () => {
    api
      .get<IJourneyProps>(`/journeys/${journey_slug}/user-progress`)
      .then(response => {
        setJourneyData(response.data);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops!',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [journey_slug, addToast, isSessionExpired, t]);

  useEffect(() => {
    loadTeacherProgressData();
  }, [loadTeacherProgressData]);

  const playlistData = useMemo<IPlaylistProps | undefined>(() => {
    if (!journeyData.playlists || !journeyData.playlists.length) {
      return undefined;
    }

    return journeyData.playlists
      .sort((a, b) => a.order - b.order)
      .find(playlist => playlist.slug === playlist_slug);
  }, [journeyData, playlist_slug]);

  const groupData = useMemo<IGroupProps | undefined>(() => {
    if (playlistData && lesson_slug) {
      const groups = playlistData.groups.find(group =>
        group.lessons.some(lesson => lesson.slug === lesson_slug),
      );

      return groups;
    }

    return undefined;
  }, [lesson_slug, playlistData]);

  const currentLesson = useMemo<ILessonProps | undefined>(() => {
    if (!playlistData || !playlistData.groups.length) {
      return undefined;
    }

    const flatLessons = playlistData.groups.flatMap(group => group.lessons);

    if (!lesson_slug) {
      return flatLessons[0];
    }

    return flatLessons.find(lesson => lesson.slug === lesson_slug);
  }, [playlistData, lesson_slug]);

  const nextLesson = useMemo<ILessonProps | undefined>(() => {
    if (!playlistData || !playlistData.groups.length) {
      return undefined;
    }

    const flatLessons = playlistData.groups.flatMap(group => group.lessons);

    if (!currentLesson) {
      return flatLessons[1] || flatLessons[0];
    }

    const currentLessonIndex = flatLessons.findIndex(
      lesson => lesson.slug === currentLesson.slug,
    );

    return flatLessons[currentLessonIndex + 1] || flatLessons[0];
  }, [currentLesson, playlistData]);

  useEffect(() => {
    if (currentLesson) {
      loadDescriptionMarkdown(currentLesson);
    }
  }, [loadDescriptionMarkdown, currentLesson]);

  const handleSelectedTabChange = useCallback((_event, tabIndex) => {
    setSelectedTab(tabIndex);
  }, []);

  const handleVideoEnded = useCallback(
    async (lesson_id: string) => {
      const data = { lesson_id };

      api
        .post(`/journeys/${journey_slug}/user-progress`, data)
        .then(() => {
          if (nextLesson) {
            navigate(
              `/videos/${journey_slug}/playlists/${playlist_slug}/lessons/${nextLesson.slug}`,
            );
          }
        })
        .catch(async (err: IRequestError) => {
          if (!(await isSessionExpired(err))) {
            addToast({
              type: 'error',
              expression: 'Ops!',
              description: t('toasts.errors.update_progress_fail'),
            });
          }
        });
    },
    [
      addToast,
      journey_slug,
      navigate,
      playlist_slug,
      nextLesson,
      isSessionExpired,
      t,
    ],
  );

  return (
    <Content>
      <MainContent>
        {!!playlistData && (
          <HeaderLinks>
            <Link to={-1 as any}>
              <ArrowBackIcon
                width="1.22rem"
                stroke="#1E88E5"
                strokeWidth={3}
                fill="none"
              />
              {t('navlinks.back')}
            </Link>
          </HeaderLinks>
        )}

        <VideoContainer className="">
          {!!currentLesson && (
            <ReactPlayer
              url={currentLesson.resource_url}
              className="react-player"
              height="100%"
              width="100%"
              controls
              onEnded={() => handleVideoEnded(currentLesson.id)}
            />
          )}
        </VideoContainer>

        <NavigationMenu>
          <NavBar position="relative" className="student-class-navBar">
            <TabsContainer
              value={selectedTab}
              onChange={handleSelectedTabChange}
              className="student-classroom-container"
              TabIndicatorProps={{
                style: {
                  background: '#FF9800',
                  height: '5px',
                  borderRadius: '2.5px',
                  marginBottom: '-3px',
                  zIndex: 1101,
                },
              }}
            >
              <Tab
                label={t('tabs.about_this_video')}
                className="student-classroom-tab-item"
              />

              {/* <Tab
                label="Sobre a Trilha"
                className="student-classroom-tab-item"
              /> */}
            </TabsContainer>
          </NavBar>
        </NavigationMenu>

        {selectedTab === 0 && (
          <>
            {!!currentLesson && (
              <VideoContent>
                <TextContent>
                  <TextArea className="">
                    <ReactMarkdown
                      plugins={[gfm]}
                      className="markdown-container"
                    >
                      {descriptionText}
                    </ReactMarkdown>
                  </TextArea>
                </TextContent>
              </VideoContent>
            )}

            <QuestionAlert
              href="https://wa.me/message/ICA65FNRIPESD1"
              rel="noreferrer"
              target="_blank"
            >
              <FilledIconBackground>
                <QuestionMarkIcon
                  width="2.89rem"
                  stroke="#FFBA08"
                  strokeWidth={1}
                  fill="none"
                />
              </FilledIconBackground>
              <div>
                <h4>
                  <Trans i18nKey="h4.any_questions" />
                </h4>
                <p>{t('p.get_in_touch')}</p>
              </div>

              <OutlinedIconBackground>
                <ChevronRightIcon
                  width="0.67rem"
                  stroke="#323650"
                  strokeWidth={2}
                />
              </OutlinedIconBackground>
            </QuestionAlert>
          </>
        )}

        {selectedTab === 1 && (
          <>
            <VideoContent>
              <TextContent>
                {currentLesson && (
                  <div>
                    <ReactMarkdown
                      plugins={[gfm]}
                      className="markdown-container"
                    >
                      {currentLesson.description}
                    </ReactMarkdown>
                  </div>
                )}
              </TextContent>
            </VideoContent>

            <QuestionAlert>
              <FilledIconBackground>
                <QuestionMarkIcon
                  width="2.89rem"
                  stroke="#FFBA08"
                  strokeWidth={1}
                  fill="none"
                />
              </FilledIconBackground>

              <div>
                <h4>
                  <Trans i18nKey="h4.any_questions" />
                </h4>
                <p>{t('p.get_in_touch')}</p>
              </div>

              <OutlinedIconBackground>
                <ChevronRightIcon
                  width="0.67rem"
                  stroke="#323650"
                  strokeWidth={2}
                />
              </OutlinedIconBackground>
            </QuestionAlert>
          </>
        )}
      </MainContent>
      <RightSidebar>
        {journey_slug === `training-videos` &&
          playlistData &&
          playlistData.groups &&
          playlistData.groups.length > 0 &&
          playlistData.groups.map((group, idx) => (
            <AccordionContainer
              open={
                currentLesson
                  ? group.lessons.includes(currentLesson)
                  : idx === 0
              }
              key={group.id}
              title={t(`groups.${group.slug}.title`)}
              videoAmount={group.lessons.length}
            >
              <LessonsList>
                <ListLessons
                  playlist_slug={playlistData.slug}
                  lessons={group.lessons}
                />
              </LessonsList>
            </AccordionContainer>
          ))}
        {journey_slug === `projects-videos` && playlistData && groupData && (
          <AccordionContainer
            open={
              currentLesson ? groupData.lessons.includes(currentLesson) : true
            }
            key={groupData.id}
            title={t(`groups.${groupData.slug}.title`)}
            videoAmount={groupData.lessons.length}
          >
            <LessonsList>
              <ListLessons
                playlist_slug={playlistData.slug}
                lessons={groupData.lessons}
              />
            </LessonsList>
          </AccordionContainer>
        )}
      </RightSidebar>
    </Content>
  );
};
export default Lessons;
