const avatar_api_letters = (name: string): string => {
  if (!name) name = 'No Name';
  const splitName = name.split(' ');
  const firstName = name.split(' ')[0];
  const lastName = splitName[splitName.length - 1];
  let encodedName: string;
  let url: string;

  if (splitName.length <= 1) {
    encodedName = `${firstName}`;
    url = `https://ui-avatars.com/api/?background=e8e8e8&color=323650&name=${encodedName}&rounded=true&size=128&length=1`;
  } else {
    encodedName = `${firstName}+${'+'}+${lastName}`;
    url = `https://ui-avatars.com/api/?background=e8e8e8&color=323650&name=${encodedName}&rounded=true&size=128`;
  }
  return url;
};

export default avatar_api_letters;
