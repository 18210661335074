import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

export interface DropdownHandles {
  toggleDropdown: () => void;
  closeDropdown: () => void;
}

const Dropdown: React.ForwardRefRenderFunction<DropdownHandles, ReactNode> = (
  { children },
  ref,
) => {
  const refDropdown = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const openDropdown = useCallback(() => {
    setIsVisible(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsVisible(false);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      toggleDropdown,
      openDropdown,
      closeDropdown,
    };
  });

  return (
    <nav ref={refDropdown} className={isVisible ? 'visible' : 'hidden'}>
      {children}
    </nav>
  );
};

export default forwardRef(Dropdown);
