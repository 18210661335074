import React from 'react';

const SvgInfo: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M0 0h24v24H0z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.999 8a.25.25 0 10.002.5A.25.25 0 0012 8"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 21v0a9 9 0 01-9-9v0a9 9 0 019-9v0a9 9 0 019 9v0a9 9 0 01-9 9zM12 12v5"
        />
      </g>
    </svg>
  );
};

export default SvgInfo;
