import React from 'react';

const SvgEyeVisible: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.118 12.467a.987.987 0 010-.935C5.01 8.033 8.505 5 12 5s6.99 3.033 8.882 6.533a.987.987 0 010 .935C18.99 15.967 15.495 19 12 19s-6.99-3.033-8.882-6.533z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.121 9.879A3 3 0 119.88 14.12 3 3 0 0114.12 9.88"
        />
      </g>
    </svg>
  );
};

export default SvgEyeVisible;
