/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LogoRobotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 40"
    fill="none"
    {...props}
  >
    <path d="M46.65 0H.914C.103 0-.307.988.269 1.568l4.924 4.963.022-.02v28.414c0 2.531 2.035 4.583 4.546 4.583h36.886c2.512 0 4.547-2.052 4.547-4.583V4.582C51.194 2.052 49.16 0 46.647 0h.004ZM17.724 25.92c-3.378 0-6.115-2.76-6.115-6.163 0-3.404 2.737-6.163 6.114-6.163 3.378 0 6.115 2.759 6.115 6.163 0 3.404-2.737 6.163-6.114 6.163Zm20.97 0c-3.378 0-6.115-2.76-6.115-6.163 0-3.404 2.737-6.163 6.114-6.163 3.378 0 6.115 2.759 6.115 6.163 0 3.404-2.737 6.163-6.114 6.163Z" />
  </svg>
);
export default LogoRobotIcon;
