import React from 'react';

const SvgPencil: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="pencil_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#pencil_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.17 13.547L10 14l.453-3.17a1 1 0 01.282-.566l6.644-6.643a2.121 2.121 0 013 3l-6.644 6.643a1 1 0 01-.565.283z" />
        <path d="M21 11v8a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h5" />
      </g>
    </svg>
  );
};

export default SvgPencil;
