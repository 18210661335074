import styled, { css } from 'styled-components';

interface StatusProps {
  watched: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ListItems = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  li {
    &:first-child {
      margin-top: 0.56rem;
    }
    &:last-child {
      margin-bottom: 3rem;
    }
  }
`;

export const Line = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Activity = styled.li`
  display: flex;
  align-items: start;
  margin-left: 0.56rem;
  margin-bottom: 5.99rem;
  position: relative;
  > button {
    display: flex;
    align-items: start;
  }
`;

export const IconArea = styled.div`
  width: 6.12rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const IconActivity = styled.div`
  display: flex;
  position: relative;

  > svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Circle = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 50%;

  ${props => colorsStatusCircle[props.watched ? 'done' : 'todo']}
`;

export const TextArea = styled.div<StatusProps>`
  margin-left: 7rem;
  position: absolute;
  > h1 {
    font-family: 'RedHatDisplay-Bold';
    line-height: 1.33rem;
    font-size: 1rem;
    color: #fff;
  }

  > p {
    margin-top: 0.27rem;
    width: fit-content;
    padding: 0.2rem 0.3rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    border-radius: 1.44rem;

    font-family: 'RedHatDisplay-Regular';
    font-size: 0.64rem;
    line-height: 0.8rem;
    ${props => colorsStaus[props.watched ? 'done' : 'todo']}
  }
`;

export const DurationWrapper = styled.div`
  display: flex;
  align-itens: center;

  > p {
    margin-left: 0.5rem;
    font-family: 'RedHatDisplay-Medium';
    font-size: 0.67.rem;
    color: #fff;
  }
`;

const colorsStaus = {
  done: css`
    background-color: #4caf50;
    color: #fff;
  `,

  todo: css`
    outline: 1px solid #fff;
    color: #fff;
  `,

  working: css`
    outline: 1px sold #4caf50;
    background-color: #e2e2e2;
    color: #4caf50;
  `,
};

const colorsStatusCircle = {
  done: css`
    background-color: #4caf50;
    border: 2px solid #ededed;
    > svg {
      fill: #e2e2e2;
    }
  `,

  todo: css`
    background-color: none;
    border: 2px solid #ededed;
    > svg {
      fill: #e2e2e2;
    }
  `,

  working: css`
    outline: 1px sold #4caf50;
    background-color: #e2e2e2;
    color: #4caf50;
  `,
};
