import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const FuzzyMakers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 225 35"
    fill="none"
    {...props}
  >
    <path
      fill="#FF9800"
      d="M124.474 29.149a.623.623 0 0 1-.619-.624V17.818c0-2.896-1.342-4.555-3.68-4.555-2.49 0-4.101 1.83-4.101 4.66V28.53a.623.623 0 0 1-.619.623h-3.844a.623.623 0 0 1-.619-.623v-10.64c0-2.896-1.389-4.625-3.715-4.625-2.474 0-4.135 1.901-4.135 4.731V28.53a.622.622 0 0 1-.618.623h-3.845a.623.623 0 0 1-.618-.623V9.539c0-.343.278-.624.618-.624h3.777c.34 0 .618.28.618.623v.904c1.305-1.309 3.105-2.054 5.079-2.054 2.48.034 4.639 1.147 5.864 2.962 1.537-1.849 4.033-2.962 6.752-2.962 2.44 0 4.426.814 5.904 2.42 1.497 1.545 2.264 3.696 2.264 6.377v11.344a.622.622 0 0 1-.618.623h-3.845v-.003ZM141.485 29.149c-2.892 0-5.314-1.038-7.2-3.09-1.872-2.035-2.821-4.594-2.821-7.608 0-3.015.925-5.484 2.83-7.479C136.177 9 138.596 8 141.485 8c2.239 0 4.188.732 5.812 2.182v-1.1c0-.344.278-.624.618-.624h3.705c.341 0 .619.28.619.623v18.987a.623.623 0 0 1-.619.624h-3.705a.623.623 0 0 1-.618-.624v-1.082c-1.634 1.438-3.582 2.164-5.812 2.164Zm.526-16.347c-1.596 0-2.911.561-3.913 1.665-1.02 1.125-1.515 2.472-1.515 4.124 0 1.652.495 2.999 1.515 4.124.993 1.097 2.273 1.63 3.913 1.63 1.639 0 2.867-.545 3.918-1.67 1.04-1.11 1.544-2.457 1.544-4.119 0-1.661-.52-2.986-1.544-4.08-1.051-1.125-2.335-1.67-3.918-1.67v-.004ZM168.582 29.15a.62.62 0 0 1-.486-.238l-6.368-8.105-.952.876v6.843a.623.623 0 0 1-.619.623h-3.845a.623.623 0 0 1-.618-.623V1.824c0-.343.278-.624.618-.624h3.845c.34 0 .619.28.619.624v13.625l6.897-6.369a.613.613 0 0 1 .417-.165h5.07a.621.621 0 0 1 .414 1.085l-8.23 7.49 8.678 10.637a.625.625 0 0 1-.479 1.019h-4.964l.003.003Z"
    />
    <path
      fill="#FF9800"
      d="M184.579 29.149c-7.405 0-10.72-5.321-10.72-10.592 0-3.018.952-5.546 2.83-7.513 1.908-1.998 4.398-3.008 7.401-3.008s5.264.926 7.08 2.756c1.837 1.851 2.768 4.358 2.768 7.45 0 .645-.05 1.306-.145 1.96a.622.622 0 0 1-.613.53h-13.927c.73 2.407 2.644 3.72 5.468 3.72 2.01 0 4.491-.792 6.031-1.924a.622.622 0 0 1 .903.196l1.609 2.853c.154.274.08.62-.167.807-1.779 1.337-4.952 2.765-8.515 2.765h-.003Zm4.129-12.837c-.511-2.232-2.295-3.684-4.618-3.684-2.323 0-4.138 1.393-4.837 3.684h9.455ZM197.439 29.15a.623.623 0 0 1-.619-.624V9.539c0-.343.279-.624.619-.624h3.671c.34 0 .619.28.619.624v.764c1.187-1.25 2.771-1.88 4.729-1.88.86 0 1.651.112 2.415.343a.627.627 0 0 1 .44.654l-.35 3.77a.636.636 0 0 1-.284.47.623.623 0 0 1-.545.059 5.271 5.271 0 0 0-1.849-.315c-2.784 0-4.38 1.967-4.38 5.4v9.722a.623.623 0 0 1-.619.623h-3.847ZM217.357 29.149c-3.266 0-6.167-1.602-7.766-4.283a.627.627 0 0 1 .118-.782l2.48-2.254a.623.623 0 0 1 .501-.153c.183.025.343.131.439.287.99 1.599 2.636 2.593 4.3 2.593 1.419 0 2.492-.748 2.492-1.736 0-.68-.411-1.21-1.258-1.624-.01-.006-.022-.01-.031-.016-.393-.22-1.748-.741-2.193-.913-1.309-.558-2.038-.885-2.468-1.135-2.19-1.162-3.313-2.924-3.313-5.224 0-1.755.659-3.226 1.958-4.38 1.318-1.134 3.028-1.705 5.091-1.705 2.876 0 5.125 1.07 6.684 3.174a.627.627 0 0 1-.047.8l-2.236 2.395a.62.62 0 0 1-.451.196h-.022a.631.631 0 0 1-.458-.227c-1.014-1.244-2.205-1.852-3.646-1.852-1.154 0-1.899.57-1.899 1.456 0 .42.123 1.034 1.2 1.505l1.914.808c1.296.539 2.134.916 2.706 1.221 2.351 1.244 3.545 3.043 3.545 5.343 0 1.84-.73 3.407-2.171 4.664-1.405 1.222-3.245 1.842-5.472 1.842h.003Z"
    />
    <path
      fill="#fff"
      d="M4.561 29.149a.623.623 0 0 1-.618-.623V13.473H1.416a.623.623 0 0 1-.619-.624V9.54c0-.343.279-.624.619-.624h2.527v-.467c0-4.838 3.01-7.846 7.853-7.846 1.68 0 3.315.414 4.865 1.234a.62.62 0 0 1 .288.78L15.724 5.75a.625.625 0 0 1-.575.396.597.597 0 0 1-.242-.05c-1.333-.57-2.165-.689-2.626-.689-2.165 0-3.26 1.048-3.26 3.111v.4h5.323c.34 0 .619.28.619.623v3.31a.623.623 0 0 1-.619.624H9.021v15.053a.623.623 0 0 1-.618.623H4.558l.003-.003ZM26.15 29.15c-5.388 0-8.867-3.483-8.867-8.866V9.533c0-.343.278-.624.618-.624h3.845c.34 0 .618.28.618.624V20.25c0 2.543 1.417 4.061 3.786 4.061 2.37 0 3.786-1.443 3.786-4.027V9.533c0-.378.278-.624.618-.624h3.879c.34 0 .618.28.618.624v10.75c0 5.468-3.411 8.866-8.9 8.866ZM38.086 29.149a.62.62 0 0 1-.619-.623v-2.578c0-.14.047-.278.136-.39l9.533-12.082h-9.047a.62.62 0 0 1-.619-.624V9.536a.62.62 0 0 1 .619-.624H53.64a.62.62 0 0 1 .619.624v2.434c0 .14-.047.274-.133.387l-9.511 12.225h9.022a.62.62 0 0 1 .619.624v3.317a.62.62 0 0 1-.619.623H38.086v.003ZM57.314 29.149a.62.62 0 0 1-.618-.623v-2.578c0-.14.046-.278.136-.39l9.532-12.082h-9.047a.62.62 0 0 1-.618-.624V9.536a.62.62 0 0 1 .619-.624h15.55a.62.62 0 0 1 .62.624v2.434c0 .14-.047.274-.134.387l-9.51 12.225h9.022a.62.62 0 0 1 .618.624v3.317a.62.62 0 0 1-.618.623H57.314v.003ZM94.99 9.078a.804.804 0 0 0-.666-.353h-3.671a.808.808 0 0 0-.761.54l-4.28 12.041-4.95-12.079a.808.808 0 0 0-.748-.505H76.07a.806.806 0 0 0-.678.368.827.827 0 0 0-.064.773l7.787 17.986-2.264 6.045a.822.822 0 0 0 .093.75.804.804 0 0 0 .665.353h3.672c.34 0 .646-.215.76-.54l3.44-9.678 5.6-14.95a.821.821 0 0 0-.092-.751Z"
    />
  </svg>
);
export default FuzzyMakers;
