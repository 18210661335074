import React, { useLayoutEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Loading from '../layouts/loading';
import MainLayout from '../layouts/main';

interface RouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  customLayout?: boolean;
}

const PrivateRoute: React.FC<RouteProps> = ({
  isPrivate = false,
  customLayout = false,
  component: Component,
}) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  if (loading) {
    return <Loading />;
  }

  const Layout: React.FC =
    user && user.email && !customLayout
      ? MainLayout
      : ({ children }) => <>{children}</>;

  return (
    <>
      {isPrivate === !!user ? (
        <Layout>
          <Component />
        </Layout>
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};

export default PrivateRoute;
