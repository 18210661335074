import React from 'react';
import {
  HeadWrapper,
  LoadContent,
  LoadingWrapper,
  ShimmerEffect,
} from './styles';

// import { Container } from './styles';

const LoadingState: React.FC = () => {
  return (
    <LoadingWrapper>
      <HeadWrapper>
        <ShimmerEffect className="loading-title" />

        <ShimmerEffect className="loading-step" />
      </HeadWrapper>

      <LoadContent>
        <div>
          <svg
            width="250"
            height="250"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="logo-fuzzy">
              <g id="faces">
                <path
                  id="face-grey"
                  d="M87.3192 12.5H1.70835C0.190143 12.5 -0.570986 14.3465 0.501882 15.4244L9.72046 24.6855C9.72046 24.6855 9.74475 24.6611 9.75689 24.6489V77.6615C9.75689 82.3836 13.5666 86.2149 18.271 86.2149H87.3192C92.0196 86.2149 95.8333 82.3877 95.8333 77.6615V21.0534C95.8333 16.3314 92.0236 12.5 87.3192 12.5ZM33.1697 60.8556C26.8459 60.8556 21.7244 55.7065 21.7244 49.3575C21.7244 43.0085 26.8499 37.8593 33.1697 37.8593C39.4895 37.8593 44.615 43.0085 44.615 49.3575C44.615 55.7065 39.4895 60.8556 33.1697 60.8556ZM72.4205 60.8556C66.0967 60.8556 60.9752 55.7065 60.9752 49.3575C60.9752 43.0085 66.1007 37.8593 72.4205 37.8593C78.7403 37.8593 83.8658 43.0085 83.8658 49.3575C83.8658 55.7065 78.7403 60.8556 72.4205 60.8556Z"
                />
              </g>
            </g>
          </svg>
        </div>
      </LoadContent>
    </LoadingWrapper>
  );
};

export default LoadingState;
