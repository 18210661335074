import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const PadlockOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 23 30">
    <path d="M.001 14.204c0-1.759 1.21-2.952 2.986-2.955h.785v-.459c.018-1.376-.045-2.758.068-4.127.265-3.237 2.939-6.03 6.164-6.547.221-.035.441-.077.663-.116h1.296c.59.134 1.196.222 1.77.409 2.994.97 5.084 3.819 5.12 6.953.005.383.007.766.007 1.149h-2.521c0-.317 0-.632-.002-.948-.014-2.575-1.744-4.588-4.302-5.01C9.422 2.12 6.768 4.02 6.373 6.629c-.084.556-.072 1.128-.079 1.692-.011.965-.003 1.929-.003 2.92h13.094c.307.007.607.017.901.063 1.33.208 2.333 1.353 2.336 2.693.012 4.414.013 8.828 0 13.24-.005 1.553-1.28 2.756-2.881 2.758-3.82.006-7.64.002-11.461.002H2.976c-1.769 0-2.975-1.2-2.975-2.963-.002-4.277-.002-8.554 0-12.83Zm9.808 6.522c.2.146.263.294.26.53-.013.849-.016 1.698 0 2.547.013.635.479 1.11 1.123 1.189.577.072 1.159-.314 1.322-.892.042-.147.05-.308.05-.463.005-.8.01-1.6-.002-2.4-.003-.225.057-.366.25-.506a2.436 2.436 0 0 0 .892-2.759 2.532 2.532 0 0 0-2.384-1.726 2.522 2.522 0 0 0-2.39 1.718 2.43 2.43 0 0 0 .88 2.762Z" />
  </svg>
);
export default PadlockOpen;
