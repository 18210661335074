import React from 'react';

import { Container, StyledCheckbox, Icon } from './styles';

interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange(value: boolean): void;
  checked: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  checked,
  ...shared
}) => {
  return (
    <Container>
      <input
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        {...shared}
      />
      <StyledCheckbox check={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </Container>
  );
};

export default Checkbox;
