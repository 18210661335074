import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const IntroductionBook = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M18 21H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2ZM3 8h2.33M3 12h2.33m-2.45 4h2.45M8 3v18m4-13h4m-4 4h4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IntroductionBook;
