import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { Container, SimulatorViewer } from './styles';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import { ResourceType, StepType } from '../../../../utils/enums';
import { IContextChaptersProps } from '../../../../dtos/IChapterProps';
import IStepProps from '../../../../dtos/IStepsProps';

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

const EmbeddingComponent: React.FC<IComponentProps> = ({
  currentStep: step,
  currentActivity,
}) => {
  const [EmbeddingURL, setEmbeddingURL] = useState(' ');

  const location = useLocation();
  const slice = location.pathname.split('/');
  const active_tab = slice[slice.length - 1] as StepType;

  const loadTutorialLink = useCallback(async (currentStep: IStepProps) => {
    if (currentStep && currentStep.resourse_type === ResourceType.EMBEDING) {
      if (currentStep) setEmbeddingURL(currentStep.resourse_url);
    }
  }, []);

  useEffect(() => {
    if (step) loadTutorialLink(step);
  }, [loadTutorialLink, step]);

  return (
    <Container>
      {step && <SimulatorViewer title="tedt" src={`${EmbeddingURL}`} />}
    </Container>
  );
};

export default EmbeddingComponent;
