import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IconContent,
  PlaylistCard,
  TimelineItem,
  VideoInfos,
  Wrapper,
} from './styles';
import { ActivityIcon, ClockIcon } from '../../assets/svg/SVGComponents';
import IActivityProps from '../../dtos/IActivitiesProps';
import IChapterProps from '../../dtos/IChapterProps';

interface IListActivitiesProps {
  trail_slug: string;
  chapters: IChapterProps;
}

// import { Container } from './styles';

const ListContentActivity: React.FC<IListActivitiesProps> = ({
  trail_slug,
  chapters: chaptersData,
}) => {
  const { t } = useTranslation();
  const handleActiveLesson = useCallback((activityItem: IActivityProps) => {
    if (activityItem.watched) {
      return true;
    }
    return false;
  }, []);
  return (
    <Wrapper>
      {chaptersData.type === 'activity' &&
        chaptersData.activities.map(activity => (
          <TimelineItem
            key={activity.id}
            watching={handleActiveLesson(activity)}
          >
            <Link
              to={`/projects/${trail_slug}/${chaptersData.id}/activity/${activity.slug}`}
            >
              <PlaylistCard disabled={false}>
                <IconContent>
                  <ActivityIcon width="1.22rem" fill="#fff" />
                </IconContent>
                <VideoInfos>
                  <h3>{t(`activities.${activity.slug}.title`)}</h3>

                  <span>
                    {t(`activities.${activity.slug}.short_description`)}
                  </span>
                  <div>
                    <ClockIcon width="1.2rem" fill="none" stroke="#1E88E5" />
                    <span>{`${activity.duration} min`}</span>
                  </div>
                </VideoInfos>
              </PlaylistCard>
            </Link>
          </TimelineItem>
        ))}
      {chaptersData.type === 'lesson' &&
        chaptersData.lessons.map(activity => (
          <TimelineItem key={activity.id} watching={false}>
            <Link
              to={`/videos/${activity.journey_slug}/playlists/${activity.playlist_slug}/lessons/${activity.slug}`}
            >
              <PlaylistCard disabled={false}>
                <IconContent>
                  <ActivityIcon width="1.22rem" fill="#fff" />
                </IconContent>
                <VideoInfos>
                  <h3>{t(`lessons.${activity.slug}.title`)}</h3>

                  <span>{t(`lessons.${activity.slug}.short_description`)}</span>
                  <div>
                    <ClockIcon width="1.2rem" fill="none" stroke="#1E88E5" />
                    <span>{`${activity.duration} min`}</span>
                  </div>
                </VideoInfos>
              </PlaylistCard>
            </Link>
          </TimelineItem>
        ))}
    </Wrapper>
  );
};

export default ListContentActivity;
