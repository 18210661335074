import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface TagColorProps {
  color: string;
}

interface InputProps {
  isFocused: boolean;
}

interface GridProps {
  numberRows: number;
}

export const MainContent = styled.div`
  padding: 2.67rem 4.4rem;
  display: flex;
  flex-direction: column;
`;

export const MainContentHeader = styled.header`
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  margin-bottom: 3.5rem;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 2rem;
  > a {
    display: flex;
    align-items: center;
    margin-bottom: 0.89rem;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    line-height: 1.61rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'RedHataDisplay-Regular';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;

export const SearchInput = styled.div<InputProps>`
  margin-left: 1.2rem;
  display: flex;
  align-items: center;
  border: 1px solid #e2e2e2;
  background: #fff;
  border-radius: 0.8rem;
  padding: 0.53rem 0.58rem;
  width: 23.22rem;
  height: 2.72rem;
  border: 1px solid ${props => (props.isFocused ? '#EC775D' : '#e2e2e2')};

  input {
    font-size: 1rem;
    background: transparent;
    flex: 1;
    border: 0;
    margin-left: 0.67rem;

    &::placeholder {
      margin: 0;
      font-size: 1rem;
    }
  }

  button {
    margin-right: 0.3rem;

    > svg {
      stroke: ${props => (props.isFocused ? '#EC775D' : '#e2e2e2')};
    }
  }
`;

export const JourneyRow = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const JourneyItem = styled.li`
  display: flex;

  margin-bottom: 2.38rem;
`;

export const JourneyCard = styled(Link)`
  width: 54.2rem;
  height: 22rem;
  display: flex;
  border: 2px solid #4caf50;
  border-radius: 1.8rem;
  padding-left: 2rem;
  display: flex;
  align-items: flex-start;

  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.1);

  position: relative;
  transition: all 0.3s;

  &:hover {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
    top: -1px;
    transform: translateY(-5px);
  }
`;

export const JourneyContent = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  header {
    display: flex;
    margin-top: 1.83rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    color: #1e88e5;
  }

  p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
    line-height: 1.22rem;
    min-height: 7rem;
  }
`;

export const Tag = styled.span<TagColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.7rem;
  text-align: center;
  margin-right: 0.67rem;
  border-radius: 2.78rem;
  background-color: ${props => props.color};
  color: #fff;
`;

export const JourneyDetails = styled.div`
  display: flex;
  margin-top: 1rem;
  div {
    display: flex;

    > span {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;

      h2 {
        font-family: 'RedHatDisplay-ExtraBold';
        font-size: 1.56rem;
      }
      p {
        font-size: 0.67rem;
      }
    }

    &:last-child {
      margin-left: 5rem;
    }
  }
`;

export const IllustrationWrapper = styled.div`
  width: 38%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > img {
    max-width: 90%;
    max-height: 100%;
  }
`;

export const BnccSection = styled.div`
  margin-left: 3.89rem;

  > div {
    h3 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.22rem;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 21rem;
      max-height: 10rem;

      span {
        margin-top: 0.83rem;
        padding: 0.3rem;
        margin-right: 0.67rem;
        border-radius: 2.78rem;
        font-size: 0.78rem;
        color: #1e88e5;
        text-align: center;
        border: 1px solid #1e88e5;
      }
    }
  }
  > div:nth-child(2) {
    margin-top: 1.8rem;

    span {
      color: #f39519;
      border: 1px solid #f39519;
    }
  }
`;

export const ChapterGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: ${props => `repeat(${props.numberRows}, 10rem)`};
  column-gap: 2.83rem;
  row-gap: 2.83rem;
`;

export const ChapterCard = styled(Link)`
  height: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  border: 1px solid #4caf50;
  border-radius: 20px;
  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s;

  &:hover {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
    top: -1px;
    transform: translateY(-5px);
  }
`;

export const IconSection = styled.div`
  margin-left: 1.4rem;
  width: 5.56rem;
  height: 5.56rem;
  border: 2px solid #1e88e5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 70%;
    height: 70%;
  }
`;

export const ContentSection = styled.div`
  margin-left: 1.28rem;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > header {
    display: flex;
    margin-bottom: 0.3rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #1e88e5;
  }

  p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 0.9rem;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  margin-left: auto;

  > button {
    color: #1e88e5;
    opacity: 0.8;
    text-decoration: underline;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const TagChapter = styled.span<TagColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.5rem;
  text-align: center;
  margin-right: 0.47rem;
  border-radius: 2.78rem;
  background-color: ${props => props.color};
  font-size: 0.7rem;
  color: #fff;
`;
