import React from 'react';

const SvgAttention: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="attention_svg__a">
          <path transform="translate(-.169)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g strokeLinecap="round">
        <path d="M12.588 13.125v-3.75" />
        <g
          clipPath="url(#attention_svg__a)"
          strokeLinejoin="round"
          transform="translate(.169)"
        >
          <path d="M12.419 16.125a.25.25 0 10.251.25.249.249 0 00-.251-.25" />
          <path d="M14.448 4.139l7.659 13.4a2.336 2.336 0 01-2.029 3.5H4.76a2.337 2.337 0 01-2.03-3.5l7.659-13.4a2.338 2.338 0 014.059 0z" />
        </g>
      </g>
    </svg>
  );
};

export default SvgAttention;
