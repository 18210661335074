import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  ContentWrapper,
  Fill,
  IconContent,
  InfoBar,
  InfoPlaylist,
  ListContent,
  ListWrapper,
  MainContent,
  MainHeadContent,
  PlaylistCard,
  PlaylistsContent,
  Progress,
  TimelineItem,
  Title,
  VideoInfos,
  VideoLists,
} from './styles';
import IPlaylistProps from '../../../dtos/IPlaylistProps';
import IJourneyProps from '../../../dtos/IJourneyProps';
import IRequestError from '../../../dtos/IRequestError';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { ClockIcon, DotContent } from '../../../assets/svg/SVGComponents';
import VideoContent from '../../../assets/svg/SVGComponents/VideoContent';
import ILessonProps from '../../../dtos/ILessonProps';

interface ILessonItemProps {
  lesson: ILessonProps;
  journey_slug: string;
  playlist_slug: string;
  group_slug: string;
}
const PlaylistsAndLessons: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isSessionExpired } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { journey_slug } = useParams();
  const [journeyData, setJourneyData] = useState<IJourneyProps | null>(null);
  const [lessonsData, setlessonsData] = useState<ILessonItemProps[] | null>(
    null,
  );
  const [duration, setDuration] = useState(0);

  const handleTimeLeftPlaylist = useCallback((lessons: ILessonItemProps[]) => {
    let timeLeft = 0;

    for (let x = 0; x < lessons.length; x += 1) {
      if (!lessons[x].lesson.watched) {
        timeLeft += lessons[x].lesson.duration;
      }
    }

    return timeLeft;
  }, []);

  useEffect(() => {
    if (journeyData && journeyData.playlists && journeyData.playlists.length) {
      const groups = journeyData.playlists.flatMap(playlist => {
        return playlist.groups.map(group => {
          return {
            groups: group,
            playlist_slug: playlist.slug,
            journey_slug: journeyData.slug,
            group_slug: group.slug,
          };
        });
      });

      const lessons = groups
        .flatMap(group => {
          return group.groups.lessons.map(lesson => {
            return {
              lesson,
              journey_slug: group.journey_slug,
              playlist_slug: group.playlist_slug,
              group_slug: group.group_slug,
            };
          });
        })
        .slice(0, 9);

      const time = handleTimeLeftPlaylist(lessons);
      setDuration(time);

      setlessonsData(lessons);
    }
  }, [handleTimeLeftPlaylist, journeyData]);

  const handleActiveLesson = useCallback((lesson: ILessonItemProps) => {
    if (lesson.lesson.watched) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/journeys/${journey_slug}/user-progress`)
      .then(response => {
        setJourneyData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        setIsLoading(false);

        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, isSessionExpired, journey_slug, t]);

  const handleProgressPlaylist = useCallback((playlist: IPlaylistProps) => {
    if (!playlist.watched_lessons) {
      return 0;
    }

    if (!playlist.lessons_amount) {
      return 0;
    }

    const percentageCompleted =
      (playlist.watched_lessons / playlist.lessons_amount) * 100;
    return Math.round(percentageCompleted);
  }, []);
  return (
    <ContentWrapper>
      <MainContent>
        <MainHeadContent>
          <Title>
            <DotContent width="2.22rem" />
            {journeyData && journeyData.playlists && (
              <h1>{t(`playlists.${journeyData.playlists}.title`)}</h1>
            )}
          </Title>

          {lessonsData && (
            <InfoPlaylist>
              <div />
              <span>
                <Trans i18nKey="spans.time_left" values={{ time: duration }} />
              </span>
            </InfoPlaylist>
          )}
        </MainHeadContent>
        {!isLoading !== false && lessonsData && (
          <ListWrapper>
            <ListContent>
              {lessonsData.map(lesson => (
                <TimelineItem
                  key={lesson.lesson.id}
                  watching={handleActiveLesson(lesson)}
                >
                  <Link
                    to={`/videos/${lesson.journey_slug}/playlists/${lesson.playlist_slug}/lessons/${lesson.lesson.slug}`}
                  >
                    <PlaylistCard disabled={false}>
                      <IconContent>
                        <VideoContent width="1.22rem" fill="#fff" />
                      </IconContent>
                      <VideoInfos>
                        <h3>
                          {`${t(`groups.${lesson.group_slug}.title`)}: ${t(
                            `lessons.${lesson.lesson.slug}.title`,
                          )}`}
                        </h3>

                        <span>
                          {t(`lessons.${lesson.lesson.slug}.short_description`)}
                        </span>
                        <div>
                          <ClockIcon
                            width="1.2rem"
                            fill="none"
                            stroke="#1E88E5"
                          />
                          <span>{`${lesson.lesson.duration} min`}</span>
                        </div>
                      </VideoInfos>
                    </PlaylistCard>
                  </Link>
                </TimelineItem>
              ))}
            </ListContent>
          </ListWrapper>
        )}
      </MainContent>

      <PlaylistsContent>
        {!isLoading !== false &&
          !!journeyData?.playlists &&
          !!journeyData.playlists.length && (
            <VideoLists>
              <h2>{t('h2.playlists_progress')}</h2>
              <ul>
                {journeyData.playlists.map(playlist => (
                  <li key={playlist.id}>
                    <Link
                      to={`/videos/${journeyData.slug}/playlists/${playlist.slug}`}
                    >
                      <InfoBar progress={handleProgressPlaylist(playlist)}>
                        <h3>{t(`playlists.${playlist.slug}.title`)}</h3>
                        <span>{handleProgressPlaylist(playlist)}%</span>
                      </InfoBar>

                      <Progress>
                        <Fill progress={handleProgressPlaylist(playlist)} />
                      </Progress>
                    </Link>
                  </li>
                ))}
              </ul>
            </VideoLists>
          )}
      </PlaylistsContent>
    </ContentWrapper>
  );
};

export default PlaylistsAndLessons;
