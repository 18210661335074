import React, { useCallback, useEffect, useRef } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  components,
} from 'react-select';
import { useField } from '@unform/core';

import { ChevronDownIcon } from '../../../assets/svg/SVGComponents';

interface Props extends SelectProps<OptionTypeBase, boolean> {
  name: string;
  dropdownIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconLeft?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Select: React.FC<Props> = ({
  name,
  iconLeft: IconLeft,
  dropdownIcon: Icon,
  isMulti = false,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, isMulti]);

  const DropdownIndicator: typeof components.DropdownIndicator = useCallback(
    props => {
      return (
        <components.DropdownIndicator {...props}>
          {Icon ? (
            <Icon width="1.22rem" fill="none" strokeWidth={2} />
          ) : (
            <ChevronDownIcon width="1.22rem" strokeWidth={2} />
          )}
        </components.DropdownIndicator>
      );
    },
    [Icon],
  );

  const Control: typeof components.Control = useCallback(
    props => {
      return (
        <components.Control {...props}>
          {IconLeft && (
            <IconLeft
              width="1.33rem"
              stroke="#323650"
              fill="none"
              strokeWidth={2}
            />
          )}
          {props.children}
        </components.Control>
      );
    },
    [IconLeft],
  );

  return (
    <ReactSelect
      defaultValue={defaultValue}
      ref={selectRef}
      classNamePrefix="react-select"
      components={{ DropdownIndicator, Control }}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default Select;
