import React, { createContext, useCallback, useContext, useState } from 'react';
import Modal from '../components/Modal';

interface ModalContextData {
  isOpen: boolean;
  modalContent?: React.ReactNode;
  handleModal(content?: React.ReactNode, schema?: unknown): void;
  confirmation?: unknown;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>('');
  const [confirmation, setConfirmation] = useState<unknown>();

  const handleModal = useCallback(
    (content: React.ReactNode, schema: unknown) => {
      setIsOpen(!isOpen);
      if (schema) {
        setConfirmation(schema);
      }
      setModalContent(content);
      if (content) {
        setModalContent(content);
      }
    },
    [isOpen],
  );

  return (
    <ModalContext.Provider
      value={{ isOpen, handleModal, modalContent, confirmation }}
    >
      <Modal>{modalContent}</Modal>

      {children}
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };
