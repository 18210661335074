import React, { useCallback, useState, ChangeEvent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  AppBar as NavBar,
  Tabs as TabsContainer,
  Tab,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import api from '../../services/api';

import {
  ArrowBackIcon,
  PencilIcon,
  QuestionMarkIcon,
  ToolsIcon,
  TrashIcon,
} from '../../assets/svg/SVGComponents';

import { useAuth } from '../../hooks/auth';

import ProfileForm from './ProfileForm';

import {
  MainContent,
  Dashboard,
  WelcomeSection,
  NameAndAvatarWrapper,
  AvatarInput,
  UnlockButton,
  UserAvatar,
  LastAccess,
  NavigationMenu,
} from './styles';
import IRequestError from '../../dtos/IRequestError';
import { useToast } from '../../hooks/toast';
import avatar_api_letters from '../../services/avatarLetters';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Tooltip from '../../components/Tooltip';
import AddHardwareUnlockModal from '../../modals/AddHardwareUnlockModal';
import { useModal } from '../../hooks/modal';

interface FormattedUserData {
  encodedName: string;
  last_access: string;
  firstName: string;
  lastName: string;
}

const Profile: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { t } = useTranslation();
  const { user, updateUser, isSessionExpired } = useAuth();
  const { addToast } = useToast();
  const { handleModal } = useModal();

  const handleSelectedTabChange = useCallback((_event, tabIndex) => {
    setSelectedTab(tabIndex);
  }, []);

  const handleHardwareUnlockButton = useCallback(() => {
    handleModal(<AddHardwareUnlockModal />);
  }, [handleModal]);

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api
          .patch(`/teachers/avatar`, data)
          .then(response => {
            updateUser(response.data);
          })
          .catch(async (err: IRequestError) => {
            if (!(await isSessionExpired(err))) {
              addToast({
                type: 'error',
                expression: 'Ops!',
                description: t('toasts.errors.load_stats_fail'),
              });
            }
          });
      }
    },
    [updateUser, t, isSessionExpired, addToast],
  );

  const handleDeleteAvatar = useCallback(async () => {
    api
      .delete('/teachers/avatar')
      .then(response => {
        updateUser(response.data);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops!',
            description: t('toasts.errors.load_stats_fail'),
          });
        }
      });
  }, [updateUser, addToast, isSessionExpired, t]);

  const formattedUserData = useMemo<FormattedUserData>(() => {
    const encodedName = user.name.replace(' ', '+');
    const splittedName = user.name.split(' ');
    const firstName = splittedName[0];

    let lastName = '';

    if (splittedName.length > 1) {
      [, lastName] = splittedName;
    }

    const last_access = user.last_access
      ? format(parseISO(user.last_access), 'dd/MM/yyyy', {
          locale: ptBR,
        })
      : format(new Date(), 'dd/MM/yyyy', {
          locale: ptBR,
        });

    return {
      encodedName,
      last_access,
      firstName,
      lastName,
    };
  }, [user.name, user.last_access]);

  return (
    <MainContent>
      <Dashboard>
        <Link to={-1 as any}>
          <ArrowBackIcon
            width="1.33rem"
            stroke="#1E88E5"
            strokeWidth={3}
            fill="none"
          />
          {t('navlinks.back')}
        </Link>

        <WelcomeSection>
          <NameAndAvatarWrapper>
            <AvatarInput>
              <UserAvatar
                avatarSrc={user.avatar_url || avatar_api_letters(user.name)}
              />
              {user.avatar_url && (
                <button type="button" onClick={() => handleDeleteAvatar()}>
                  <TrashIcon
                    width="1.33rem"
                    stroke="#1E88E5"
                    strokeWidth={2}
                    fill="none"
                  />
                </button>
              )}

              <label htmlFor="avatar">
                <PencilIcon
                  width="1.33rem"
                  stroke="#1E88E5"
                  strokeWidth={2}
                  fill="none"
                />

                <input type="file" id="avatar" onChange={handleAvatarChange} />
              </label>
            </AvatarInput>

            <span>
              {formattedUserData.lastName ? (
                <h1>
                  {formattedUserData.firstName}{' '}
                  <b>{formattedUserData.lastName}</b>
                </h1>
              ) : (
                <h1>
                  <b>{formattedUserData.firstName}</b>
                </h1>
              )}
            </span>
          </NameAndAvatarWrapper>
          <UnlockButton>
            <PrimaryButton type="button" onClick={handleHardwareUnlockButton}>
              Destravar Ferramenta
            </PrimaryButton>
            <Tooltip
              title={t('tooltips.titles.unlock_button')}
              description={t('tooltips.descriptions.unlock_button')}
              position="top"
              icon={
                <ToolsIcon
                  width="1.61rem"
                  stroke="#FF8800"
                  fill="none"
                  strokeWidth={1.5}
                />
              }
            >
              <QuestionMarkIcon
                width="1.28rem"
                stroke="#1E88E5"
                strokeWidth={2}
                fill="none"
              />
            </Tooltip>
          </UnlockButton>

          <LastAccess>
            {t('p.last_access')}: {formattedUserData.last_access}
          </LastAccess>
        </WelcomeSection>

        <NavigationMenu>
          <NavBar position="relative" className="profile-navBar">
            <TabsContainer
              value={selectedTab}
              onChange={handleSelectedTabChange}
              className="profile-tabs-container"
              TabIndicatorProps={{
                style: {
                  background: '#FF9800',
                  height: '5px',
                  borderRadius: '2.5px',
                  marginBottom: '-3px',
                  zIndex: 1101,
                },
              }}
            >
              <Tab
                label={t('tabs.registration_data')}
                className="profile-tab-item"
              />
            </TabsContainer>
          </NavBar>

          {selectedTab === 0 && <ProfileForm />}
        </NavigationMenu>
      </Dashboard>
    </MainContent>
  );
};
export default Profile;
