import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { firebaseAuth } from '../config/firebase';
import api from '../services/api';
import { UserGender } from '../utils/enums';
import { useToast } from './toast';
import IRequestError from '../dtos/IRequestError';
import ILessonProps from '../dtos/ILessonProps';
import IPlaylistProps from '../dtos/IPlaylistProps';
import IGroupProps from '../dtos/IGroupProps';
import IActivityProps from '../dtos/IActivitiesProps';
import ITrailsProps from '../dtos/ITrailsProps';
import IChapterProps from '../dtos/IChapterProps';

export type SubjectsList = Array<{ subjects_list: string[] }>;

enum PlanTypes {
  Premium = 'Premium',
  Freemium = 'Freemium',
}

export interface School {
  id: string;
  logo_url: string;
  name: string;
}

export interface Class {
  id: string;
  class_img: string;
  name: string;
  school_id: string;
}

interface ICurrenLessonProps extends ILessonProps {
  group: {
    playlist: IPlaylistProps;
  } & IGroupProps;
}

interface ITrailChapterProps extends IChapterProps {
  trails: ITrailsProps[];
}

interface ICurrentActivityProps extends IActivityProps {
  chapters: ITrailChapterProps[];
}

export interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string | null;
  school?: School;
  linkedin: string | null;
  gender: UserGender;
  subjects: string[];
  hardwares: IHardwareProps[];
  subscribed_newsletter: boolean;
  last_access: string | null;
  current_lesson: ICurrenLessonProps;
  current_lesson_id: string | null;
  current_activity: ICurrentActivityProps;
}

export interface IHardwareProps {
  id: string;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  trails: ITrailsProps[];
  avatar_url: string;
}

interface AuthState {
  firebaseUser: firebase.User;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  loading: boolean;
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  updateFirebaseUser(firebaseUser: firebase.User): void;
  updateUser(user: User): void;
  signOut(): void;
  isSessionExpired(err: IRequestError): Promise<boolean>;
  isOldPasswordCorrect(password: string): Promise<boolean>;
  updatePassword(password: string): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [authData, setAuthData] = useState<AuthState>({} as AuthState);
  const [loading, setLoading] = useState(true);
  const { addToast } = useToast();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onIdTokenChanged(async firebaseUser => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken(true);

        api.defaults.headers.authorization = `Bearer ${token}`;

        const teacherProfile = await api
          .get(`/user-profile`)
          .then(response => response.data)
          .catch(() => {
            addToast({
              type: 'error',
              expression: 'Ops!',
              description:
                'Ocorreu um erro na autenticação. Faça login novamente.',
            });

            localStorage.removeItem(
              `${process.env.NODE_ENV}@Fuzzy:firebaseUser`,
            );
            localStorage.removeItem(`${process.env.NODE_ENV}@Fuzzy:user`);

            delete api.defaults.headers.authorization;

            firebaseAuth.signOut();

            setAuthData({} as AuthState);
          });

        setAuthData({ firebaseUser, user: teacherProfile });

        localStorage.setItem(
          `${process.env.NODE_ENV}@Fuzzy:firebaseUser`,
          JSON.stringify(firebaseUser),
        );

        localStorage.setItem(
          `${process.env.NODE_ENV}@Fuzzy:user`,
          JSON.stringify(teacherProfile),
        );
      } else {
        localStorage.removeItem(`${process.env.NODE_ENV}@Fuzzy:firebaseUser`);
        localStorage.removeItem(`${process.env.NODE_ENV}@Fuzzy:user`);

        delete api.defaults.headers.authorization;

        await firebaseAuth.signOut();

        setAuthData({} as AuthState);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [setAuthData, addToast]);

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const firebaseCredentials = await firebaseAuth
          .signInWithEmailAndPassword(email, password)
          .catch(() => {
            addToast({
              type: 'error',
              expression: 'Ops!',
              description: 'Email ou senha incorretos. Tente novamente.',
            });
          });

        if (!firebaseCredentials || !firebaseCredentials.user) {
          setAuthData({} as AuthState);
          return;
        }

        const firebaseUser = firebaseCredentials.user;

        const token = await firebaseUser.getIdToken();

        api.defaults.headers.authorization = `Bearer ${token}`;

        const response = await api.get(`/user-profile`);

        localStorage.setItem(
          `${process.env.NODE_ENV}@Fuzzy:firebaseUser`,
          JSON.stringify(firebaseUser),
        );
        localStorage.setItem(
          `${process.env.NODE_ENV}@Fuzzy:user`,
          JSON.stringify(response.data),
        );

        setAuthData({ firebaseUser, user: response.data });
      } catch (error) {
        delete api.defaults.headers.authorization;

        setAuthData({} as AuthState);
      }
    },
    [addToast],
  );

  const updateFirebaseUser = useCallback(
    async (firebaseUser: firebase.User) => {
      localStorage.setItem(
        `${process.env.NODE_ENV}@Fuzzy:firebaseUser`,
        JSON.stringify(firebaseUser),
      );

      setAuthData({ ...authData, firebaseUser });
    },
    [authData, setAuthData],
  );

  const updateUser = useCallback(
    async (user: User) => {
      localStorage.setItem(
        `${process.env.NODE_ENV}@Fuzzy:user`,
        JSON.stringify(user),
      );

      setAuthData({ ...authData, user });
    },
    [authData, setAuthData],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem(`${process.env.NODE_ENV}@Fuzzy:firebaseUser`);
    localStorage.removeItem(`${process.env.NODE_ENV}@Fuzzy:user`);

    setAuthData({} as AuthState);

    delete api.defaults.headers.authorization;

    await firebaseAuth.signOut();
  }, []);

  const isSessionExpired = useCallback(
    async (err: IRequestError) => {
      const tokenExpired =
        !!err.response &&
        !!(err.response.data.name === 'auth/id-token-expired');

      if (tokenExpired) {
        addToast({
          type: 'error',
          expression: 'Sua sessão expirou!',
          description: 'Por favor, faça login novamente.',
        });

        await signOut();
        return tokenExpired;
      }

      return !tokenExpired;
    },
    [addToast, signOut],
  );

  const isOldPasswordCorrect = useCallback(
    async (password: string): Promise<boolean> => {
      const user = firebaseAuth.currentUser;

      if (!user) {
        return false;
      }

      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email as string,
        password,
      );

      const firebaseCredentials = await user
        .reauthenticateWithCredential(credential)
        .catch(() => null);

      if (!firebaseCredentials || !firebaseCredentials.user) {
        return false;
      }

      // const firebaseUser = firebaseCredentials.user;

      // const token = await firebaseUser.getIdToken();

      // api.defaults.headers.authorization = `Bearer ${token}`;

      // setAuthData({ ...authData, firebaseUser });

      return true;
    },
    [],
  );

  const updatePassword = useCallback(
    async (password: string) => {
      const user = firebaseAuth.currentUser;

      if (!user) {
        await signOut();
        return;
      }

      await user.updatePassword(password);
    },
    [signOut],
  );

  return (
    <AuthContext.Provider
      value={{
        loading,
        user: authData.user,
        updateUser,
        updateFirebaseUser,
        signIn,
        signOut,
        isSessionExpired,
        isOldPasswordCorrect,
        updatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth hook must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
