import React from 'react';

const SvgArrowBack: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.393 22.564"
      {...props}
    >
      <g strokeLinecap="round">
        <path d="M23.893 11.477h-22" />
        <path d="M10.661 2.121L1.5 11.282l9.161 9.161" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default SvgArrowBack;
