import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface PreviousURLDataProps {
  previousURL: string;
  allPreviousURLs: string[];
}

const PreviousURLContext = createContext<PreviousURLDataProps>({
  previousURL: '',
  allPreviousURLs: [],
});

const PreviousURLProvider: React.FC = ({ children }) => {
  const [previousURL, setPreviousURL] = useState<string[]>(
    JSON.parse(localStorage.getItem('allPreviousURLs') || '[]'),
  );
  const [lastRelevantURL, setLastRelevantURL] = useState<string>(
    localStorage.getItem('previousURL') || '',
  );
  const location = useLocation();

  useEffect(() => {
    const currentURL = location.pathname;

    setPreviousURL(prevsURL => {
      const updatedURLs = [...prevsURL, currentURL];
      localStorage.setItem('allPreviousURLs', JSON.stringify(updatedURLs));
      return updatedURLs;
    });

    if (!currentURL.includes('activity')) {
      setLastRelevantURL(currentURL);
      localStorage.setItem('previousURL', currentURL);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (previousURL.length >= 5) {
      setPreviousURL(prev => {
        const updatedURLs = prev.slice(-4);
        localStorage.setItem('allPreviousURLs', JSON.stringify(updatedURLs));
        return updatedURLs;
      });
    }
  }, [previousURL]);

  return (
    <PreviousURLContext.Provider
      value={{ previousURL: lastRelevantURL, allPreviousURLs: previousURL }}
    >
      {children}
    </PreviousURLContext.Provider>
  );
};

const usePreviousURL = (): {
  previousURL: string;
  allPreviousURLs: string[];
} => {
  const context = useContext(PreviousURLContext);
  if (context === undefined) {
    throw new Error(
      'usePreviousURL deve ser usado dentro de um PreviousURLProvider',
    );
  }
  return context;
};

export { PreviousURLProvider, usePreviousURL };
