import React from 'react';

import { Container, TooltipHeader } from './styles';

interface TooltipProps {
  title?: string;
  description: string | JSX.Element;
  icon?: React.ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  description,
  icon,
  className,
  children,
  position = 'right',
}) => {
  return (
    <Container className={className} position={position}>
      {children}
      <div className="tooltip-content">
        <TooltipHeader>
          {icon}
          <h4>{title}</h4>
        </TooltipHeader>

        <p>{description}</p>
      </div>
    </Container>
  );
};

export default Tooltip;
