import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Home from '../pages/Home';
import Journeys from '../pages/Videos/Journeys';
import Profile from '../pages/Profile';
import Projects from '../pages/Projects';
import Lessons from '../pages/Videos/Lessons';
import PrivacyPolicy from '../pages/Auth/PrivacyPolicy';
import PrivateRoute from './PrivateRoute';
import Tutorial from '../pages/Projects/Tutorial';
import Tools from '../pages/Projects/ProjectsLibrary/Tools';
import SignIn from '../pages/Auth/SignIn';
import SignUp from '../pages/Auth/SignUp';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import PlaylistAndProgress from '../pages/Videos/PlaylistAndProgress';
import Trails from '../pages/Projects/Trails';
import HardwarePage from '../pages/Hardwares/HardwarePage';
import HardwareProfile from '../pages/Hardwares/HardwareProfile';
import PlaylistsAndLessons from '../pages/Videos/PlaylistsAndLessons';
import MakeCodePage from '../pages/Projects/MakeCodePage';
import ChaptersAndProgress from '../pages/Projects/ChaptersAndProgress';
import TrailsAndProgress from '../pages/Projects/TrailsAndProgress';

const Routex: React.FC = () => {
  const location = useLocation();
  const background = location.state as { backgroundLocation?: Location };

  return (
    <Routes location={background?.backgroundLocation || location}>
      <Route path="/" element={<SignIn />}>
        <Route path="modal" element={<PrivacyPolicyModal text="" />} />
      </Route>
      <Route path="signup" element={<SignUp />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path="home"
        element={<PrivateRoute isPrivate component={Home} />}
      />
      <Route
        path="profile"
        element={<PrivateRoute isPrivate component={Profile} />}
      />

      <Route
        path="hardwares"
        element={<PrivateRoute isPrivate component={HardwarePage} />}
      />

      <Route
        path="hardwares/profile/:hardware"
        element={<PrivateRoute isPrivate component={HardwareProfile} />}
      />

      <Route
        path="videos"
        element={<PrivateRoute isPrivate component={Journeys} />}
      >
        <Route path=":active_tab">
          <Route path="*" element={<PlaylistAndProgress />} />
          <Route index path="progress" element={<PlaylistAndProgress />} />
          <Route path="tools" element={<PlaylistAndProgress />} />
          <Route path="knowledge" element={<PlaylistAndProgress />} />
          <Route path="trails" element={Trails} />
        </Route>
      </Route>

      <Route
        path="videos/:journey_slug/playlists"
        element={<PrivateRoute isPrivate component={PlaylistsAndLessons} />}
      />

      <Route
        path="videos/:journey_slug/playlists/:playlist_slug"
        element={<PrivateRoute isPrivate component={Lessons} />}
      />

      <Route
        path="videos/:journey_slug/playlists/:playlist_slug/lessons/:lesson_slug"
        element={<PrivateRoute isPrivate component={Lessons} />}
      />

      {/* TRAILS */}
      <Route
        path="projects"
        element={<PrivateRoute isPrivate component={Projects} />}
      >
        <Route path=":active_tab">
          {/* não mexer por enquanto nessa feature */}
          <Route
            path="progress"
            element={<PrivateRoute isPrivate component={TrailsAndProgress} />}
          />
          <Route path="tools" element={<Tools />} />
        </Route>
      </Route>

      <Route
        path="trails/:hardware_slug"
        element={<PrivateRoute isPrivate component={Trails} />}
      />

      <Route
        path="/makecode"
        element={<PrivateRoute isPrivate component={MakeCodePage} />}
      />
      <Route
        path="trails/:trail_slug/chapters"
        element={<PrivateRoute isPrivate component={ChaptersAndProgress} />}
      />

      <Route
        path="trails/:trail_slug/chapters/:chapter_slug"
        element={<PrivateRoute isPrivate component={ChaptersAndProgress} />}
      />

      <Route
        path="projects/:trail_slug/:chapter_id/activity/:activity_slug/"
        element={<PrivateRoute isPrivate component={Tutorial} />}
      />

      <Route
        path="/privacypolicy"
        element={<PrivateRoute isPrivate component={PrivacyPolicy} />}
      />
    </Routes>
  );
};

export default Routex;
