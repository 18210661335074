import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ContentWrapper,
  Fill,
  InfoBar,
  InfoPlaylist,
  MainContent,
  MainHeadContent,
  PlaylistsContent,
  Progress,
  Title,
  ChapterItem,
  IconWrapper,
  InfoWrapper,
  ListWrapper,
  ProgressList,
  IconWrapperTrail,
} from './styles';
import IRequestError from '../../../dtos/IRequestError';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { DotContent } from '../../../assets/svg/SVGComponents';
import ITrailsProps from '../../../dtos/ITrailsProps';
import IChapterProps from '../../../dtos/IChapterProps';
import ListContentActivity from '../../../components/ListContentActivity';
import ListContentChapter from '../../../components/ListContentChapter';
import LoadingState from './LoadingState';

// import { Container } from './styles';

const TrailsAndProgress: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isSessionExpired, user } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { trail_slug } = useParams();
  const [trailsData, setTrailsData] = useState<ITrailsProps | null>(null);
  const [chaptersData, setChaptersData] = useState<IChapterProps | null>(null);
  const [duration, setDuration] = useState(0);

  const handleButtonChapter = useCallback((chapter: IChapterProps): void => {
    setChaptersData(chapter);
  }, []);

  const handleTimeLeftPlaylist = useCallback(
    (chapter: IChapterProps): number => {
      if (chapter.type === 'lesson') {
        let timeLeft = 0;

        for (let x = 0; x < chapter.lessons.length; x += 1) {
          timeLeft += chapter.lessons[x].duration;
        }

        return timeLeft;
      }
      let timeLeft = 0;

      for (let x = 0; x < chapter.activities.length; x += 1) {
        if (!chapter.activities[x].watched)
          timeLeft += chapter.activities[x].duration;
      }

      return timeLeft;
    },
    [],
  );

  useEffect(() => {
    if (trailsData && chaptersData) {
      const time = handleTimeLeftPlaylist(chaptersData);
      setDuration(time);
    }
  }, [chaptersData, handleTimeLeftPlaylist, trailsData]);

  useEffect(() => {
    setIsLoading(true);

    api
      .get(
        `/trails/${user.current_activity.chapters[0].trails[0].slug}/progress`,
      )
      .then(response => {
        setTrailsData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, isSessionExpired, t, user.current_activity.chapters]);
  // nao coloque isSessionExpired no array de dependencias

  useEffect(() => {
    if (trailsData && trailsData.chapters) {
      const chapter = trailsData.chapters.find(
        ch => ch.slug === user.current_activity.chapters[0].slug,
      );

      if (chapter) {
        setChaptersData(chapter);
      } else {
        setChaptersData(trailsData.chapters[0]);
      }
    }
  }, [trailsData, user.current_activity]);

  const handleProgressChapters = useCallback(
    (chapter: IChapterProps) => {
      if (!chapter.done_activities) {
        return 0;
      }

      if (!trailsData?.chapters.length) {
        return 0;
      }
      let percentageCompleted = 0;
      if (chapter.type === 'lesson') {
        percentageCompleted =
          (chapter.done_activities / chapter.lessons.length) * 100;
      }
      if (chapter.type === 'activity') {
        percentageCompleted =
          (chapter.done_activities / chapter.activities.length) * 100;
      }

      return Math.round(percentageCompleted);
    },
    [trailsData],
  );

  const handleProgressTrails = useCallback(
    (trail: ITrailsProps) => {
      let doneActivies = 0;
      trail.chapters.forEach(chapter => {
        if (!chapter.done_activities) {
          return;
        }
        if (!trailsData?.chapters.length) {
          return;
        }

        if (chapter.type === 'lesson') {
          doneActivies += chapter.done_activities;
        }
        if (chapter.type === 'activity') {
          doneActivies += chapter.done_activities;
        }
      });

      return Math.round((doneActivies / trail.chapters.length) * 100);
    },
    [trailsData],
  );

  return (
    <>
      {!!isLoading && <LoadingState />}
      {!isLoading && (
        <ContentWrapper>
          <MainContent>
            <MainHeadContent>
              {trailsData && chaptersData && (
                <Title>
                  <DotContent width="2.22rem" />
                  {trailsData.type === 'chapter' ? (
                    <h1>{t(`trails.${trailsData.slug}.title`)}</h1>
                  ) : (
                    <h1>{t(`chapters.${chaptersData.slug}.title`)}</h1>
                  )}
                </Title>
              )}

              {chaptersData && (
                <InfoPlaylist>
                  <div />
                  {trailsData && (
                    <span>
                      <Trans
                        i18nKey="spans.time_left"
                        values={{ time: duration }}
                      />
                    </span>
                  )}
                </InfoPlaylist>
              )}
            </MainHeadContent>
            {chaptersData && trailsData && (
              <ListWrapper>
                {trailsData.type === 'activity' && (
                  <ListContentActivity
                    chapters={chaptersData}
                    trail_slug={trailsData.slug}
                  />
                )}

                {trailsData.type === 'chapter' && (
                  <ListContentChapter
                    chapters={chaptersData}
                    trails={trailsData}
                  />
                )}
              </ListWrapper>
            )}
          </MainContent>

          <PlaylistsContent>
            {!!trailsData && !!chaptersData && !!trailsData.chapters.length && (
              <ProgressList>
                <h2>{t('h2.playlists_progress')}</h2>
                <ul>
                  {trailsData.type === 'activity' &&
                    trailsData.chapters.map(chapter => (
                      <ChapterItem
                        selected={chapter.id === chaptersData.id}
                        key={chapter.id}
                      >
                        <button
                          type="button"
                          onClick={() => handleButtonChapter(chapter)}
                        >
                          <IconWrapper>
                            <img src={chapter.icon_url} alt="icon" />
                          </IconWrapper>

                          <InfoWrapper>
                            <InfoBar progress={handleProgressChapters(chapter)}>
                              <h3>{t(`chapters.${chapter.slug}.title`)}</h3>
                              <span>{handleProgressChapters(chapter)}%</span>
                            </InfoBar>

                            <Progress>
                              <Fill
                                progress={handleProgressChapters(chapter)}
                              />
                            </Progress>
                          </InfoWrapper>
                        </button>
                      </ChapterItem>
                    ))}

                  {trailsData.type === 'chapter' && (
                    <ChapterItem selected key={trailsData.id}>
                      <button type="button">
                        <IconWrapperTrail>
                          <img src={trailsData.image_url} alt="icon" />
                        </IconWrapperTrail>

                        <InfoWrapper>
                          <InfoBar progress={handleProgressTrails(trailsData)}>
                            <h3>{t(`trails.${trailsData.slug}.title`)}</h3>
                            <span>{handleProgressTrails(trailsData)}%</span>
                          </InfoBar>

                          <Progress>
                            <Fill progress={handleProgressTrails(trailsData)} />
                          </Progress>
                        </InfoWrapper>
                      </button>
                    </ChapterItem>
                  )}
                </ul>
              </ProgressList>
            )}
          </PlaylistsContent>
        </ContentWrapper>
      )}
    </>
  );
};

export default TrailsAndProgress;
