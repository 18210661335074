import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import { ChevronRightIcon, FuzzyMakers } from '../../assets/svg/SVGComponents';

// import imageArduinoUnlocked from '../../../assets/images/card-arduino-color-700x420px.jpg';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import {
  MainContent,
  Dashboard,
  WelcomeSection,
  UserAvatar,
  ActivitiesSidebar,
  ActivitiesWrapper,
  ShimmerWrapper,
  ShimmerEffect,
} from './styles';
import IRequestError from '../../dtos/IRequestError';
import ListActivities from '../../components/ListActivities';
import LogoFaceRobot from '../../assets/svg/SVGComponents/LogoFaceRobot';
import IChapterProps from '../../dtos/IChapterProps';
import ITrailsProps from '../../dtos/ITrailsProps';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import AddHardwareUnlockModal from '../../modals/AddHardwareUnlockModal';
import { useModal } from '../../hooks/modal';
import LessonList from '../../components/LessonList';
import GridHardware from '../../components/GridHardware';

interface FormattedUserData {
  encodedName: string;
  encodedFirstLastName: string;
  shortName: string;
}

const Home: React.FC = () => {
  const { user, isSessionExpired } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { handleModal } = useModal();
  const [chapterData, setChapterData] = useState<IChapterProps>();
  const [trailData, setTrailData] = useState<ITrailsProps>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .get(
        `/trails/${user.current_activity.chapters[0].trails[0].slug}/progress`,
      )
      .then(response => {
        setIsLoading(false);
        setTrailData(response.data);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, isSessionExpired, t, user]);

  useEffect(() => {
    if (trailData) {
      const currentChapter = trailData.chapters.find(
        chapter => chapter.id === user.current_activity.chapters[0].id,
      );

      setChapterData(currentChapter);
    }
  }, [trailData, user.current_activity.chapters]);

  const handleHardwareUnlockButton = useCallback(() => {
    handleModal(<AddHardwareUnlockModal />);
  }, [handleModal]);

  const formattedUserData = useMemo<FormattedUserData>(() => {
    const encodedName = user.name.replace(' ', '+');

    const splitName = user.name.split('');
    const fisrtName = user.name.split(' ')[0];
    const lastName = splitName[splitName.length - 1];
    const encodedFirstLastName = `${fisrtName}+${'+'}+${lastName}`;
    const shortName = fisrtName.charAt(0).toUpperCase() + fisrtName.slice(1);

    return {
      encodedName,
      shortName,
      encodedFirstLastName,
    };
  }, [user.name]);

  return (
    <MainContent>
      <Dashboard>
        <WelcomeSection>
          <Link data-testid="img-profile" to="/profile">
            <UserAvatar
              avatarSrc={
                user.avatar_url ||
                'https://fuzzyplaydev.s3.us-east-2.amazonaws.com/defaultUserImage.png'
              }
            />
          </Link>

          <span>
            <Link to="/profile">
              <Trans
                i18nKey="spans.home_greetings"
                values={{ user_name: formattedUserData.shortName }}
              />
            </Link>
            <p>{t('p.check_week_summary')}</p>
          </span>

          <PrimaryButton type="button" onClick={handleHardwareUnlockButton}>
            {t('buttons.unlock_button_modal')}
          </PrimaryButton>
        </WelcomeSection>
        <GridHardware />
      </Dashboard>

      <ActivitiesSidebar>
        <div>
          <LogoFaceRobot fill="#fff" width="2.84rem" />
          <FuzzyMakers fill="none" width="16.58rem" />
        </div>

        <header>
          <Link to="/projects/progress">
            <h3>{t('h3.access_playlists')}</h3>
            <ChevronRightIcon height="1.12rem" stroke="#fff" strokeWidth={2} />
          </Link>

          <h4>
            <Trans i18nKey="h4.continue_where_you_left_of" />
          </h4>
        </header>
        <ActivitiesWrapper>
          {!!isLoading && (
            <ShimmerWrapper>
              <ShimmerEffect className="shimmer-medium-title" />
              <ShimmerEffect className="shimmer-activity" />
              <ShimmerEffect className="shimmer-activity" />
              <ShimmerEffect className="shimmer-activity" />
            </ShimmerWrapper>
          )}
          {!isLoading && chapterData && (
            <>
              <h2>
                <strong>
                  <Trans
                    i18nKey="h2.chapter_number"
                    values={{ number: chapterData.order }}
                  />
                </strong>
                <Trans i18nKey={`chapters.${chapterData.slug}.title`} />
              </h2>
              {chapterData.type === 'activity' && (
                <ListActivities
                  trail_slug={user.current_activity.chapters[0].trails[0].slug}
                  chapter_id={chapterData.id}
                  activities={chapterData.activities}
                />
              )}
              {chapterData.type === 'lesson' && (
                <LessonList
                  trail_slug={user.current_activity.chapters[0].trails[0].slug}
                  chapter_id={chapterData.id}
                  activities={chapterData.lessons}
                />
              )}
            </>
          )}
        </ActivitiesWrapper>
      </ActivitiesSidebar>
    </MainContent>
  );
};

export default Home;
