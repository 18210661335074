import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { HardwareSlug } from '../../utils/enums';
import { useAuth } from '../../hooks/auth';
import { ToolCard, ToolsGrid } from './styles';

import imageFuzzyBitsLocked from '../../assets/images/card-fuzzy-start-gray.jpg';
import imageFuzzyBitsUnlocked from '../../assets/images/card-fuzzy-start-color.jpg';
import imageMicroBitLocked from '../../assets/images/card-microbit-gray-700x420px.png';
import imageMicroBitUnlocked from '../../assets/images/card-microbit-color-700x420px.png';
import imageMakeyMakeyLocked from '../../assets/images/card-makemakey-gray-700x420px.png';
import imageMakeyMakeyUnlocked from '../../assets/images/card-makemakey-color-700x420px.png';
import imageScratchLocked from '../../assets/images/card-scratch-gray.jpg';
import imageScratchUnlocked from '../../assets/images/card-scratch-color.jpg';
import imageFuzzyPlusLocked from '../../assets/images/card-fuzzy-plus-gray.jpg';
import imageFuzzyPlusUnlocked from '../../assets/images/card-fuzzy-plus-color.jpg';

import { PadlockClose, PadlockOpen } from '../../assets/svg/SVGComponents';

const GridHardware: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const handleStatusHardware = useCallback(
    (SlugHardware: HardwareSlug): boolean => {
      if (user.hardwares) {
        const unlockHardwares = user.hardwares.find(
          hardware => hardware.slug === SlugHardware,
        );
        if (unlockHardwares) {
          return true; // unlock
        }
      }
      return false; // locked
    },
    [user.hardwares],
  );
  return (
    <ToolsGrid>
      <ToolCard
        to={
          handleStatusHardware(HardwareSlug.FUZZYBITS)
            ? `/hardwares/profile/fuzzy_bits`
            : `${pathname}`
        }
        className="item-1"
        background={
          handleStatusHardware(HardwareSlug.FUZZYBITS)
            ? imageFuzzyBitsUnlocked
            : imageFuzzyBitsLocked
        }
      >
        <span>Fuzzy Start</span>
        <p>
          {handleStatusHardware(HardwareSlug.FUZZYBITS) ? (
            <PadlockOpen fill="none" width="1.5rem" />
          ) : (
            <PadlockClose fill="#fff" width="1.5rem" />
          )}
        </p>
      </ToolCard>
      <ToolCard
        to={
          handleStatusHardware(HardwareSlug.FUZZYPLUS)
            ? `/hardwares/profile/fuzzy_bits_plus`
            : `${pathname}`
        }
        className="item-2"
        background={
          handleStatusHardware(HardwareSlug.FUZZYPLUS)
            ? imageFuzzyPlusUnlocked
            : imageFuzzyPlusLocked
        }
      >
        <span>Fuzzy Plus+</span>
        <p>
          {handleStatusHardware(HardwareSlug.FUZZYPLUS) ? (
            <PadlockOpen fill="none" width="1.5rem" />
          ) : (
            <PadlockClose fill="#fff" width="1.5rem" />
          )}
        </p>
      </ToolCard>
      <ToolCard
        to={
          handleStatusHardware(HardwareSlug.MICROBIT)
            ? `/hardwares/profile/micro_bit`
            : `${pathname}`
        }
        className="item-3"
        background={
          handleStatusHardware(HardwareSlug.MICROBIT)
            ? imageMicroBitUnlocked
            : imageMicroBitLocked
        }
      >
        <span>Micro:bit</span>
        <p>
          {handleStatusHardware(HardwareSlug.MICROBIT) ? (
            <PadlockOpen fill="none" width="1.22rem" />
          ) : (
            <PadlockClose fill="#fff" width="1.22rem" />
          )}
        </p>
      </ToolCard>
      <ToolCard
        to={
          handleStatusHardware(HardwareSlug.MAKEYMAKEY)
            ? `/hardwares/profile/makey_makey`
            : `${pathname}`
        }
        className="item-4"
        background={
          handleStatusHardware(HardwareSlug.MAKEYMAKEY)
            ? imageMakeyMakeyUnlocked
            : imageMakeyMakeyLocked
        }
      >
        <span>Makey Makey</span>
        <p>
          {handleStatusHardware(HardwareSlug.MAKEYMAKEY) ? (
            <PadlockOpen fill="none" width="1.22rem" />
          ) : (
            <PadlockClose fill="#fff" width="1.22rem" />
          )}
        </p>
      </ToolCard>
      <ToolCard
        to={
          handleStatusHardware(HardwareSlug.SCRATCH)
            ? `/hardwares/profile/scratch`
            : `${pathname}`
        }
        className="item-5"
        background={
          handleStatusHardware(HardwareSlug.SCRATCH)
            ? imageScratchUnlocked
            : imageScratchLocked
        }
      >
        <span>Scratch</span>
        <p>
          {handleStatusHardware(HardwareSlug.SCRATCH) ? (
            <PadlockOpen fill="none" width="1.22rem" />
          ) : (
            <PadlockClose fill="#fff" width="1.22rem" />
          )}
        </p>
      </ToolCard>
    </ToolsGrid>
  );
};

export default GridHardware;
