import React, { useRef } from 'react';

// import { Container } from './styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  MainContent,
  MainContentHeader,
  Title,
  Container,
  MakecodeIframe,
} from './styles';
import { ArrowBackIcon } from '../../../assets/svg/SVGComponents';
import MicroBitLogo from '../../../assets/svg/SVGComponents/MicroBitLogo';

const MakeCodePage: React.FC = () => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const { t } = useTranslation();
  return (
    <MainContent>
      <MainContentHeader>
        <Link to={-1 as any}>
          <ArrowBackIcon
            width="1.22rem"
            stroke="#1E88E5"
            strokeWidth={3}
            fill="none"
          />
          {t('navlinks.back')}
        </Link>
        <Title>
          <MicroBitLogo width="3rem" fill="#FF9800" strokeWidth={2} />
          <h1>{t('h1.makecode_page')}</h1>
        </Title>
      </MainContentHeader>

      <Container>
        <MakecodeIframe>
          <iframe
            src="https://makecode.microbit.org/#tutorial:github:wolfloiz/microbit-tutorials/default?controller=1&noproject=1&nocookiebanner=1&ws=browser"
            title="test pxt"
            ref={iFrameRef}
          />
        </MakecodeIframe>
      </Container>
    </MainContent>
  );
};

export default MakeCodePage;
