import styled from 'styled-components';

interface StepProps {
  accomplished: boolean;
}

interface StepBarProps {
  steps: number;
}

export const Container = styled.div`
  margin-top: 2rem;
  padding: 0;
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  > div {
    margin: 0 4rem;
  }

  button {
    font-family: 'RedhatDisplay-Bold';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.27rem;
    padding: 0.77rem 0px;
    transition: all 0.3s;
    color: #1e88e5;

    > svg {
      margin-right: 0.61rem;
      margin-left: 0.61rem;
    }

    &:disabled {
      cursor: not-allowed;
      color: #63646a;
      background: none;
      svg {
        stroke: #63646a;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
`;

export const Steps = styled.div<StepBarProps>`
  display: flex;

  > div:nth-child(2) {
    margin-left: 5.29rem;
  }

  > div:nth-child(3) {
    margin-left: 5.13rem;
  }
`;

export const Step = styled.div<StepProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > span {
    width: 250%;
    top: -2.2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 0.61rem;
    letter-spacing: 0.12rem;
    margin-bottom: 1.4rem;
  }

  > div {
    width: 3.9rem;
    height: 3.9rem;
    background-color: ${props => (props.accomplished ? '#4CAF50' : '#f2f2f2')};
    transition: background-color 0.4s ease;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > p {
      width: 0.12rem;
      height: 1.22rem;
      background-color: ${props =>
        props.accomplished ? '#4CAF50' : '#f2f2f2'};
      transition: background-color 0.4s ease;
      position: absolute;
      top: -1.22rem;
      left: 50%;
      transform: translateX(-50%);
    }

    > div {
      width: 3.3rem;
      height: 3.3rem;
      border-radius: 50%;
      outline: ${props =>
        props.accomplished ? '2px solid #FFFEFC' : '2px solid #b7b7b7'};
      transition: outline 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        stroke: ${props => (props.accomplished ? '#FFFEFC' : '#b7b7b7')};
        transition: stroke 0.4s ease;
      }
    }
  }
`;
