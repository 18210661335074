import React from 'react';

const SvgChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.768 16.384"
      {...props}
    >
      <path
        d="M2.121 2.121l12.762 12.763L27.645 2.122"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgChevronDown;
