import { useState, useEffect } from 'react';

interface IContainerSize {
  width: number;
  height: number;
}

const useContainerSize = (
  ref: React.RefObject<HTMLDivElement>,
): IContainerSize => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return size;
};

export default useContainerSize;
