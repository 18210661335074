import React, { createContext, useContext, useState } from 'react';

interface ISelectedTabs {
  studentsAndClasses: number;
  chooseTemplate: number;
}

interface ITabContextData {
  selectedTabs: ISelectedTabs;
  setSelectedTabs: React.Dispatch<React.SetStateAction<ISelectedTabs>>;
}

const TabContext = createContext<ITabContextData>({} as ITabContextData);

const TabProvider: React.FC = ({ children }) => {
  const [selectedTabs, setSelectedTabs] = useState<ISelectedTabs>({
    studentsAndClasses: 0,
    chooseTemplate: 0,
  });

  return (
    <TabContext.Provider value={{ selectedTabs, setSelectedTabs }}>
      {children}
    </TabContext.Provider>
  );
};

function useTab(): ITabContextData {
  const context = useContext(TabContext);

  if (!context) {
    throw new Error('useTab must be used within ModalProvider');
  }

  return context;
}

export { TabProvider, useTab };
