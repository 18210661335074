import { lighten } from 'polished';
import styled from 'styled-components';

export const MainContent = styled.div`
  padding: 2.67rem 4.4rem;
  display: flex;
  flex-direction: column;
`;

export const MainContentHeader = styled.header`
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  margin-bottom: 3.5rem;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 2rem;
  > a {
    display: flex;
    align-items: center;
    margin-bottom: 0.89rem;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    line-height: 1.61rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'RedHataDisplay-Regular';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;

export const JourneyContent = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    margin-top: 1.83rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    color: #1e88e5;
  }

  p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
    line-height: 1.22rem;
  }
`;

export const Container = styled.div`
  width: auto;
`;
export const MakecodeIframe = styled.div`
  width: 100%;
  height: 90vh;

  iframe {
    width: 100%;
    height: 100%;
    border-style: none;
  }
`;
