import React, { useRef, useCallback, useState, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import i18next from 'i18next';
import BackgroundBLue from '../../../assets/images/backgroundSignIn.jpg';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  EmailInput,
  FuzzyLogoNewOrange,
  LockIcon,
} from '../../../assets/svg/SVGComponents';

import Input from '../../../components/Input';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

import { useAuth } from '../../../hooks/auth';

import {
  Container,
  Content,
  SideContent,
  SignInArea,
  TipBackground,
  ImageArea,
  LanguageButtons,
  TopContent,
} from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      formRef.current?.setErrors({});

      try {
        setIsLoading(true);
        const signInSchema = Yup.object().shape({
          email: Yup.string()
            .required(t('yup_errors.email_required'))
            .email(t('yup_errors.invalid_email')),
          password: Yup.string().required(t('yup_errors.password_required')),
        });

        await signInSchema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        navigate('/home');

        // navigate('/teachers/home');
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, signIn, t],
  );

  const handleLanguageChange = useCallback((lang_code: string) => {
    i18next.changeLanguage(lang_code);
  }, []);
  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FuzzyLogoNewOrange />

          <h1>
            <Trans i18nKey="h1.signin" />
          </h1>

          <span>{t('labels.email')}</span>
          <Input
            name="email"
            type="text"
            placeholder={t('placeholders.email')}
            icon={EmailInput}
          />

          <span>{t('labels.password')}</span>
          <Input
            name="password"
            placeholder={t('placeholders.password')}
            type="password"
            icon={LockIcon}
            hasToggleButton
          />

          <PrimaryButton autoFocus type="submit" loading={isLoading}>
            {t('buttons.signin')}
          </PrimaryButton>
          <SignInArea>
            <Link to="/forgot-password">{t('navlinks.forgot_password')}</Link>
            <Link to="/signup">{t('navlinks.signup')}</Link>
          </SignInArea>
        </Form>
        <footer />
      </Content>
      <SideContent>
        <ImageArea>
          <img
            src="https://fuzzyplaydev.s3.us-east-2.amazonaws.com/backgroundSignIn.jpg"
            alt="fuzzy-logo"
          />
          <TopContent>
            <LanguageButtons>
              <button
                type="button"
                className={
                  i18next.language === 'pt-BR' ? 'active-language' : ''
                }
                onClick={() => handleLanguageChange('pt-BR')}
              >
                PT
              </button>
              |
              <button
                type="button"
                className={i18next.language === 'en' ? 'active-language' : ''}
                onClick={() => handleLanguageChange('en')}
              >
                EN
              </button>
            </LanguageButtons>
            <TipBackground>
              <img
                src="https://fuzzyplaydev.s3.us-east-2.amazonaws.com/backgroundTip.png"
                alt="tip-bg"
              />
            </TipBackground>
          </TopContent>
        </ImageArea>
      </SideContent>
    </Container>
  );
};

export default memo(SignIn);
