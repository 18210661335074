import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Content, FuzzyBitsArea, MicroBitArea } from './styles';

// import { Container } from './styles';

const Tools: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <FuzzyBitsArea to="/projects/library/me/categories">
        <h1>{t('h1.fuzzy_bits')}</h1>
        <p>
          <Trans i18nKey="p.fuzzy_bits" />
        </p>
      </FuzzyBitsArea>
      <MicroBitArea to="/categories/coding/templates">
        <h1>{t('h1.microbit')}</h1>
        <p>
          <Trans i18nKey="p.micro_bit" />
        </p>
      </MicroBitArea>
    </Content>
  );
};

export default Tools;
