import styled, { keyframes } from 'styled-components';

export const Container = styled.div``;

export const AccordionHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;

    > h4 {
      width: 100%;
      font-family: 'RedHatDisplay-Extrabold';
      font-size: 1.1rem;
      line-height: 1.8rem;
    }

    > p {
      font-family: 'RedHatDisplay-Bold';
      font-size: 0.88rem;
      line-height: 1.28rem;
      color: #ff9800;
    }
  }

  > svg {
    transition: transform 0.3s;

    &.open {
      transform: rotate(90deg);
    }
  }
`;

const removeOverflow = keyframes`
  from { overflow: visible; }
  to { overflow: hidden; }
`;

const addOverflow = keyframes`
  from { overflow: hidden; }
  to { overflow: visible; }
`;

export const AccordionContent = styled.div`
  display: flex;
  flex-direction: column;

  overflow: visible;
  animation: 0.5s ${addOverflow} cubic-bezier(1, 0, 1, 0);

  transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;

  > ul > li::after {
    transition: opacity 0.5s cubic-bezier(1, 0, 1, 0);
    opacity: 1;
  }

  &.collapsed {
    > ul > li::after {
      transition: opacity 0.5s cubic-bezier(0, 1, 0, 1);
      opacity: 0;
    }

    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    overflow: hidden;
    animation: 0.5s ${removeOverflow} cubic-bezier(0, 1, 0, 1);
  }
`;
