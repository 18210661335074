import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

const developmentConfig = {
  apiKey: 'AIzaSyCjScoBimgpI2wEjWRBDSUIp_FkCaBASoc',
  authDomain: 'fuzzyplaydev.firebaseapp.com',
  projectId: 'fuzzyplaydev',
  storageBucket: 'fuzzyplaydev.appspot.com',
  messagingSenderId: '682533369398',
  appId: '1:682533369398:web:2e2f71a23722d61933ca97',
  measurementId: 'G-78YL2PP29E',
};

const productionConfig = {
  apiKey: 'AIzaSyClv5yJWrVKt9VuRbaVyqMKtWO7RB8ZQ9k',
  authDomain: 'fuzzyplay-ba022.firebaseapp.com',
  projectId: 'fuzzyplay-ba022',
  storageBucket: 'fuzzyplay-ba022.appspot.com',
  messagingSenderId: '834814701821',
  appId: '1:834814701821:web:734bd3bf88951885474e94',
  measurementId: 'G-SD5YEGGF0Y',
};

let firebaseConfig = developmentConfig;

if (process.env.REACT_APP_BRANCH === 'main') {
  firebaseConfig = productionConfig;
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const firebaseAuth = firebase.auth();

export { firebaseAuth };
