import React from 'react';

import {
  LoadingWrapper,
  ListWrapper,
  MainContent,
  PlaylistsContent,
  ProgressList,
  ShimmerEffect,
  MainHeadContent,
} from './styles';

// import { Container } from './styles';

const LoadingState: React.FC = () => {
  return (
    <LoadingWrapper>
      <MainContent>
        <MainHeadContent>
          <ShimmerEffect className="loading-title" />
        </MainHeadContent>

        <ListWrapper>
          <ShimmerEffect className="card-chapter" />
          <ShimmerEffect className="card-chapter" />
          <ShimmerEffect className="card-chapter" />
          <ShimmerEffect className="card-chapter" />
        </ListWrapper>
      </MainContent>

      <PlaylistsContent>
        <ProgressList>
          <ShimmerEffect className="progress-title" />
          <ShimmerEffect className="progress-card" />
          <ShimmerEffect className="progress-card" />
          <ShimmerEffect className="progress-card" />
          <ShimmerEffect className="progress-card" />
        </ProgressList>
      </PlaylistsContent>
    </LoadingWrapper>
  );
};

export default LoadingState;
