import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const ActivityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="#fff" clipPath="url(#a)">
      <path d="M23.231.45c.245.134.324.337.32.615-.01 2.694 0 5.383-.005 8.077 0 .462-.144.605-.606.605h-2.546c-.434 0-.587-.152-.587-.577V7.862c0-.55-.383-.952-.896-.957-.513-.004-.901.402-.906.948V9.23c0 .342-.17.517-.513.517h-2.73c-.324 0-.5-.18-.5-.503V6.905h-.896a1.801 1.801 0 0 1-1.78-1.492c-.198-1.058.615-2.07 1.692-2.112.314-.01.633 0 .984 0v-.245c0-.66.01-1.321-.005-1.987-.004-.277.075-.48.32-.614h8.659L23.231.45ZM.45 6.18c.134-.245.338-.328.615-.319.66.014 1.322.005 1.982.005h.245V5.02c.005-1.049.786-1.858 1.802-1.858 1.012 0 1.803.804 1.807 1.853v.855h2.334c.328 0 .503.176.508.5v2.749c0 .31-.18.49-.494.49-.467.004-.934 0-1.4 0-.55 0-.952.388-.948.9 0 .514.402.897.952.902H9.23c.338 0 .513.175.513.517v2.31h1.94v-.836c.005-1.049.791-1.857 1.803-1.857s1.798.804 1.807 1.853v.85h2.287c.384 0 .55.162.55.55v8.188c0 .402-.157.56-.564.56H1.014c-.263 0-.443-.098-.568-.32V6.18H.45Zm8.392 16.46v-2.306c0-.356.171-.527.527-.527h1.354c.554 0 .956-.379.96-.892.005-.527-.396-.91-.96-.915H9.346c-.328 0-.5-.175-.504-.503v-2.325H1.37v7.468h7.472Zm-.01-15.873H6.519c-.342 0-.513-.176-.513-.513V4.992c-.005-.531-.393-.929-.901-.929-.504 0-.897.398-.901.93V6.23c0 .37-.167.536-.536.536H1.379v7.476h7.463v-1.931c-.139 0-.25.004-.36 0-.389-.023-.79.009-1.165-.079-.864-.208-1.423-1.076-1.308-1.931A1.81 1.81 0 0 1 7.77 8.707c.351-.009.702 0 1.063 0v-1.94Zm8.392 8.391h-2.32c-.341 0-.512-.175-.512-.513v-1.261c-.005-.532-.393-.929-.901-.929-.504 0-.897.397-.901.929v1.238c0 .37-.167.536-.536.536H9.766V17.1c.37 0 .726-.014 1.077 0 .702.032 1.234.365 1.553.994.314.628.268 1.256-.135 1.834-.346.504-.845.758-1.455.772-.351.009-.698 0-1.044 0v1.927h7.467v-7.468h-.005Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.45.45h23.1v23.105H.45z" />
      </clipPath>
    </defs>
  </svg>
);
export default ActivityIcon;
