import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Microchip = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 485.18 485.18" {...props}>
    <path d="m483.94 338.09-15.4-33.2c-2.2-4.8-7-7.8-12.2-7.8h-73.4v-19.6h36.1c7.4 0 13.5-6 13.5-13.5 0-7.4-6-13.5-13.5-13.5h-36.1v-21h73.4c5.3 0 10-3.1 12.2-7.8l15.3-33.3c3.1-6.8.2-14.8-6.6-17.9-6.8-3.1-14.8-.1-17.9 6.6l-11.7 25.5h-64.7v-22.3h36.1c7.4 0 13.5-6 13.5-13.5 0-7.4-6-13.5-13.5-13.5h-36.1v-12.1c0-21.4-17.4-38.8-38.8-38.8h-14.4v-36.3c0-7.4-6-13.5-13.5-13.5s-13.5 6-13.5 13.5v36.3h-22.3v-64.9l25.4-11.7c6.8-3.1 9.7-11.1 6.6-17.9-3.1-6.8-11.1-9.7-17.9-6.6l-33.2 15.3c-4.8 2.2-7.8 7-7.8 12.2v73.5h-21v-36.2c0-7.4-6-13.5-13.5-13.5-7.4 0-13.5 6-13.5 13.5v36.3h-19.6v-73.5c0-5.3-3.1-10-7.8-12.2l-33.3-15.3c-6.8-3.1-14.8-.1-17.9 6.6-3.1 6.8-.2 14.8 6.6 17.9l25.4 11.7v64.9h-17.7c-19.6 0-38.1 15.5-39.1 27.8v23.1h-36.1c-7.4 0-13.5 6-13.5 13.5 0 7.4 6 13.5 13.5 13.5h36.1v22.2h-64.7l-11.7-25.4c-3.1-6.8-11.1-9.7-17.9-6.6s-9.7 11.1-6.6 17.9l15.3 33.2c2.2 4.8 7 7.8 12.2 7.8h73.4v21h-36.1c-7.4 0-13.5 6-13.5 13.5 0 7.4 6 13.5 13.5 13.5h36.1v19.6h-73.3c-5.3 0-10 3.1-12.2 7.8l-15.3 33.3c-3.1 6.8-.2 14.8 6.6 17.9 6.8 3.1 14.8.1 17.9-6.6l11.7-25.4h64.7v10.2c.5 35.9 21.4 48.6 39.1 48.6h17.7v64.9l-25.5 11.6c-6.8 3.1-9.7 11.1-6.6 17.9 3.1 6.8 11.1 9.7 17.9 6.6l33.3-15.3c4.8-2.2 7.8-7 7.8-12.2v-73.5h19.6v36.3c0 7.4 6 13.5 13.5 13.5 7.4 0 13.5-6 13.5-13.5v-36.3h21v73.5c0 5.3 3.1 10 7.8 12.2l33.3 15.3c6.8 3.1 14.8.1 17.9-6.6 3.1-6.8.2-14.8-6.6-17.9l-25.4-11.7v-64.9h22.3v36.3c0 7.4 6 13.5 13.5 13.5s13.5-6 13.5-13.5v-36.3h14.4c21.4 0 38.8-17.4 38.8-38.8v-20h64.7l11.7 25.4c3.1 6.8 11.1 9.7 17.9 6.6 6.8-3.1 9.7-11.1 6.6-17.9zm-127.9 5.9h-.1c0 6.5-5.3 11.9-11.9 11.9h-202.7c-10.7 0-12.1-15.4-12.2-21.9v-199.3c2.1-2 6.8-5.4 12.2-5.4h202.8c6.5 0 11.9 5.3 11.9 11.9v202.8z" />
  </svg>
);

export default Microchip;
