import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const PadlockClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 30" {...props}>
    <path d="M10.516 0h1.278c.218.038.435.08.654.116 3.179.516 5.814 3.31 6.075 6.547.111 1.369.05 2.751.067 4.127.002.144 0 .287 0 .46h.774c1.75.002 2.942 1.195 2.943 2.954v12.83c0 1.762-1.19 2.962-2.933 2.964h-5.228c-3.766 0-7.531.003-11.297-.002-1.578-.002-2.835-1.205-2.84-2.758-.014-4.413-.012-8.827 0-13.24a2.742 2.742 0 0 1 2.302-2.693c.454-.072.92-.056 1.409-.082v-.317c0-1.181-.01-2.363.004-3.544C3.76 4.227 5.82 1.378 8.77.408 9.338.222 9.934.134 10.516 0Zm5.59 11.242c0-.991.01-1.956-.002-2.92-.007-.564.005-1.136-.078-1.691-.389-2.61-3.005-4.508-5.58-4.077-2.522.422-4.228 2.435-4.24 5.01-.006 1.133-.002 2.265 0 3.397 0 .093.007.185.011.281h9.89Zm-4.956 5.005a2.5 2.5 0 0 0-2.35 1.726 2.453 2.453 0 0 0 .88 2.758c.19.14.25.28.246.505-.012.8-.007 1.601-.003 2.401.001.154.009.316.05.463.161.578.735.964 1.303.892.635-.08 1.094-.555 1.106-1.189.017-.848.015-1.698.002-2.547-.004-.236.057-.384.255-.53a2.452 2.452 0 0 0 .866-2.762 2.489 2.489 0 0 0-2.355-1.717Z" />
  </svg>
);
export default PadlockClose;
