import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Line3Steps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 629 144"
    fill="none"
    {...props}
  >
    <path
      fill="#1E88E5"
      d="M559.01 143.05c-38.51 0-69.83-31.33-69.83-69.83v-2.7c0-28.46-23.16-51.62-51.62-51.62-28.46 0-51.62 23.15-51.62 51.62v2.02c0 38.51-31.33 69.83-69.83 69.83s-69.83-31.33-69.83-69.83v-2.7c0-28.46-23.16-51.62-51.62-51.62h-2.7c-28.46 0-51.62 23.15-51.62 51.62v2.7c0 38.51-31.33 69.83-69.83 69.83h-.67C31.33 142.37 0 111.04 0 72.54h18.22c0 28.46 23.15 51.62 51.62 51.62h.67c28.46 0 51.62-23.16 51.62-51.62v-2.7C122.13 31.33 153.45 0 191.96 0h2.7c38.51 0 69.83 31.33 69.83 69.83v2.7c0 28.46 23.16 51.62 51.62 51.62 28.46 0 51.62-23.16 51.62-51.62v-2.02C367.73 32 399.06.68 437.56.68s69.83 31.33 69.83 69.83v2.7c0 28.46 23.16 51.62 51.62 51.62 28.46 0 51.62-23.16 51.62-51.62h18.22c0 38.51-31.33 69.83-69.83 69.83l-.01.01Z"
    />
  </svg>
);
export default Line3Steps;
