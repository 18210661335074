import React, { useRef, useCallback, useMemo, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  LockIcon,
  MailIcon,
  UserCircleIcon,
  LinkedinIcon,
  GenderIcon,
} from '../../../assets/svg/SVGComponents';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { useAuth, User } from '../../../hooks/auth';
import { genderTexts, UserGender } from '../../../utils/enums';

import Input from '../../../components/Input';
import SimpleSelect from '../../../components/Selects/SimpleSelect';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ResetPasswordModal from '../../../modals/ResetPasswordModal';

import {
  MainContainer,
  RedefinePassword,
  Footer,
  genderSelectStyles,
} from './styles';
import IRequestError from '../../../dtos/IRequestError';

interface ProfileFormData {
  name: string;
  email: string;
  linkedin?: string;
  gender: UserGender;
  subjects?: string[];
}

const ProfileForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { handleModal } = useModal();
  const { addToast } = useToast();

  const { user, updateUser, isSessionExpired } = useAuth();

  const genderOptions = useMemo(() => {
    return Object.entries(genderTexts).map(keyValue => ({
      label: keyValue[1],
      value: keyValue[0],
    }));
  }, []);

  // useEffect(() => {
  //   api
  //     .get<SubjectsList>('/subjects')
  //     .then(response => {
  //       const subjects_list = response.data;

  //       const formattedSubjectsList = subjects_list.map(subjectName => ({
  //         label: subjectName,
  //         value: subjectName,
  //       }));

  //       setSubjectsOptions(formattedSubjectsList);
  //     })
  //     .catch(async (err: IRequestError) => {
  //       if (!(await isSessionExpired(err))) {
  //         addToast({
  //           type: 'error',
  //           expression: 'Ops!',
  //           description: t('toasts.errors.load_stats_fail'),
  //         });
  //       }
  //     });
  // }, [addToast, isSessionExpired, t]);

  const handleResetPasswordModal = useCallback(() => {
    handleModal(<ResetPasswordModal />);
  }, [handleModal]);

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      formRef.current?.setErrors({});
      setLoading(true);

      try {
        const profileSchema = Yup.object().shape({
          name: Yup.string().required(t('yup_errors.name_required')),
          email: Yup.string()
            .required(t('yup_errors.email_required'))
            .email(t('yup_errors.invalid_email')),
          linkedin: Yup.string().url(),
          gender: Yup.string()
            .oneOf(Object.values(UserGender), t('yup_errors.gender_not_found'))
            .required(t('yup_errors.gender_required')),
        });

        const validatedSchema = await profileSchema.validate(data, {
          abortEarly: false,
          stripUnknown: true,
        });

        const response = await api.put<User>(`/user-profile`, validatedSchema);

        setLoading(false);
        updateUser(response.data);

        addToast({
          type: 'success',
          expression: 'Ok!',
          description: t('toasts.success.changes_saved'),
        });
      } catch (err: IRequestError | unknown) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else if (!(await isSessionExpired(err as IRequestError))) {
          addToast({
            type: 'error',
            expression: 'Ops!',
            description: t('toasts.errors.updated_data_fail'),
          });
        }
      }
    },
    [updateUser, addToast, t, isSessionExpired],
  );

  return (
    <MainContainer>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: user.name,
          email: user.email,
          password: '******',
          linkedin: user.linkedin,
          gender: genderOptions.find(
            genderOption => genderOption.value === user.gender,
          ),
        }}
      >
        <header>
          <h3>{t('h3.registration_data')}</h3>
          <PrimaryButton type="submit" loading={loading}>
            {t('buttons.save_changes')}
          </PrimaryButton>
        </header>

        <h4>{t('labels.teacher_name')}</h4>
        <Input
          name="name"
          type="text"
          placeholder={t('placeholders.full_name')}
          icon={UserCircleIcon}
        />

        <h4>{t('labels.email')}</h4>
        <Input
          name="email"
          type="text"
          placeholder={t('placeholders.email')}
          icon={MailIcon}
          readOnly
          disabled
        />

        <RedefinePassword>
          <h4>{t('labels.password')}</h4>
          <button type="button" onClick={handleResetPasswordModal}>
            {t('buttons.reset_password')}
          </button>
        </RedefinePassword>
        <Input
          name="password"
          placeholder={t('placeholders.password')}
          type="password"
          icon={LockIcon}
          readOnly
          disabled
        />

        <h4>{t('labels.linkedin')}</h4>
        <Input
          name="linkedin"
          type="text"
          placeholder="https://linkedin.com/in/example/"
          icon={LinkedinIcon}
        />

        <h4>{t('labels.gender')}</h4>
        <SimpleSelect
          name="gender"
          placeholder={t('placeholders.gender')}
          options={genderOptions}
          styles={genderSelectStyles}
          iconLeft={GenderIcon}
        />

        <Footer>
          <PrimaryButton type="submit" loading={loading}>
            {t('buttons.save_changes')}
          </PrimaryButton>
        </Footer>
      </Form>
    </MainContainer>
  );
};

export default ProfileForm;
