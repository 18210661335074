import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="m23 0 5.388 16.584h17.437L31.718 26.833l5.389 16.583L23 33.167 8.893 43.417l5.388-16.584L.175 16.583h17.437L23 0Z" />
  </svg>
);
export default SvgComponent;
