import { lighten } from 'polished';
import styled from 'styled-components';

interface ProgressProps {
  progress: number;
}

interface IChapterProps {
  selected: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  width: 48.5vw;
`;

export const MainContentHeader = styled.header`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  > a {
    display: flex;
    align-items: center;
    margin-bottom: 0.89rem;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    line-height: 1.61rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'RedHataDisplay-Regular';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;

export const ListWrapper = styled.div`
  padding: 1.22rem;
`;

export const MainHeadContent = styled.div`
  border-bottom: 1px solid #d6d5d3;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    text-align: center;
    font-family: 'RedHatDisplay-Bold';
    line-heght: 2.2rem;
    margin-left: 1.94rem;
    color: #1e88e5;
  }
`;

export const InfoPlaylist = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 4.22rem;
  margin-top: 1rem;
  text-align: center;

  h2 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.67rem;
  }

  span {
    text-align: center;
    font-size: 1rem;
    margin-right: 2rem;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.56rem;
`;

export const ListContent = styled.ul`
  padding: 0.1rem;
`;

export const PlaylistsContent = styled.div`
  margin-left: 4rem;
  border-left: 1px solid #d6d5d3;
`;

export const ProgressList = styled.div`
  margin-left: 2.5rem;

  h2 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.22rem;
    margin-bottom: 3.11rem;
  }

  li {
    margin-bottom: 2.72rem;
    font-family: 'RedHatDisplay-Regular';
  }
`;

export const IconWrapperTrail = styled.div`
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const ChaptersLists = styled.div`
  margin-left: 2.5rem;

  h2 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.22rem;
    margin-bottom: 3.11rem;
  }
`;

export const ChapterItem = styled.li<IChapterProps>`
  margin-bottom: 1.72rem;
  padding: 0.8rem;
  font-family: 'RedHatDisplay-Regular';
  border-radius: 0.89rem;
  outline: 2px solid;
  outline-color: ${props => (props.selected ? '#4caf50' : '#fff')};

  > button {
    display: flex;
    flex-direction: row;
    width: 26.56vw;
  }

  opacity: ${props => (props.selected ? '1' : '0.8')};
  transition: opacity 0.3s;
  transition: outline 0.5s;

  &:hover {
    opacity: 1;
    outline: 2px solid;

    outline-color: ${props => (props.selected ? '#4caf50' : '#e2e2e2')};
  }
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  border: 1px solid #1e88e5;
  border-radius: 50%;
  background-color: #99bbdd;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 70%;
    object-fit: cover;
    object-position: center;
  }
`;

export const InfoWrapper = styled.div`
  margin-left: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const InfoBar = styled.div<ProgressProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.559rem;
  font-size: 0.89rem;

  > h3 {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
  }

  > span {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.125rem;
    color: ${props => (props.progress > 0 ? '#FF9800' : '#e2e2e2')};
  }
`;

export const Progress = styled.span`
  height: 0.33rem;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 5.56rem;
`;

export const Fill = styled.div<ProgressProps>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #ff9800;
  border-radius: inherit;
  text-align: right;
`;
