import styled from 'styled-components';
import toolsIcon from '../../assets/svg/icons/toolsIcon.svg';

export const Container = styled.div`
  font-size: 1rem;
  z-index: 2001;
  min-width: 56vw;
  width: 56vw;
  display: flex;
  flex-direction: column;

  > header {
    height: 4.2rem;
    padding-left: 2.22rem;
    border-bottom: 1px solid #4caf50;
    display: flex;
    align-items: center;
    > h1 {
      font-family: 'RedHatDisplay-Bold';
      font-size: 1.33rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h3 {
      text-align: center;
      width: 32vw;
      font-size: 1.22rem;
      line-height: 2rem;
      margin-top: 1rem;
      font-family: 'RedHatDisplay-extraBold';
    }
  }

  > form {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      margin-top: 0.89rem;
      margin-bottom: 1.5rem;
      cursor: text;
      width: 32vw;

      > span {
        font-size: 0.88rem;
        line-height: 2rem;
        margin-bottom: 0.88rem;
      }
    }
  }
`;

export const Logo = styled.div`
  margin-top: 1.67rem;
  border: 2px solid #ff9800;
  height: 6.33rem;
  width: 6.33rem;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > .secondary-button {
    font-family: 'RedHatDisplay-ExtraBold';

    text-align: center !important;
    font-size: 1rem;
    color: #1e88e5;
    line-height: 1.28rem;
    padding: 0.77rem 0.72rem 0.77rem 0.88rem;
    border: 1px solid #1e88e5;
    border-radius: 0.55rem;
    height: 2.83rem;
    width: 12.16rem;
  }

  > .primary-button {
    height: 2.83rem;
    width: 12.16rem;
    margin-left: 2rem;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0px 3px 15px rgba(255, 152, 0, 0.3);
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
`;

export const AlertMessage = styled.div`
  width: 100%;
  border: 1px solid #ff9800;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 1rem;
  }

  p {
    width: 20rem;
    margin-left: 2.2rem;
    font-family: 'RedHatDisplay-Regular';
    font-size: 0.86rem;
    color: #000;
  }
`;
