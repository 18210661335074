import { animated } from 'react-spring';
import styled from 'styled-components';

interface IContainerProps {
  positiontop?: number;
  positionleft?: number;
}

export const Container = styled(animated.div)<IContainerProps>`
  background: #e9f3fd;
  width: 29rem;
  height: 25rem;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
  border-radius: 0.89rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 1000;
  top: ${({ positiontop }) => positiontop}%;
  left: ${({ positionleft }) => positionleft}%;
  transform: translateX(-50%);
`;

export const BarTimer = styled.div`
  background: #e0e0e0;
  width: 100%;
  height: 0.6rem;
  overflow: hidden;
  border-radius: 0.89rem 0.89rem 0 0;
`;

export const BarFill = styled.div`
  height: 100%;
  background: #1e88e5;
  border-radius: 0.89rem 0.89rem 0 0;
  transition: width 0.1s linear;
`;

export const Header = styled.div`
  padding: 0 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  h1 {
    color: #1e88e5;
    margin-left: 0.5rem;
    font-family: 'RedHatDisplay-Bold';
    line-heght: 2.2rem;
    font-size: 1.67rem;
  }
`;

export const CloseButton = styled.button`
  align-items: center;
  justify-content: center;
  margin-left: auto;

  > svg {
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
`;

export const RateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-bottom: 0.5rem;
  }
`;

export const StarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.89rem;
  margin-top: 0.89rem;

  h3 {
    margin-bottom: 0.5rem;
  }

  textarea {
    border: 2px solid #1e88e5;
    border-radius: 0.89rem;
    width: 90%;
    height: 7.5rem;
    padding: 0.5rem; /* Adiciona espaço interno para evitar que o texto encoste nas bordas */
    box-sizing: border-box; /* Garante que o padding não afete a largura total */
    resize: none; /* Opcional: Remove a capacidade de redimensionar */
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > .primary-button {
    height: 2.83rem;
    width: 8.89rem;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0px 3px 15px rgba(255, 152, 0, 0.3);
    margin-right: 2.72rem;
  }

  > .secondary-button {
    display: flex;
    width: 8.89rem;
    height: 2.89rem;
    align-items: center;
    justify-content: center;
    font-family: 'MarkPro';
    font-size: 1rem;
    color: #323232;
    line-height: 1.28rem;
    border-radius: 0.55rem;
    vertical-align: center;
  }
`;
