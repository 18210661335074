import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SimulatorViewer = styled.iframe`
  border-style: none;
  width: 100%;
  height: 70vh;
`;
