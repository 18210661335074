import styled, { css } from 'styled-components';

interface ProgressProps {
  progress: number;
}

interface TimelineItemProps {
  watching: boolean;
  disabled?: boolean;
}

interface PlaylistProps {
  background: string;
}

interface PlaylistCardProps {
  disabled?: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  width: 48.5vw;
  margin-right: 4.9rem;
`;

export const MainHeadContent = styled.div`
  border-bottom: 1px solid #d6d5d3;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  h1 {
    text-align: center;
    font-family: 'RedHatDisplay-Bold';
    line-heght: 2.2rem;
    margin-left: 1.94rem;
    color: #1e88e5;
  }
`;

export const InfoPlaylist = styled.div`
  display: flex;
  justify-content: ;
  align-items: center;
  justify-content: space-between;
  margin-left: 4.22rem;
  margin-top: 1.89rem;
  text-align: center;

  h2 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.67rem;
  }

  span {
    text-align: center;
    font-size: 1rem;
    margin-right: 2rem;
  }
`;

export const ListWrapper = styled.div`
  padding: 1.22rem;
`;

export const TimelineItem = styled.li<TimelineItemProps>`
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 1.1rem;

  > a {
    width: fit-content;
    height: fit-content;
  }

  &:before {
    content: '';
    background-color: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    position: absolute;
    bottom: 0px;
    top: 52%;
    left: 0.9%;
    width: 1px;
    height: 80%;
  }

  &:after {
    content: '';
    width: 0.833vw;
    height: 0.833vw;
    top: calc(50% - 0.89rem);
    border-radius: 50%;
    background: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    box-shadow: ${props =>
      props.watching ? ' 0px 0px 0px 0.5rem rgba(236, 119, 93, 0.1)' : '0'};
    position: absolute;
    left: 0;
    top: 25%;
  }

  &:nth-last-child(2):before {
    background: #e2e2e2;
  }

  &:last-child:before {
    height: 0;
  }
`;

const playlisCardStyle = {
  disabled: css``,
  enabled: css`
    box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  `,
};

export const PlaylistCard = styled.div<PlaylistCardProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 44.7rem;
  height: 7.5rem;
  padding: 1.22rem 1.22rem 0 1.22rem;
  margin-left: 2.5rem;
  border-radius: 1.1rem;
  display: flex;
  flex-direction: center;
  background: ${props => (props.disabled ? '#f5f5f5' : '#fff')};
  /* box-shadow: 0px 3px 10px #00000029; */
  position: relative;
  transition: all 0.3s;

  &:hover {
    ${props =>
      props.disabled ? playlisCardStyle.disabled : playlisCardStyle.enabled}
  }
`;

export const VideoImage = styled.div<PlaylistProps>`
  border-radius: 1.1rem 0 0 1.1rem;
  width: 19.167rem;
  height: 12.167rem;
  background: ${props => `url(${props.background}) no-repeat  center`};
  background-size: cover;
`;

export const IconContent = styled.div`
  width: 2.33rem;
  height: 2.33rem;
  border-radius: 50%;
  background-color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.56rem;
`;

export const VideoInfos = styled.div`
  margin-left: 2.22rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.38rem;
    font-family: 'RedHatDisplay-Bold';
    line-height: 1.38rem;
    color: #1e88e5;
    margin-bottom: 0.83rem;
  }

  > span {
    font-size: 0.89rem;
    margin-bottom: 0.83rem;
  }

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 0.56rem;
      font-family: 'RedHatDisplay-Bold';
      font-size: 0.83rem;
    }
  }

  /* > div {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: 'MarkPro-Heavy';
      font-size: 0.67rem;
    }
  } */
`;

export const ListContent = styled.ul`
  padding: 1.22rem;
`;

export const PlaylistsContent = styled.div`
  border-left: 1px solid #d6d5d3;
`;

export const VideoLists = styled.div`
  margin-left: 2.5rem;

  h2 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.22rem;
    margin-bottom: 3.11rem;
  }

  li {
    margin-bottom: 2.72rem;
    font-family: 'RedHatDisplay-Regular';
    display: flex;
    flex-direction: column;
    width: 26.56vw;

    > a {
      display: flex;
      flex-direction: column;
      width: 26.56vw;
    }
  }
`;

export const InfoBar = styled.div<ProgressProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.559rem;
  font-size: 0.89rem;

  > h3 {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
  }

  > span {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.125rem;
    color: ${props => (props.progress > 0 ? '#FF9800' : '#e2e2e2')};
  }
`;

export const Progress = styled.span`
  height: 0.33rem;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 5.56rem;
`;

export const Fill = styled.div<ProgressProps>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #ff9800;
  border-radius: inherit;
  text-align: right;
`;

export const ShimmerEffect = styled.div`
  background-image: linear-gradient(
    -90deg,
    #f5f5f5 0%,
    #e2e2e2 50%,
    #f5f5f5 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &.white {
    background-image: linear-gradient(
      -90deg,
      #f5f5f5 0%,
      #e2e2e2 50%,
      #f5f5f5 100%
    );
  }
`;
