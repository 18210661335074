import styled, { keyframes } from 'styled-components';

const dotFlashing = keyframes`
  0% {
      background-color: #fff;
    }
  50%,
  100% {
      background-color: #e2e2e2;
    }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin: 0 1rem; */

  /**
  * ==============================================
  * Dot Flashing
  * ==============================================
  */
  .dot-flashing {
    position: relative;
    width: 0.67rem;
    height: 0.67rem;
    border-radius: 0.33rem;
    background-color: #fff;
    color: #fff;
    animation: ${dotFlashing} 0.6s infinite linear alternate;
    animation-delay: 0.3s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -1rem;
    width: 0.67rem;
    height: 0.67rem;
    border-radius: 0.33rem;
    background-color: #fff;
    color: #fff;
    animation: ${dotFlashing} 0.6s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 1rem;
    width: 0.67rem;
    height: 0.67rem;
    border-radius: 0.33rem;
    background-color: #fff;
    color: #fff;
    animation: ${dotFlashing} 0.6s infinite alternate;
    animation-delay: 0.6s;
  }
`;
