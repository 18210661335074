import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #1e88e5;

  > h1 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.55rem;
    line-height: 3rem;
    margin-top: 1.16rem;
    color: #fff;
  }
`;
