import React from 'react';

const SvgLinkedin: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="linkedin_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#linkedin_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.5 3h9A4.5 4.5 0 0121 7.5v9a4.5 4.5 0 01-4.5 4.5h-9A4.5 4.5 0 013 16.5v-9A4.5 4.5 0 017.5 3zM8.119 11.1v5.4" />
        <path d="M11.719 16.5v-3.15a2.25 2.25 0 114.5 0v3.15M8.118 7.838a.225.225 0 10.226.225.225.225 0 00-.226-.225" />
      </g>
    </svg>
  );
};

export default SvgLinkedin;
