import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: var(--sidebar-width);
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #1e88e5;
  padding: 1.8vw 0 0;
  font-size: 1rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;

  > a > svg.sidebar-y {
    height: 1.7em;
    transition: fill 0.3s;

    &:hover {
      fill: rgba(255, 152, 0, 0.8);
    }
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 100%;
`;

export const NavLinkElm = styled(NavLink)`
  height: 11.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  color: rgb(172, 173, 185);
  cursor: pointer;

  > span {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1em;
    line-height: 2em;
    vertical-align: bottom;
    position: relative;
  }

  &:hover {
    > svg {
      stroke: #ff9800;
      transition: 0.3s;
    }

    span {
      color: #fff;
      transition: 0.3s;
    }
  }

  &.navlink-active {
    cursor: pointer;
    background: #167bd3;

    > svg {
      stroke: #ff9800;
    }

    span {
      color: #fff;
    }
  }
`;

export const ExternalLink = styled.a`
  height: 11.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  color: rgb(172, 173, 185);
  cursor: pointer;

  > span {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1em;
    line-height: 2em;
    vertical-align: bottom;
    position: relative;
  }

  &:hover {
    > svg {
      stroke: #ff9800;
      transition: 0.3s;
    }

    span {
      color: #fff;
      transition: 0.3s;
    }
  }
`;
