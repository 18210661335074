import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const CommentsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 30"
    fill="none"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.091 20.955A10 10 0 0 1 1.083 9.697C1.72 4.645 6.285 1 11.378 1H21c5.625 0 10.165 4.645 9.995 10.308C30.83 16.782 26.055 21 20.58 21h-4.267c-1.31 1.308-3.182 3.182-4.802 4.802-.525.525-1.42.151-1.42-.592v-4.255Z"
    />
    <path
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M6 9h19M8 11.5h15M11 14h9"
    />
  </svg>
);
export default CommentsIcon;
