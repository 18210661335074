import styled, { css } from 'styled-components';

interface PlaylistCardProps {
  disabled?: boolean;
}

interface TimelineItemProps {
  watching: boolean;
}

export const Wrapper = styled.ul`
  padding: 0.1rem;
`;

const playlisCardStyle = {
  disabled: css``,
  enabled: css`
    box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  `,
};

export const IconContent = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 2.9px solid #1e88e5;
  background-color: #fffefc;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 70%;
    object-fit: cover;
    object-position: center;
  }
`;

export const TimelineItem = styled.li<TimelineItemProps>`
  position: relative;
  margin-bottom: 1.5rem;
  padding-left: 1.1rem;

  > a {
    width: fit-content;
    height: fit-content;
  }

  &:before {
    content: '';
    background-color: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    position: absolute;
    bottom: 0px;
    top: 70%;
    left: 0.9%;
    width: 1px;
    height: 80%;
  }

  &:after {
    content: '';
    width: 0.833vw;
    height: 0.833vw;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    box-shadow: ${props =>
      props.watching ? ' 0px 0px 0px 0.5rem rgba(236, 119, 93, 0.1)' : '0'};
    position: absolute;
    left: 0;
  }

  &:nth-last-child(2):before {
    background: #e2e2e2;
  }

  &:last-child:before {
    height: 0;
  }
`;

export const PlaylistCard = styled.div<PlaylistCardProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 90%;
  height: 7.5rem;
  padding-left: 1.22rem;
  margin-left: 2.5rem;
  border-radius: 1.1rem;
  display: flex;
  align-items: center;
  flex-direction: center;
  background: ${props => (props.disabled ? '#f5f5f5' : '#fff')};
  /* box-shadow: 0px 3px 10px #00000029; */
  position: relative;
  transition: all 0.3s;

  &:hover {
    ${props =>
      props.disabled ? playlisCardStyle.disabled : playlisCardStyle.enabled}
  }
`;

export const VideoInfos = styled.div`
  margin-left: 2.22rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.38rem;
    font-family: 'RedHatDisplay-Bold';
    line-height: 1.38rem;
    color: #1e88e5;
    margin-bottom: 0.83rem;
  }

  > span {
    font-size: 0.89rem;
    margin-bottom: 0.83rem;
  }

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 0.56rem;
      font-family: 'RedHatDisplay-Bold';
      font-size: 0.83rem;
    }
  }

  /* > div {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: 'MarkPro-Heavy';
      font-size: 0.67rem;
    }
  } */
`;
