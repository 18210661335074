import React from 'react';

const SvgSearch: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...props}>
      <defs>
        <clipPath id="search_svg__a">
          <path fill="none" d="M0 .448h34v34H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#search_svg__a)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(0 -.448)"
      >
        <circle
          cx={8.495}
          cy={8.495}
          r={8.495}
          transform="translate(11.766 5.454)"
        />
        <path d="M14.308 20.009l-8.316 8.433" />
      </g>
    </svg>
  );
};

export default SvgSearch;
