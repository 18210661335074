import styled from 'styled-components';
import DotIcon from '../../../../assets/svg/icons/dot.svg';

export const Container = styled.div`
  width: 100%;
`;

export const TextContent = styled.div`
  display: flex;
  margin-top: 3.2rem;
  margin-bottom: 1rem;

  > div {
    max-width: 100%;

    > .markdown-container {
      max-width: 100%;

      h1 {
        font-family: 'MarkPro-Heavy';
        font-size: 1.56rem;
        margin-bottom: 2rem;
      }

      h2 {
        font-family: 'MarkPro-Heavy';
        font-size: 1.44rem;
        margin-bottom: 1.8rem;
      }

      h3 {
        font-family: 'MarkPro-Heavy';
        font-size: 1.33rem;
        margin-bottom: 1.8rem;
      }

      p {
        font-size: 1.11rem;
        line-height: 1.78rem;
        margin-bottom: 1.5rem;
      }

      strong {
        font-family: 'MarkPro-Heavy';
      }

      code {
        padding: 0.05rem 0.2rem;
        border-radius: 0.2rem;
        line-height: 1.6em;
      }

      a {
        font-family: 'MarkPro-Bold';

        &:hover {
          text-decoration: underline;
        }
      }

      img {
        display: flex;
        max-width: 100%;
        border-radius: 1.11rem;
        margin: 1rem auto;
      }

      ul {
        margin-bottom: 1.5rem;
        padding-left: 0.5rem;
      }

      li {
        background: url(${DotIcon}) no-repeat left 5px;
        padding-left: 1.3rem;
        margin-bottom: 1.3rem;
      }
    }
  }

  aside {
    width: 22.11rem;
    height: 15rem;
    background: #fff;
    border: 1px solid #e2e2e2;
    margin-left: 3.11rem;
    padding: 1.97rem;
    border-radius: 0.56rem;

    h2 {
      font-family: 'MarkPro-Heavy';
      font-size: 1.22rem;
      line-height: 1.78rem;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1.5rem;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: disc;
        list-style-position: inherit;
        font-family: 'MarkPro-Bold';
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
`;

export const ColoredSpan = styled.span`
  background-color: ${props => props.color || 'transparent'};
  padding: 0.2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > .primary-button {
    height: 2.83rem;
    width: 12.16rem;
    margin-left: 2rem;
    font-family: 'RedHatDisplay-Bold';
    font-size: 1rem;
    border-radius: 0.6rem;
    box-shadow: 0px 3px 15px rgba(255, 152, 0, 0.3);
  }
`;
