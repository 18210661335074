import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const BlogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 27"
    fill="none"
    {...props}
  >
    <g strokeLinecap="round" strokeLinejoin="round">
      <path d="M33.006 11.972c0 .672-.184 1.329-.528 1.888a3.204 3.204 0 0 1-1.407 1.251 2.91 2.91 0 0 1-1.812.194 3.068 3.068 0 0 1-1.605-.93 3.493 3.493 0 0 1-.858-1.74 3.661 3.661 0 0 1 .179-1.962c.237-.621.639-1.152 1.155-1.525a2.966 2.966 0 0 1 1.742-.572c.83 0 1.628.358 2.216.995.587.637.918 1.5.918 2.4v0ZM23.288 7.201a5.545 5.545 0 0 1-.813 2.906 4.932 4.932 0 0 1-2.166 1.926c-.882.395-1.852.499-2.788.297a4.723 4.723 0 0 1-2.471-1.43 5.378 5.378 0 0 1-1.321-2.678 5.636 5.636 0 0 1 .275-3.022 5.162 5.162 0 0 1 1.777-2.347 4.566 4.566 0 0 1 2.681-.88c1.28 0 2.508.55 3.413 1.53.905.981 1.413 2.312 1.413 3.698v0ZM3.915 11.972c0 .672.184 1.329.528 1.888.344.558.834.994 1.407 1.251a2.91 2.91 0 0 0 1.811.194 3.067 3.067 0 0 0 1.606-.93c.438-.475.737-1.08.858-1.74a3.662 3.662 0 0 0-.18-1.962 3.353 3.353 0 0 0-1.154-1.525 2.966 2.966 0 0 0-1.742-.572c-.831 0-1.628.358-2.216.995a3.545 3.545 0 0 0-.918 2.4v0ZM35.51 25.485v-1.842c0-.552-.1-1.098-.294-1.608a4.24 4.24 0 0 0-.84-1.363c-.36-.39-.787-.7-1.257-.91a3.62 3.62 0 0 0-1.484-.321h-1.24M1.41 25.485v-1.842c0-.552.1-1.098.295-1.608.195-.51.48-.973.84-1.363s.787-.7 1.257-.91a3.62 3.62 0 0 1 1.483-.321h1.242M26.735 25.486v-2.69c0-.773-.14-1.538-.413-2.252a5.94 5.94 0 0 0-1.177-1.909 5.422 5.422 0 0 0-1.762-1.275 5.067 5.067 0 0 0-2.078-.447h-5.697c-1.44 0-2.82.62-3.84 1.723-1.017 1.103-1.59 2.6-1.59 4.16v2.69" />
    </g>
  </svg>
);
export default BlogIcon;
