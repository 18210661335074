import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const MicroBitLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M6.857 5.143A6.865 6.865 0 0 0 0 12a6.864 6.864 0 0 0 6.857 6.857h10.287A6.863 6.863 0 0 0 24 12a6.864 6.864 0 0 0-6.856-6.857zm0 2.744h10.287A4.117 4.117 0 0 1 21.257 12a4.119 4.119 0 0 1-4.113 4.116H6.857A4.12 4.12 0 0 1 2.743 12a4.118 4.118 0 0 1 4.114-4.113zm10.168 2.729a1.385 1.385 0 1 0 .003 2.77 1.385 1.385 0 0 0-.003-2.77zm-10.166 0a1.385 1.385 0 1 0-.003 2.771 1.385 1.385 0 0 0 .003-2.77Z" />
  </svg>
);

export default MicroBitLogo;
