import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { useModal } from '../../hooks/modal';

import { Container, TextContent, Logo } from './styles';
import { FuzzyLogoNewOrange } from '../../assets/svg/SVGComponents';

interface PrivacypolicyTextProps {
  text: string;
}

const PrivacyPolicyModal: React.FC<PrivacypolicyTextProps> = text => {
  const { handleModal } = useModal();

  const privacy = text;
  // const loadDescriptionMarkdown = useCallback(async () => {
  //   try {
  //     const response = await fetch(t('privacy-policy.privacy'));

  //     const text = await response.text();

  //     setPrivacyText(text);
  //   } catch (error) {
  //     addToast({
  //       type: 'error',
  //       expression: 'Ops!',
  //       description: t('toasts.errors.load_description_fail'),
  //     });
  //   }
  // }, [t]);

  // useEffect(() => {
  //   // Attach the listeners on component mount.
  //   if (i18next.language === 'en') {
  //     loadDescriptionMarkdown();
  //   }

  //   if (i18next.language === 'pt-BR') {
  //     loadDescriptionMarkdown();
  //   }
  //   setPrivacyText(t('privacy-policy.privacy'));
  // }, [t]);

  const handleClose = useCallback(() => {
    handleModal(null);
  }, [handleModal]);

  return (
    <Container>
      <Logo>
        <FuzzyLogoNewOrange />
      </Logo>
      <TextContent>
        <div>
          <ReactMarkdown plugins={[gfm]} className="markdown-container">
            {privacy.text}
          </ReactMarkdown>
        </div>
      </TextContent>

      <PrimaryButton
        onClick={() => {
          handleClose();
        }}
      >
        Voltar
      </PrimaryButton>
    </Container>
  );
};
export default PrivacyPolicyModal;
