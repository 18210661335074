import React from 'react';

const SvgCheckedCircle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="checkCircled_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#checkCircled_svg__a)"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 21h0a9 9 0 119-9 9 9 0 01-9 9z" />
        <path d="M16 10l-5 5-3-3" />
      </g>
    </svg>
  );
};

export default SvgCheckedCircle;
