import React from 'react';

const SvgProjects: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" {...props}>
      <defs>
        <clipPath id="projects_svg__a">
          <path fill="none" d="M0 0h38v38H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#projects_svg__a)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M30.083 10.99H19.838a1.588 1.588 0 01-1.309-.692l-2.224-3.271a1.584 1.584 0 00-1.309-.693H7.917A3.167 3.167 0 004.75 9.502v19a3.169 3.169 0 003.167 3.167h22.166a3.169 3.169 0 003.167-3.167V14.157a3.168 3.168 0 00-3.167-3.167z" />
        <rect
          width={3.695}
          height={3.694}
          rx={1.847}
          transform="translate(17.153 15.833)"
        />
        <rect
          width={3.695}
          height={3.695}
          rx={1.848}
          transform="translate(12.675 23.221)"
        />
        <rect
          width={3.694}
          height={3.695}
          rx={1.847}
          transform="translate(21.63 23.221)"
        />
        <path d="M21.631 24.612h-5.26M15.503 23.232l2.484-3.722M20.155 19.481l2.381 3.745" />
      </g>
    </svg>
  );
};

export default SvgProjects;
