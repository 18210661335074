import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BarFill,
  BarTimer,
  ButtonContainer,
  CloseButton,
  CommentContainer,
  Container,
  Header,
  RateContainer,
  StarContainer,
  Title,
} from './styles';
import {
  CrossIcon,
  LogoRobotIcon,
  StarIcon,
} from '../../../assets/svg/SVGComponents';
import PrimaryButton from '../../Buttons/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton';
import {
  IFeedbacksProps,
  IUpdateFeedbackProps,
} from '../../../dtos/IFeedbacksProps';
import { useFeedback } from '../../../hooks/feedback';
import { IPopoverComponentProps } from '../../../dtos/IPopoverComponentsProps';

const Popover: React.FC<IPopoverComponentProps> = ({
  positiontop,
  positionleft,
  style,
  closePopover,
  openPopover,
  setTimer = true,
}) => {
  const [rating, setRating] = useState(0);
  const [selectRating, setSelectRating] = useState(0);
  const [commentText, setCommentText] = useState('');
  const [progress, setProgress] = useState(0);
  const [timerStopped, setTimerStopped] = useState(false);
  const { feedbacks, updateFeedback } = useFeedback();
  const [isTimeout, setIsTimeout] = useState(false);
  const feedbackSource = 'large-popover';

  const memoFeedback = useMemo(() => {
    if (feedbacks) {
      setRating(feedbacks.rating || 0);
      setSelectRating(feedbacks.rating || 0);
      setCommentText(feedbacks.comment || '');
    }

    return feedbacks;
  }, [feedbacks, setTimer]);

  useEffect(() => {
    const duration = 3000;
    const intervalTime = 50;

    if (openPopover && setTimer) {
      const timer = setTimeout(() => {
        if (!timerStopped) {
          closePopover();
          setProgress(0);
          setIsTimeout(true);
        }
      }, duration);

      const interval = setInterval(() => {
        setProgress(prevProgress => {
          if (!timerStopped) {
            const nextProgress = prevProgress + (intervalTime / duration) * 100;
            return nextProgress >= 100 ? 100 : nextProgress;
          }

          return 100;
        });
      }, intervalTime);

      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }

    return undefined;
  }, [closePopover, openPopover, timerStopped]);

  const handleClick = useCallback((starIndex: number) => {
    setRating(starIndex);
  }, []);

  const handleStopTimer = (): void => {
    setTimerStopped(true); // Interrompe o temporizador
  };

  // Todos os dados que foram atualizados vai ser alterados aqui
  const changeData = useCallback(() => {
    const updateFeedbackData: IUpdateFeedbackProps = {
      rating,
      feedback_source: feedbackSource,
      comment: commentText,
    };

    updateFeedback(updateFeedbackData);
  }, [commentText, rating, updateFeedback]);

  useEffect(() => {
    if (isTimeout) {
      if (rating !== feedbacks?.rating) {
        changeData();
        setTimerStopped(false);
      }
    }
  }, [changeData, feedbacks?.rating, isTimeout, rating]);

  const handleSendFeedback = useCallback(() => {
    changeData();
    setTimerStopped(false);
    setProgress(0);
    closePopover();
  }, [changeData, closePopover]);

  const handleClosePopover = useCallback(() => {
    closePopover();
    setTimerStopped(false);
  }, [closePopover]);

  return (
    <Container
      style={style}
      positiontop={positiontop}
      positionleft={positionleft}
    >
      <BarTimer>
        <BarFill style={{ width: `${100 - progress}%` }} />
      </BarTimer>
      <Header>
        <Title>
          <LogoRobotIcon width="2.21rem" fill="#1e88e5" />
          <h1>Feedback</h1>
        </Title>
        <CloseButton type="button" onClick={() => handleClosePopover()}>
          <CrossIcon width="1.21rem" strokeWidth={1.5} stroke="#000" />
        </CloseButton>
      </Header>

      <RateContainer>
        <h3>Como você avalia o conteúdo?</h3>
        <StarContainer>
          {Array.from({ length: 5 }, (_, index) => (
            <StarIcon
              key={index}
              width="2.67rem"
              onClick={() => handleClick(index + 1)}
              fill={index < selectRating ? '#ff9800' : '#e2e2e2'}
              onMouseEnter={() => setSelectRating(index + 1)}
              onMouseLeave={() => setSelectRating(rating)}
            />
          ))}
        </StarContainer>
      </RateContainer>

      <CommentContainer>
        <h3>Ficaremos felizes com o seu comentário</h3>
        <textarea
          placeholder="Escreva seu comentário"
          onClick={handleStopTimer}
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
        />
      </CommentContainer>

      <ButtonContainer>
        <PrimaryButton onClick={() => handleSendFeedback()}>
          Enviar
        </PrimaryButton>
        <SecondaryButton onClick={() => handleClosePopover()}>
          Cancelar
        </SecondaryButton>
      </ButtonContainer>
    </Container>
  );
};

export default Popover;
