import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimulationIcon } from '../../assets/svg/SVGComponents';

import { Container } from './styles';

const Loading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SimulationIcon width="4.55rem" strokeWidth={1.5} stroke="#FF9800" />
      <h1>{t('splash_screen.h1.loading', { defaultValue: 'Loading...' })}</h1>
    </Container>
  );
};

export default Loading;
