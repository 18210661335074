import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import {
  SimulationIcon,
  HelpIcon,
  VideosIcon,
  HomeIcon,
  ProjectsIcon,
  ToolsIcon,
  StoreIcon,
} from '../../assets/svg/SVGComponents';

import {
  Container,
  Content,
  NavContainer,
  NavLinkElm,
  ExternalLink,
} from './styles';
import LogoFaceRobot from '../../assets/svg/SVGComponents/LogoFaceRobot';
import BlogIcon from '../../assets/svg/SVGComponents/BlogIcon';

const LeftSideBar: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <Link to="/home">
          <LogoFaceRobot fill="#fff " width="2.89rem" />
        </Link>

        <NavContainer>
          <NavLinkElm
            to="/home"
            className={
              pathname.startsWith('/home') || pathname.startsWith('/profile')
                ? 'navlink-active'
                : ''
            }
          >
            <HomeIcon
              strokeWidth={2}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.home')}</span>
          </NavLinkElm>

          <NavLinkElm
            to="/hardwares"
            className={
              pathname.startsWith('/hardwares') ? 'navlink-active' : ''
              // eslint-disable-next-line react/jsx-curly-newline
            }
          >
            <ToolsIcon
              strokeWidth={2.5}
              stroke="rgb(172, 173, 185)"
              width="1.8em"
            />
            <span>{t('left_sidebar.hardware')}</span>
          </NavLinkElm>

          <NavLinkElm
            to="/videos/progress"
            className={
              pathname.startsWith('/videos') ? 'navlink-active' : ''
              // eslint-disable-next-line react/jsx-curly-newline
            }
          >
            <VideosIcon
              strokeWidth={2}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.videos')}</span>
          </NavLinkElm>

          <NavLinkElm
            to="/projects/progress"
            className={
              pathname.startsWith('/projects') || pathname.startsWith('/trails')
                ? 'navlink-active'
                : ''
              // eslint-disable-next-line react/jsx-curly-newline
            }
            end
          >
            <ProjectsIcon
              strokeWidth={2}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.projects')}</span>
          </NavLinkElm>

          <ExternalLink
            href="https://loja.fuzzymakers.com.br/"
            rel="noreferrer"
            target="_blank"
          >
            <StoreIcon
              strokeWidth={2}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.store')}</span>
          </ExternalLink>
          <ExternalLink
            href="https://fuzzymakers.com.br/blog/"
            rel="noreferrer"
            target="_blank"
          >
            <BlogIcon
              strokeWidth={2}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.blog')}</span>
          </ExternalLink>

          <ExternalLink
            href="https://play.fuzzymakers.com"
            rel="noreferrer"
            target="_blank"
          >
            <SimulationIcon
              strokeWidth={1.5}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.simulator')}</span>
          </ExternalLink>

          <ExternalLink
            href="https://wa.me/message/ICA65FNRIPESD1"
            rel="noreferrer"
            target="_blank"
          >
            <HelpIcon
              strokeWidth={2}
              stroke="rgb(172, 173, 185)"
              width="1.9em"
            />
            <span>{t('left_sidebar.help')}</span>
          </ExternalLink>
        </NavContainer>
      </Content>
    </Container>
  );
};
export default memo(LeftSideBar);
