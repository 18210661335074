import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2500;
  background: rgba(30, 136, 229, 0.7);
  color: #fffefc;
`;

export const Content = styled.div`
  max-height: calc(100vh - 2rem);
  background: #fffefc;
  color: #323232;
  border: 3px solid #4caf50;
  display: flex;
  flex-direction: column;
  border-radius: 1.11rem;
`;
