import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path d="m15.944 16.667 2.167-2.167-2.167-2.167M13.056 12.333 10.889 14.5l2.167 2.167M5.167 16A2.167 2.167 0 0 1 3 13.833V5.167C3 3.97 3.97 3 5.167 3h8.667C15.03 3 16 3.97 16 5.167" />
      <path d="M18.833 21h-8.667A2.167 2.167 0 0 1 8 18.833v-8.667C8 8.97 8.97 8 10.167 8h8.667C20.03 8 21 8.97 21 10.167v8.667C21 20.03 20.03 21 18.833 21Z" />
    </g>
  </svg>
);
export default SvgComponent;
