import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IconContent,
  PlaylistCard,
  TimelineItem,
  VideoInfos,
  Wrapper,
} from './styles';
import { ClockIcon } from '../../assets/svg/SVGComponents';
import IActivityProps from '../../dtos/IActivitiesProps';
import IChapterProps from '../../dtos/IChapterProps';
import ITrailProps from '../../dtos/ITrailsProps';

interface IListActivitiesProps {
  trails: ITrailProps;
  chapters: IChapterProps;
}

// import { Container } from './styles';

const ListContentChapter: React.FC<IListActivitiesProps> = ({
  trails: TrailsData,
  chapters: chaptersData,
}) => {
  const { t } = useTranslation();
  const handleActiveLesson = useCallback((activityItem: IActivityProps) => {
    if (activityItem.watched) {
      return true;
    }
    return false;
  }, []);

  const handleLinkTutorial = useCallback(
    (chapterItem: IChapterProps): string => {
      let link = '';
      if (chapterItem.type === 'activity') {
        link = `/projects/${TrailsData.slug}/${chapterItem.id}/activity/${chapterItem.activities[0].slug}`;
      }
      if (chapterItem.type === 'lesson') {
        link = `/projects/${TrailsData.slug}/${chapterItem.id}/activity/${chapterItem.activities[0].slug}`;
      }

      return link;
    },
    [TrailsData.slug],
  );
  return (
    <Wrapper>
      {TrailsData &&
        TrailsData.type === 'chapter' &&
        TrailsData.chapters.map(chapter => (
          <TimelineItem
            key={chapter.id}
            watching={handleActiveLesson(chapter.activities[0])}
          >
            <Link to={handleLinkTutorial(chapter)}>
              <PlaylistCard disabled={false}>
                <IconContent>
                  <img src={chapter.icon_url} alt="" />
                </IconContent>
                <VideoInfos>
                  <h3>{t(`activities.${chapter.activities[0].slug}.title`)}</h3>

                  <span>
                    {t(
                      `activities.${chapter.activities[0].slug}.short_description`,
                    )}
                  </span>
                  <div>
                    <ClockIcon width="1.2rem" fill="none" stroke="#1E88E5" />
                    <span>{`${chapter.activities[0].duration} min`}</span>
                  </div>
                </VideoInfos>
              </PlaylistCard>
            </Link>
          </TimelineItem>
        ))}
    </Wrapper>
  );
};

export default ListContentChapter;
