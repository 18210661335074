import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  font-size: 1rem;
  overflow: hidden;
`;

export const ImageArea = styled.main`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: 101vh;
    object-fit: cover;
    object-position: center;
  }
`;

export const TipBackground = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 3.16rem;
  right: 3.94rem;
  width: 22.94rem;
  height: 12.83rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40vw;

  form {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 3.88rem 6.16rem 0 6.16rem;
    align-items: stretch;
    text-align: flex-start;

    > svg {
      margin: 0 0 8%;
    }

    > h1 {
      font-family: 'RedHatDisplay-Bold';
      font-size: 1.39rem;
    }

    > span {
      display: flex;
      justify-content: flex-start;
      font-family: 'RedHatDisplay-Regular';
      font-size: 0.88rem;
      text-align: left;
      margin-top: 6%;
      margin-bottom: 1.5%;
    }

    > .primary-button {
      margin-top: 2rem;
      margin-bottom: 1.44rem;
      font-family: 'RedHatDisplay-Bold';
      font-size: 1.33rem;
      height: 3.33rem;
      border-radius: 0.55rem;
      box-shadow: 0px 3px 15px rgba(236, 119, 93, 0.3);
    }

    > p {
      margin-top: 6%;
      display: flex;
      justify-content: start;
      align-items: center;
      font-family: 'RedHatDisplay-Regular';
      font-size: 0.8rem;
      > div {
        margin-right: 0.5rem;
      }

      button {
        margin-left: 0.5rem;
        font-family: 'RedHatDisplay-Regular';
        font-size: 0.8rem;
        color: #1e88e5;
      }
    }
  }
  > footer {
    width: 100%;
    height: 0.778rem;
    background-color: #ff9800;
  }

  @media (max-width: 790px) {
    max-width: 100vw;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: end;

  a {
    font-size: 1.16rem;
    font-family: 'RedHatDisplay-Regular';

    color: #1e88e5;
  }
`;

export const SideContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #323232;

  @media (max-width: 790px) {
    display: none;
  }
`;
export const TopContent = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LanguageButtons = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  top: 3.16rem;
  left: 3.94rem;
  align-items: center;
  color: #ffffff80;

  > button {
    background: none;
    border: 0;
    font-family: 'RedHatDisplay-Regular';
    font-size: 1.22rem;
    color: #ffffff80;

    &.active-language {
      color: #fff;
    }
  }

  > button:first-child {
    margin-right: 0.33rem;
  }

  > button:last-child {
    margin-left: 0.33rem;
  }
`;
