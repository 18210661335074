import React from 'react';

const SvgTrash: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="trash_svg__a">
          <path transform="translate(.042)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g strokeLinecap="round" strokeLinejoin="round">
        <path d="M5 7h14" />
        <g clipPath="url(#trash_svg__a)" transform="translate(-.042)">
          <path d="M18 7v11a2 2 0 01-2 2H8a2 2 0 01-2-2V7M15 3.764H9M13.869 15.369l-3.737-3.737M10.131 15.369l3.737-3.737" />
        </g>
      </g>
    </svg>
  );
};

export default SvgTrash;
